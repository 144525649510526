<template>
  <card class="mx-auto">
    <template v-slot:title>
      <div class="d-flex justify-content-between">
        <div>
          <b>{{ group.attributes.name }}</b>
        </div>
        <div>
          <template v-if="check && role == 'admin' && useType == 'admin'">
            <tooltip-icon
              text="Editar Grupo"
              icon="pencil-alt"
              class-icon="text-white"
              @click="
                $router.push({
                  name: 'admin-groups-edit',
                  params: { id: group.id },
                })
              "
            ></tooltip-icon>
            <tooltip-icon
              text="Eliminar Grupo"
              icon="trash-alt"
              class-icon="text-white"
              @click="destroyFunction({ id: group.id, entity: 'groups' })"
            ></tooltip-icon>
          </template>
        </div>
      </div>
    </template>

    <template v-slot:body>
      <div class="row justify-center">
        <div class="col-lg-4 pt-2 col-md-12 col-sm-12 d-block text-center">
          <img
            :src="`${
              group.attributes.portada ||
              require('@/assets/images/logos/logo.png')
            }`"
            alt=""
            class="gallery mt-2"
          />
        </div>
        <div class="col-lg-8 col-md-12 col-sm-12">
          <div class="row px-3 pt-2">
            <div class="col-md-12 col-sm-12" v-if="options.description">
              <card-item
                :text="group.attributes.description"
                icon="clipboard-list"
              ></card-item>
            </div>
            <div class="row px-3 pt-2">
              <div
                class="col-md-6 col-sm-12"
                v-if="options.start_date && group.attributes.start_date"
              >
                <card-item
                  :text="formatDate(group.attributes.start_date)"
                  icon="calendar"
                  label="Fecha de inicio"
                ></card-item>
              </div>
              <div
                class="col-md-6 col-sm-12"
                v-if="options.end_date && group.attributes.end_date"
              >
                <card-item
                  :text="formatDate(group.attributes.end_date)"
                  icon="calendar"
                  label="Fecha de Término"
                ></card-item>
              </div>
              <!-- <div class="col-12 col-md-4" v-if="useType == 'admin'">
                <button-filled
                  :hasRoundedCorners="true"
                  class="blog-btn fw-bolder"
                  :text="'Ordenar Productos'"
                  size="small"
                  location="/edit-profile"
                />
              </div> -->
            </div>
          </div>
        </div>
        <div
          class="col-12 col-md-3 mx-3 mt-sm-2 mt-lg-0"
          v-if="group.attributes.bases"
        >
          <button-filled
            type="button"
            class="fw-bold text-white mt-4"
            :hasRoundedCorners="true"
            :location="group.attributes.bases"
            :is-link="true"
            :external="true"
            target="_blank"
          >
            <small>Descargar bases</small></button-filled
          >
        </div>
      </div>
    </template>
  </card>
</template>

<script>
import Card from "@/components/molecules/Card/Card.vue";
import moment from "moment";
import ButtonFilled from "@/components/atoms/Button/ButtonFilled.vue";
import CardItem from "@/components/molecules/Card/CardItem.vue";
import TooltipIcon from "@/components/atoms/TooltipIcon.vue";
import { mapGetters } from "vuex";

export default {
  components: { Card, CardItem, ButtonFilled, TooltipIcon },
  props: {
    group: { require: true },
    destroyFunction: { type: Function },
    useType: { type: String, default: "admin" },
    options: {
      type: Object,
      default: () => ({
        portada: true,
        name: true,
        description: true,
        start_date: true,
        end_date: true,
      }),
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
  computed: {
    ...mapGetters({
      check: "auth/check",
      role: "auth/role",
    }),
  },
};
</script>

<style></style>
