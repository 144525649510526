<template>
  <card class="mx-auto">
    <template v-slot:title>
      <b>Exhibición</b>
    </template>

    <template v-slot:body>
      <div class="row justify-center">
        <div class="col-lg-4 pt-2 col-md-12 col-sm-12 d-block text-center">
          <img
            :src="`${
              exhibition.attributes.portada ||
              require('@/assets/images/logos/logo.png')
            }`"
            alt=""
            class="gallery mt-2"
          />
        </div>
        <div class="col-lg-8 col-md-12 col-sm-12">
          <div class="row px-3 pt-2">
            <div class="col-md-6 col-sm-12">
              <card-item
                :text="formatDate(exhibition.attributes.start_date)"
                icon="calendar"
                label="Fecha y Hora de Inicio"
              ></card-item>
            </div>
            <div class="col-md-6 col-sm-12">
              <card-item
                :text="formatDate(exhibition.attributes.end_date)"
                icon="calendar"
                label="Fecha y Hora de Cierre"
              ></card-item>
            </div>
            <div class="col-md-6 col-sm-12">
              <card-item
                :text="exhibition.attributes.address"
                icon="map"
                label="Dirección"
              ></card-item>
            </div>
            <div class="col-md-6 col-sm-12">
              <card-item
                :text="exhibition.attributes.description"
                icon="align-justify"
                label="Descripción"
              ></card-item>
            </div>
          </div>
        </div>
      </div>
    </template>
  </card>
</template>

<script>
import Card from "@/components/molecules/Card/Card.vue";
import moment from "moment";
import CardItem from "@/components/molecules/Card/CardItem.vue";

export default {
  components: {
    Card,
    CardItem,
  },
  props: {
    exhibition: {
      type: Object,
      default: () => {
        return {
          attributes: {},
        };
      },
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
  },
};
</script>

<style></style>
