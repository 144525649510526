<template>
  <form @submit.prevent="sendData" class="login-form d-flex flex-column p-md-4">
    <div class="row mb-md-2">
      <div class="col-md-6 col-sm-12">
        <BasicLabel title="Nombre" :required="true" class="fw-bold" />
        <BasicInputText
          :hasRoundedCorners="true"
          class="login-input mb-2"
          :placeholder="'Nombre'"
          v-model="v$.auction.name.$model"
          required
        />
        <span class="text-danger" v-if="v$.auction.name.$error"
          >El Nombre es requerido.</span
        >
      </div>
      <div class="col-md-3 col-sm-6 my-2 my-md-0">
        <BasicLabel title="Fecha" :required="true" class="fw-bold" />
        <BasicInputText
          :hasRoundedCorners="true"
          class="login-input mb-2"
          v-model="v$.auction.date.date.$model"
          required
          type="date"
          :min="minDate"
        />
        <span class="text-danger" v-if="v$.auction.date.date.$error"
          >La Fecha es requerida.</span
        >
      </div>
      <div class="col-md-3 col-sm-6 my-2 my-md-0">
        <BasicLabel title="Hora" :required="true" class="fw-bold" />
        <BasicInputText
          :hasRoundedCorners="true"
          class="login-input mb-2"
          v-model="v$.auction.date.time.$model"
          type="time"
        />
        <span class="text-danger" v-if="v$.auction.date.time.$error"
          >La Hora es requerida.</span
        >
      </div>
    </div>
    <div class="row mb-md-2">
      <div class="col-12 col-md-12 my-2">
        <BasicLabel title="Descripción" :required="true" class="fw-bold" />
        <BasicInputText
          :hasRoundedCorners="true"
          class="login-input mb-2"
          :placeholder="'Descripción'"
          v-model="v$.auction.description.$model"
          required
        />
        <span class="text-danger" v-if="v$.auction.description.$error"
          >La Descripción es requerida.</span
        >
      </div>
      <div class="col-12 col-md-12">
        <BasicLabel title="Dirección" :required="true" class="fw-bold" />
        <BasicInputText
          :hasRoundedCorners="true"
          class="login-input mb-2"
          :placeholder="'Dirección'"
          v-model="v$.auction.address.$model"
          required
        />
        <span class="text-danger" v-if="v$.auction.address.$error"
          >La Dirección es requerida.</span
        >
      </div>
    </div>
    <div class="row mb-md-2">
      <div class="col-md-6 col-sm-12">
        <BasicLabel title="Comuna" :required="true" class="fw-bold" />
        <BasicSelectBox
          name="comune"
          class="form-control mt-md-3"
          defaultValueText="Seleccione una comuna"
          :options="comunas"
          v-model="v$.auction.comuna_id.$model"
        />
        <span class="text-danger" v-if="v$.auction.comuna_id.$error"
          >La Comuna es requerida.</span
        >
      </div>
      <div class="col-md-6 col-sm-12">
        <BasicLabel title="Tipo" :required="true" class="fw-bold" />
        <BasicSelectBox
          name="type"
          class="form-control mt-md-3"
          defaultValueText="Seleccione un tipo"
          :options="types"
          v-model="v$.auction.type.$model"
        />
        <span class="text-danger" v-if="v$.auction.type.$error"
          >La Comuna es requerida.</span
        >
      </div>
    </div>
    <div class="row my-md-2">
      <div class="col-md-6 col-sm-12">
        <BasicLabel title="Imagen de portada" class="fw-bold" />
        <BasicInputText
          @input="convertFile($event, 'portada')"
          :hasRoundedCorners="true"
          class="login-input form-control-sm mb-2"
          :placeholder="'Imagen de portada'"
          :type="'file'"
          v-model="v$.auction.image.$model"
          accept="image/png, image/jpeg"
        />
      </div>
      <div class="col-md-6 col-sm-12">
        <BasicLabel title="Base" class="fw-bold" />
        <BasicInputText
          :hasRoundedCorners="true"
          class="login-input form-control-sm mb-3"
          :placeholder="'Base'"
          :type="'file'"
          v-model="v$.auction.base.$model"
          @input="convertFile($event, 'base')"
          accept=".pdf"
        />
      </div>
    </div>
    <div class="row mb-md-2">
      <div class="col-md-6 col-sm-12">
        <BasicLabel title="Catálogo" class="fw-bold" />
        <BasicInputText
          :hasRoundedCorners="true"
          class="login-input form-control-sm mb-3"
          :placeholder="'Catálogo'"
          :type="'file'"
          v-model="v$.auction.catalog.$model"
          @input="convertFile($event, 'catalog')"
          accept=".pdf"
        />
      </div>
      <div class="col-md-6 col-sm-12">
        <BasicLabel title="Diseños de Lotes" class="fw-bold" />
        <BasicSelectBox
          name="type"
          class="form-control mt-3"
          defaultValueText="Diseños de Lotes"
          :options="lotDesigns"
          v-model="v$.auction.lot_design.$model"
        />
      </div>
    </div>
    <div class="row mb-md-2">
      <div class="col-md-6 col-sm-12">
        <BasicLabel
          title="Comisión de Martillero (%)"
          :required="true"
          class="fw-bold"
        />
        <BasicInputText
          :hasRoundedCorners="true"
          class="login-input mb-3"
          :placeholder="'Comisión de Martillero (%)'"
          v-model="v$.auction.commission_auctioneer.$model"
          required
          :type="'number'"
        />
        <span class="text-danger" v-if="v$.auction.commission_auctioneer.$error"
          >La Comisión del Martillero es requerida.</span
        >
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="form-check form-switch mt-5 d-flex justify-content-center">
          <input
            class="form-check-input mb-4"
            type="checkbox"
            id="flexSwitchCheckDefault"
            name="flexSwitchCheckDefault"
            role="switch"
            v-model="v$.auction.activeExhibition.$model"
          />
          <BasicLabel
            title="Agregar Exhibición"
            class="form-check-label fw-bold ml-3"
            for="flexSwitchCheckDefault"
          />
        </div>
      </div>
    </div>
    <exhibition-new
      v-if="auction.activeExhibition"
      v-model="exhibition"
      :active="auction.activeExhibition"
      :comunas="comunas"
    />
    <div class="d-flex justify-content-end mt-4">
      <ButtonFilled
        type="submit"
        class="Minicart_button text-white"
        :text="auctionSlug ? 'Editar' : 'Crear'"
        :hasRoundedCorners="true"
        :disabled="!valid"
      />
    </div>
  </form>

  <p v-for="error of v$.$errors" :key="error.$uid">
    {{ error.$message }}
  </p>
</template>

<script>
import axios from "axios";
import BasicLabel from "@/components/atoms/FormInput/BasicLabel.vue";
import ButtonFilled from "@/components/atoms/Button/ButtonFilled.vue";
import BasicInputText from "@/components/atoms/FormInput/BasicInputText.vue";
import BasicSelectBox from "@/components/atoms/FormInput/BasicSelectBox.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapActions, mapGetters } from "vuex";
import ExhibitionNew from "@/components/custom/exhibition/new.vue";

export default {
  name: "AuctionFormComponent",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    ExhibitionNew,
    BasicLabel,
    BasicInputText,
    BasicSelectBox,
    ButtonFilled,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    auctionSlug: { default: null },
    type: { type: String, default: "crear" },
  },
  data() {
    return {
      // Data Auction
      auction: {
        name: "",
        description: null,
        date: {
          date: new Date().toISOString().slice(0, 10),
          time: null,
        },
        address: "",
        comuna_id: null,
        type: null,
        image: null,
        base: null,
        catalog: null,
        activeExhibition: false,
        lot_design: 1,
        commission_auctioneer: null,
      },

      portada: null,
      base: null,
      catalog: null,

      // Data Exhibition
      exhibition: {},

      // Data Form
      types: [
        { id: 1, name: "Presencial" },
        { id: 2, name: "Mixto" },
        { id: 3, name: "Online" },
      ],
      lotDesigns: [
        { id: 1, name: "Lotes sin imágenes" },
        { id: 2, name: "Lotes con imágenes" },
      ],

      // Modals
      modals: {
        date_picker: false,
        time_picker: false,
      },
    };
  },
  async created() {
    if (this.auctionSlug && this.type === "editar") this.fetchAuction();
  },
  mounted() {
    this.fetchComunas();
  },
  methods: {
    ...mapActions({
      fetchComunas: "tool/fetchComunas",
    }),
    convertFile(e, type) {
      if (type === "portada") {
        this.portada = e.target.files[0];
      } else if (type === "base") {
        this.base = e.target.files[0];
      } else if (type === "catalog") {
        this.catalog = e.target.files[0];
      }
    },
    async fetchAuction() {
      let loader = this.$loading.show();
      await this.$store.dispatch("auction/fetchAuction", this.auctionSlug);
      loader.hide();

      // Set auction data
      const data = this.$store.getters["auction/auction"];
      const { attributes } = data;
      const {
        name,
        description,
        date,
        address,
        type,
        lot_design,
        commission_auctioneer_value,
      } = attributes;
      const fecha = date.split(" ");
      const tp = this.types.find((tp) => tp.name === type);

      const ex = data.relationships.exhibition;

      this.auction = {
        name,
        description,
        date: { date: fecha[0], time: fecha[1] },
        address,
        type: tp.id,
        comuna_id: data.relationships.comuna.id,
        image: null,
        base: null,
        catalog: null,
        activeExhibition: !!ex,
        lot_design,
        commission_auctioneer: commission_auctioneer_value,
      };

      // Set exhibition data
      if (ex) {
        const sd = ex.attributes.start_date.split(" ");
        const ed = ex.attributes.end_date.split(" ");
        this.exhibition = {
          description: ex.attributes.description,
          start_date: {
            date: sd[0],
            time: sd[1],
          },
          end_date: {
            date: ed[0],
            time: ed[1],
          },
          address: ex.attributes.address,
          comuna_id: ex.attributes.comuna_id,
          portada: null,
        };
      }
    },
    async sendData() {
      const isFormCorrect = await this.v$.$validate();
      // Form Data
      const dataToSend = new FormData();
      for (const key in this.auction) {
        if (key === "date") {
          dataToSend.append("date[date]", this.auction.date.date);
          dataToSend.append("date[time]", this.auction.date.time);
        } else if (key !== "image") {
          dataToSend.append(key, this.auction[key]);
        }
      }
      if (this.portada) {
        dataToSend.append("image", this.portada);
      }
      if (this.catalog) {
        dataToSend.append("catalog", this.catalog);
      }
      if (this.base) {
        dataToSend.append("base", this.base);
      }

      for (const key in this.exhibition) {
        if (key === "start_date" || key === "end_date") {
          dataToSend.append(
            `exhibition[${key}][date]`,
            this.exhibition[key].date
          );
          dataToSend.append(
            `exhibition[${key}][time]`,
            this.exhibition[key].time
          );
        } else {
          dataToSend.append(`exhibition[${key}]`, this.exhibition[key]);
        }
      }
      if (isFormCorrect) {
        let loader = this.$loading.show();
        try {
          const url = this.auctionSlug
            ? `/api/auctions/${this.auctionSlug}`
            : "/api/auctions";

          const { data } = await axios.post(url, dataToSend);
          this.$swal({
            icon: "success",
            title: "Excelente",
            text: data.message,
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            this.$router.push({
              name: "admin-auction-show",
              params: { slug: data.id },
            });
          });
          loader.hide();
        } catch (e) {
          const { status } = e.response || {};
          if (status === 409) {
            loader.hide();
            this.$swal({
              icon: "error",
              title: e.response.data.errors.title,
              text: e.response.data.errors.detail,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      comunas: "tool/comunas",
    }),
    minDate() {
      return new Date().toISOString().slice(0, 10);
    },
    valid() {
      const {
        name,
        description,
        date,
        address,
        comuna_id,
        type,
        commission_auctioneer,
      } = this.auction;
      return !name ||
        !description ||
        !date.date ||
        !date.time ||
        !address ||
        !comuna_id ||
        !type ||
        !commission_auctioneer
        ? false
        : true;
    },
  },
  validations() {
    return {
      auction: {
        name: { required },
        description: { required },
        date: {
          date: { required },
          time: { required },
        },
        address: { required },
        comuna_id: { required },
        type: { required },
        image: {},
        base: {},
        catalog: {},
        lot_design: {},
        commission_auctioneer: { required },
        activeExhibition: {},
      },
    };
  },
};
</script>
<style scoped="scoped">
.customer-checkbox {
  margin-top: 10px;
}
.login-input {
  height: 40px !important;
  padding: 10px 20px !important;
  box-shadow: none !important;
  margin-top: 10px;
}
.form-check {
  padding-left: 27px !important;
}
.login-field,
.login-field-last {
  margin-top: 30px !important;
}

.Minicart_button {
  width: 150px !important;
  height: 40px !important;
}
@media screen and (max-width: 768px) {
  .login-input {
    width: 100% !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .login-input {
    width: 250px !important;
  }
  .form-check-input input {
    width: 15px !important;
  }
}
.form-switch .form-check-input {
  height: 1.8rem;
  width: 3rem;
}
.form-check-label {
  margin-left: 1rem;
  margin-top: 0.2rem;
}
</style>
