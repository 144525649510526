<template>
  <div class="row align-items-stretch">
    <div
      class="grid-item col-lg-4 col-md-6 col-sm-12"
      v-for="item in items"
      :key="item"
    >
      <keep-alive>
        <component
          :is="search ? 'SearchCard' : 'ProductCard'"
          :product="item"
        />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import ProductCard from "../Product/ProductCard.vue";
import SearchCard from "@/components/custom/SearchCard";

export default {
  components: { ProductCard, SearchCard },
  props: {
    items: {
      type: Array,
      required: true,
    },
    search: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped="scoped">
.grid-item {
  padding-bottom: 24px;
}
:deep(.prod-card-counter) {
  margin-top: -15px !important;
}
@media (max-width: 767.98px) {
  :deep(.prod-card-counter) {
    margin-top: 0px !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  :deep(.prod-card-counter) {
    margin-top: 0px !important;
  }
}
@media (max-width: 767.98px) {
  .row {
    padding: 10px;
  }
}
</style>
