<template>
  <layout-default v-if="lot">
    <div class="container mb-4">
      <breadcrumb class="mt-5">
        <link-wrapper
          location="/admin/auctions"
          class="breadcrumb-text text-decoration-none"
        >
          <span class="breadcrumb-home">Remates</span>
          <span class="breadcrumb-text mx-2"> / </span>
        </link-wrapper>
        <link-wrapper
          :location="`/admin/auctions/show/${slug}`"
          class="breadcrumb-text text-decoration-none"
        >
          <span class="breadcrumb-home">Remate</span>
          <span class="breadcrumb-text mx-2"> / </span>
        </link-wrapper>
        <link-wrapper
          class="breadcrumb-text text-decoration-none"
          :location="`/admin/auctions/${slug}/lots/edit/${$route.params.id}`"
        >
          <span class="breadcrumb-home">Ver Lote</span>
        </link-wrapper>
      </breadcrumb>
    </div>
    <section
      :class="`container mt-3 ${listImages.length ? 'col-12' : 'col-8'}`"
    >
      <view-lot></view-lot>
    </section>
    <section class="container w-75 products my-5">
      <div class="d-flex justify-content-between mb-2">
        <h3 class="mb-4 pt-md-0 pt-sm-3">Imágenes</h3>
        <ButtonFilled
          class="blog-btn fw-bolder"
          :hasRoundedCorners="true"
          :text="'Cargar nueva'"
          size="small"
          @click="showModal"
        />
      </div>

      <custom-table :headers="headerImages" :items="listImages">
        <template v-slot:id="{ item }">
          <div class="d-flex justify-content-center" :key="item.id">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                :checked="item.portada === 'Portada' ? true : false"
                @change="changeCover(item)"
              />
              <label class="form-check-label" for="flexRadioDefault2">
                {{ item.portada }}
              </label>
            </div>
          </div>
        </template>
        <template v-slot:actions="{ item }">
          <div class="d-flex justify-content-center">
            <tooltip-icon
              text="Ver Imagen"
              icon="eye"
              class-icon="text-primary"
              @click="showModalLoad(item)"
            ></tooltip-icon>
            <tooltip-icon
              text="Eliminar Imagen"
              icon="trash"
              class-icon="text-danger"
              @click="destroyItem({ id: item.id, entity: 'media' })"
            ></tooltip-icon>
          </div>
        </template>
      </custom-table>
    </section>
    <section
      class="container w-75 products my-5"
      v-if="lot && lot.attributes.status == 3"
    >
      <div class="d-flex justify-content-between mb-2">
        <h3 class="mb-4 pt-md-0 pt-sm-3">Ofertas del Lote</h3>
      </div>
      <custom-table :headers="headerLots" :items="lot.relationships.users">
        <template v-slot:amount="{ item }">
          {{ currencyFormat(item, lot) }}
        </template>
        <template v-slot:status="{ item }">
          {{ item.pivot.status == 1 ? "No adjudicada" : "Adjudicada" }}
        </template>
        <template v-slot:created_at="{ item }">
          {{ formatDate(item.pivot.created_at) }}
        </template>
      </custom-table>
    </section>
    <modal-general v-if="modalIsGeneral && image">
      <div class="current-bid-title text-center">
        <img
          :src="image.url"
          class="img-thumbnail"
          style="height: 70vh !important"
        />
      </div>
    </modal-general>
    <modal-general v-if="modalIsGeneral && modal_load_image">
      <div class="current-bid-title">
        <h3>Cargue las imágenes del Lote</h3>
      </div>
      <div class="d-flex justify-content-center">
        <form @submit.prevent="sendImages" class="p-md-2">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
              <BasicLabel title="Imágenes" required class="fw-bold" />
              <BasicInputText
                @input="convertFile($event)"
                :hasRoundedCorners="true"
                class="login-input form-control-sm mb-2"
                :placeholder="'Imágenes'"
                :type="'file'"
                v-model="v$.lot_image_form.$model"
                accept="image/png, image/jpeg"
                multiple
              />
              <span class="text-danger" v-if="v$.lot_image_form.$error"
                >Por favor seleccione al menos una imagen.</span
              >
            </div>
            <div
              class="col-lg-12 col-md-12 col-sm-12 col-12 mt-3"
              v-if="lot_image && lot_image.length == 1"
            >
              <div class="form-check form-switch">
                <input
                  class="form-check-input mb-4"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  name="flexSwitchCheckDefault"
                  role="switch"
                  v-model="v$.portada_image.$model"
                />
                <BasicLabel
                  :title="`Portada: ${portada_image ? 'Sí' : 'No'}`"
                  class="form-check-label fw-bold"
                  for="flexSwitchCheckDefault"
                />
              </div>
            </div>
            <div class="d-flex justify-content-end mt-4">
              <ButtonFilled
                type="submit"
                :disabled="!lot_image"
                class="Minicart_button text-white"
                :text="'Enviar'"
                :hasRoundedCorners="true"
              />
            </div>
          </div>
        </form>
      </div>
    </modal-general>
  </layout-default>
</template>

<script>
import axios from "axios";
import LayoutDefault from "@/components/organisms/LayoutDefault/LayoutDefault.vue";
import CustomTable from "@/components/molecules/Table/CustomTable.vue";
import Breadcrumb from "@/components/atoms/Breadcrumb/Breadcrumb.vue";
import LinkWrapper from "@/components/atoms/LinkWrapper/LinkWrapper.vue";
import { mapActions, mapGetters } from "vuex";
import ButtonFilled from "@/components/atoms/Button/ButtonFilled.vue";
import TooltipIcon from "@/components/atoms/TooltipIcon.vue";
import ModalGeneral from "@/components/molecules/Modal/ModalGeneral.vue";
import BasicLabel from "@/components/atoms/FormInput/BasicLabel.vue";
import BasicInputText from "@/components/atoms/FormInput/BasicInputText.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import ViewLot from "@/components/organisms/Section/Lot/ViewLot.vue";
import moment from "moment";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  async mounted() {
    await this.initPage();
  },
  data() {
    return {
      //Currencys
      PESO_CURRENCY: 1,
      UF_CURRENCY: 3,
      id: this.$route.params.id,
      slug: this.$route.params.slug,
      image: null,
      coverSelected: null,

      modal_load_image: false,
      lot_image_form: null,
      lot_image: null,
      portada_image: false,

      // Datatable img
      headerImages: [
        { text: "Tipo", value: "id" },
        { text: "Nombre", value: "name" },
        { text: "Acciones", value: "actions" },
      ],
      // DataTable Posturas
      headerLots: [
        { text: "Nombre del Adjudicado", value: "name" },
        { text: "Correo del Adjudicado", value: "email" },
        { text: "Monto de Oferta", value: "amount" },
        { text: "Estatus", value: "status" },
        { text: "Fecha y Hora", value: "created_at" },
      ],
    };
  },
  components: {
    LayoutDefault,
    Breadcrumb,
    LinkWrapper,
    ButtonFilled,
    CustomTable,
    TooltipIcon,
    ModalGeneral,
    BasicLabel,
    BasicInputText,
    ViewLot,
  },
  methods: {
    ...mapActions({
      fetchLot: "lot/fetchLot",
    }),
    async initPage() {
      const lot = await this.fetchLot(this.id);
      this.listImages.forEach((image) => {
        if (image.portada === "Portada") this.coverSelected = image.id;
      });
      document.title = lot?.attributes?.name || "Lote";
      this.$store.commit("setLocation", []);
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY, h:mm:ss a");
    },
    currencyFormat(item, lot) {
      let simbol =
        lot?.attributes?.currency_id == this.UF_CURRENCY ? "UF" : "$";
      return simbol + new Intl.NumberFormat("es-CL").format(item.pivot.amount);
    },
    showModalLoad(img) {
      this.image = img;
      this.$store.commit("toggleModal", "general");
    },
    showModal() {
      this.modal_load_image = true;
      this.$store.commit("toggleModal", "general");
    },
    convertFile(e) {
      this.lot_image = Object.values(e.target.files);
    },
    async changeCover(item) {
      try {
        this.coverSelected = item.id;
        if (this.coverSelected) {
          const { data } = await axios.patch(
            `/api/media/${this.coverSelected}`,
            {
              model: "Lot",
              model_id: this.id,
            }
          );
          await this.initPage();
          this.$swal({
            icon: "success",
            title: "Excelente",
            text: data.message,
            showConfirmButton: false,
            timer: 2000,
          });
        } // eslint-disable-next-line
      } catch (e) {}
    },
    destroyItem(item) {
      const { id, entity } = item;
      this.$swal({
        title: "¿Está seguro?",
        text: "¡No podrá revertir esta opción!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Eliminar",
      }).then(async (result) => {
        if (result.value) {
          let loader = this.$loading.show();
          try {
            const { data } = await axios.delete(`/api/${entity}/${id}`);
            this.$swal({
              icon: "success",
              title: "Excelente",
              text: data.message,
              showConfirmButton: false,
              timer: 3000,
            }).then(async () => {
              await this.initPage();
            });
          } catch (e) {
            return e;
          } finally {
            loader.hide();
          }
        }
      });
    },
    async sendImages() {
      this.$store.commit("forceCloseModal");
      let loader = this.$loading.show();
      try {
        const imageToSend = new FormData();
        if (this.lot_image.length !== undefined) {
          this.lot_image.forEach((img, idx) => {
            imageToSend.append(`image-${idx}`, img);
          });

          if (this.lot_image.length > 1) this.portada_image = null;
          else {
            this.portada_image =
              this.listImages.length == 0 ? true : this.portada_image;
          }
          imageToSend.append("images", this.lot_image.length);
          imageToSend.append("portada", this.portada_image);
        } else {
          imageToSend.append("image-0", this.lot_image);
          imageToSend.append("images", 1);
          imageToSend.append(
            "portada",
            this.listImages.length == 0 ? true : this.portada_image
          );
        }
        const { data } = await axios.post(
          `/api/lots/${this.id}/image`,
          imageToSend
        );

        loader.hide();
        this.initPage();
        this.lot_image = null;
        this.lot_image_form = null;
        this.portada_image = false;
        this.modal_load_image = false;
        this.$swal({
          icon: "success",
          title: "Excelente",
          text: data.message,
          showConfirmButton: false,
          timer: 3000,
        });
      } catch (e) {
        this.showModal();
        loader.hide();
      }
    },
  },
  computed: {
    ...mapGetters({
      lot: "lot/lot",
      check: "auth/check",
      role: "auth/role",
      modalIsGeneral: "modalIsGeneral",
    }),
    listImages() {
      if (this.lot) {
        return this.lot.relationships.images;
      }
      return [];
    },
    isAdmin() {
      return this.check && this.role === "admin";
    },
    isUser() {
      return this.check && this.role === "user";
    },
  },
  watch: {
    modalIsGeneral(val) {
      if (!val) {
        this.image = null;
        this.modal_load_image = false;
      }
    },
  },
  validations() {
    return {
      lot_image_form: { required },
      portada_image: {},
    };
  },
};
</script>

<style>
.offer-product {
  width: 80%;
}
.gallery {
  width: 100%;
  height: auto;
}
</style>
