<template>
  <layout-default>
    <div class="container">
      <div class="mt-5">
        <section class="container col-10 products">
          <section>
            <div class="d-flex justify-content-between mb-2">
              <h3 class="mb-4">Usuarios</h3>
            </div>
            <custom-table :headers="headersUsers" :items="users">
              <template v-slot:accions="{ item }">
                <div class="d-flex justify-content-center" :key="item.id">
                  <tooltip-icon
                    text="Ver Usuario"
                    icon="eye"
                    class-icon="text-primary"
                    @click="$router.push(`/admin/users/show/${item.id}`)"
                  ></tooltip-icon>
                  <tooltip-icon
                    text="Editar Usuario"
                    icon="edit"
                    class-icon="text-success"
                    @click="
                      $router.push({
                        name: 'admin-users-edit',
                        params: { id: item.id },
                      })
                    "
                  ></tooltip-icon>
                  <tooltip-icon
                    text="Eliminar Usuario"
                    icon="trash"
                    class-icon="text-danger"
                    @click.prevent="destroyUser(item.id)"
                  ></tooltip-icon>
                </div>
              </template>
            </custom-table>
          </section>
        </section>
      </div>
    </div>
  </layout-default>
</template>

<script>
import LayoutDefault from "@/components/organisms/LayoutDefault/LayoutDefault.vue";
import { mapGetters, mapActions } from "vuex";
import CustomTable from "@/components/molecules/Table/CustomTable.vue";
import TooltipIcon from "@/components/atoms/TooltipIcon.vue";

export default {
  data() {
    return {
      // Headers
      headersUsers: [
        { text: "Nombre", value: "attributes.name" },
        { text: "Apellido", value: "attributes.lastname" },
        { text: "Correo Electrónico", value: "attributes.email" },
        { text: "RUT", value: "relationships.info.rut" },
        { text: "Acciones", value: "accions" },
      ],
    };
  },
  middleware: ["admin"],
  beforeCreate() {
    document.title = "Administrar Usuarios";
  },
  mounted() {
    this.fetchAllUsers();
  },
  components: {
    LayoutDefault,
    CustomTable,
    TooltipIcon,
  },
  methods: {
    ...mapActions({
      fetchAllUsers: "userApp/fetchAllUsers",
      deleteUser: "userApp/deleteUser",
    }),
    destroyUser(id) {
      this.$swal({
        title: "¿Está seguro?",
        text: "¡No podrá revertir esta opción!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Eliminar",
      }).then(async (result) => {
        if (result.value) {
          let loader = this.$loading.show();
          const data = await this.deleteUser(id);
          loader.hide();
          this.$swal({
            icon: "success",
            title: "Excelente",
            text: data.message,
            showConfirmButton: false,
            timer: 3000,
          }).then(async () => {
            await this.fetchAllUsers();
          });
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      users: "userApp/users",
    }),
  },
};
</script>
<style scoped></style>
