<template>
  <layout-default>
    <div class="container">
      <div class="container my-account-page d-flex flex-column">
        <div class="my-account-page-location">
          <div class="my-account-breadcrumb border-bottom">
            <Breadcrumb />
          </div>
        </div>
        <div class="mt-5">
          <section class="container col-10 products">
            <h3 class="mb-4">Lotes Acreditados</h3>
            <custom-table :headers="headerLots" :items="lots">
              <template v-slot:accions="{ item }">
                <div :key="item.id">
                  <tooltip-icon
                    icon="eye"
                    class-icon="text-primary"
                    @click="showModal(item)"
                  ></tooltip-icon>
                </div>
              </template>
            </custom-table>
          </section>
        </div>
        <div class="mt-5">
          <section class="container col-10 products">
            <h3 class="mb-4">Remates ofertados</h3>
            <custom-table :headers="headerAuctions" :items="auctions">
              <template v-slot:accions="{ item }">
                <div :key="item.id">
                  <tooltip-icon
                    :icon="item.active ? 'chevron-down' : 'chevron-up'"
                    class-icon="text-secondary"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="`#collapseExample-${item.id}`"
                    aria-expanded="false"
                    :aria-controls="`collapseExample-${item.id}`"
                    @click="item.active = !item.active"
                  ></tooltip-icon>
                </div>
              </template>
              <template v-slot:collapse="{ item }">
                <tr class="collapse" :id="`collapseExample-${item.id}`">
                  <td colspan="4">
                    <custom-table
                      :headers="headerLots"
                      :items="item.relationships.lots"
                      :bg-header="'bg-transparent'"
                    >
                      <template v-slot:accions="{ item }">
                        <div :key="item.id">
                          <tooltip-icon
                            icon="eye"
                            class-icon="text-primary"
                            @click="showModal(item)"
                          ></tooltip-icon>
                        </div>
                      </template>
                    </custom-table>
                  </td>
                </tr>
              </template>
            </custom-table>
          </section>
        </div>
      </div>
      <modal-general v-if="modalIsGeneral">
        <div class="current-bid-title border-bottom">
          <h5 class="fw-bold">Descripción de: {{ info_description.name }}</h5>
          <p class="pt-3">{{ info_description.description }}</p>
        </div>
      </modal-general>
    </div>
  </layout-default>
</template>

<script>
import LayoutDefault from "@/components/organisms/LayoutDefault/LayoutDefault.vue";
import { mapGetters, mapActions } from "vuex";
import ModalGeneral from "@/components/molecules/Modal/ModalGeneral.vue";
import CustomTable from "@/components/molecules/Table/CustomTable.vue";
import TooltipIcon from "@/components/atoms/TooltipIcon.vue";
import Breadcrumb from "@/components/atoms/Breadcrumb/Breadcrumb.vue";

export default {
  data() {
    return {
      link: "/user/direct-sale",
      // Headers
      headerLots: [
        { text: "Nro", value: "number" },
        { text: "Nombre", value: "name" },
        {
          text: "Monto ofertado (Max)",
          value: "bid.amount",
        },
        { text: "Cantidad de ofertas", value: "bid.count" },
        {
          text: "Ver mas",
          value: "accions",
        },
      ],
      headerAuctions: [
        { text: "Nombre", value: "attributes.name" },
        { text: "Fecha", value: "attributes.date" },
        { text: "Estado", value: "attributes.status" },
        {
          text: "Ver mas",
          value: "accions",
        },
      ],
      info_description: {
        name: null,
        description: null,
      },
    };
  },
  middleware: ["auth", "user"],
  beforeCreate() {
    document.title = "Mis posturas";
  },
  mounted() {
    this.$store.commit("setLocation", [
      { title: "Mis Posturas", location: "/user/auctions" },
    ]);
    this.fetchLotsUser();
    this.fetchAuctionsUser();
  },
  components: {
    LayoutDefault,
    CustomTable,
    TooltipIcon,
    ModalGeneral,
    Breadcrumb,
  },
  methods: {
    ...mapActions({
      fetchLotsUser: "userApp/fetchLotsUser",
      fetchAuctionsUser: "userApp/fetchAuctionsUser",
    }),
    showModal(item) {
      this.info_description.name = item.name;
      this.info_description.description = item.description;
      this.$store.commit("toggleModal", "general");
    },
  },
  computed: {
    ...mapGetters({
      lots: "userApp/lots",
      auctions: "userApp/auctions",
      modalIsGeneral: "modalIsGeneral",
    }),
  },
};
</script>
<style scoped>
.account-page {
  padding-bottom: 30px;
}
.my-account-breadcrumb {
  padding-top: 20px;
  padding-bottom: 20px;
}
.my-account-title {
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .my-account {
    display: block !important;
  }
  .my-account-breadcrumb {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .account-page {
    padding: 0px !important;
    display: flex !important;
    flex-direction: column !important;
  }
  .login-input {
    width: 270px !important;
  }
  .my-account-breadcrumb {
    width: 350px;
  }
  .col-md-6 {
    padding: 35px 0px 0px 0px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .account-page {
    padding: 50px 10px !important;
  }
  .my-account {
    justify-content: space-between !important;
  }
  .my-account-page,
  .my-account {
    padding: 0px !important;
  }
}
</style>
