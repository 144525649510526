<template>
  <layout-default v-if="product.attributes">
    <div class="container mb-4">
      <breadcrumb class="mt-5">
        <link-wrapper location="/" class="breadcrumb-text text-decoration-none">
          <span class="breadcrumb-home">Inicio</span>
          <span class="breadcrumb-text mx-2"> / </span>
        </link-wrapper>
        <link-wrapper
          location="/admin/products"
          class="breadcrumb-text text-decoration-none"
        >
          <span class="breadcrumb-home">Productos</span>
          <span class="breadcrumb-text mx-2"> / </span>
        </link-wrapper>
        <link-wrapper
          v-if="product"
          :location="`/admin/product/show/${product.attributes.group_id}`"
          class="breadcrumb-text text-decoration-none"
        >
        </link-wrapper>
      </breadcrumb>
    </div>
    <section class="container mt-3">
      <div class="row">
        <div class="d-block text-center col-lg-4 col-md-4 col-sm-12">
          <button-filled
            type="button"
            @click="
              $router.push({
                name: 'AdminProducts',
              })
            "
            class="fw-bold text-white my-2"
            :hasRoundedCorners="true"
          >
            <font-awesome-icon :icon="['fas', 'arrow-left']" />
            <span class="mx-2"> Regresar </span>
          </button-filled>
        </div>
      </div>
    </section>
    <section
      :class="`container mt-3 ${listImages.length ? 'col-12' : 'col-8'}`"
    >
      <view-product></view-product>
    </section>
    <section class="container col-12 products my-5">
      <div class="d-flex justify-content-between mb-2">
        <h3 class="mb-4 pt-md-0 pt-sm-3">Imágenes</h3>
        <ButtonFilled
          class="blog-btn fw-bolder"
          :hasRoundedCorners="true"
          :text="'Cargar nueva'"
          size="small"
          @click="showModal"
        />
      </div>

      <custom-table :headers="headerImages" :items="listImages">
        <template v-slot:id="{ item }">
          <div class="d-flex justify-content-start" :key="item.id">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                :checked="item.portada === 'Portada' ? true : false"
                @change="changeCover(item)"
              />
              <label class="form-check-label" for="flexRadioDefault2">
                {{ item.portada }}
              </label>
            </div>
          </div>
        </template>
        <template v-slot:actions="{ item }">
          <div class="d-flex justify-content-start">
            <tooltip-icon
              text="Ver Imagen"
              icon="eye"
              class-icon="text-primary"
              @click="showModalLoad(item)"
            ></tooltip-icon>
            <tooltip-icon
              text="Eliminar Imagen"
              icon="trash"
              class-icon="text-danger"
              @click="destroyItem({ id: item.id, entity: 'media' })"
            ></tooltip-icon>
          </div>
        </template>
      </custom-table>
    </section>
    <section class="container col-12 products my-5">
      <div class="d-flex justify-content-between mb-2">
        <h3 class="mb-4 pt-md-0 pt-sm-3">Posturas</h3>
      </div>
      <custom-table :headers="headerPosturas" :items="listPosturas">
        <template v-slot:actions="{ item }">
          <tooltip-icon
            v-if="product.attributes.status_type == 1"
            text="Acreditar"
            icon="check"
            class-icon="text-primary"
            @click="accreditProduct(item.id)"
          ></tooltip-icon>
          <span v-else-if="item.offer.status == 2">Acreditado</span>
          <span v-else-if="item.offer.status == 1">Vendido</span>
        </template>
      </custom-table>
    </section>
    <modal-general v-if="modalIsGeneral && image">
      <div class="current-bid-title text-center">
        <img
          :src="image.url"
          class="img-thumbnail"
          style="height: 70vh !important"
        />
      </div>
    </modal-general>
    <modal-general v-if="modalIsGeneral && modal_load_image">
      <div class="current-bid-title">
        <h3>Cargue las imágenes del producto</h3>
      </div>
      <div class="d-flex justify-content-start">
        <form @submit.prevent="sendImages" class="login-form p-md-4">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
              <BasicLabel title="Imágenes" required class="fw-bold" />
              <BasicInputText
                @input="convertFile($event)"
                :hasRoundedCorners="true"
                class="login-input form-control-sm mb-2"
                :placeholder="'Imágenes'"
                :type="'file'"
                v-model="v$.product_image_form.$model"
                accept="image/png, image/jpeg"
                multiple
              />
              <span class="text-danger" v-if="v$.product_image_form.$error"
                >Por favor seleccione al menos una imagen.</span
              >
            </div>
            <div
              class="col-lg-12 col-md-12 col-sm-12 col-12 mt-3"
              v-if="product_image && product_image.length == 1"
            >
              <div class="form-check form-switch">
                <input
                  class="form-check-input mb-4"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  name="flexSwitchCheckDefault"
                  role="switch"
                  v-model="v$.portada_image.$model"
                />
                <BasicLabel
                  :title="`Portada: ${portada_image ? 'Sí' : 'No'}`"
                  class="form-check-label fw-bold"
                  for="flexSwitchCheckDefault"
                />
              </div>
            </div>
            <div class="d-flex justify-content-end mt-4">
              <ButtonFilled
                type="submit"
                :disabled="!product_image"
                class="Minicart_button text-white"
                :text="'Enviar'"
                :hasRoundedCorners="true"
              />
            </div>
          </div>
        </form>
      </div>
    </modal-general>
  </layout-default>
</template>

<script>
import axios from "axios";
import LayoutDefault from "@/components/organisms/LayoutDefault/LayoutDefault.vue";
import CustomTable from "@/components/molecules/Table/CustomTable.vue";
import Breadcrumb from "@/components/atoms/Breadcrumb/Breadcrumb.vue";
import LinkWrapper from "@/components/atoms/LinkWrapper/LinkWrapper.vue";
import { mapActions, mapGetters } from "vuex";
import ButtonFilled from "@/components/atoms/Button/ButtonFilled.vue";
import ViewProduct from "@/components/organisms/Section/Product/ViewProduct.vue";
import TooltipIcon from "@/components/atoms/TooltipIcon.vue";
import ModalGeneral from "@/components/molecules/Modal/ModalGeneral.vue";
import BasicLabel from "@/components/atoms/FormInput/BasicLabel.vue";
import BasicInputText from "@/components/atoms/FormInput/BasicInputText.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  async mounted() {
    await this.initPage();
  },
  unmounted() {
    this.$store.commit("product/SET_PRODUCT_ACTION", null);
    this.$store.commit("product/SET_CURRENT_AMOUNT_PRODUCT", null);
  },
  data() {
    return {
      id: this.$route.params.id,
      link: "/direct-sale",
      image: null,
      coverSelected: null,

      modal_load_image: false,
      product_image_form: null,
      product_image: null,
      portada_image: false,

      // Datatable img
      headerImages: [
        { text: "Tipo", value: "id" },
        { text: "Nombre", value: "name" },
        { text: "Acciones", value: "actions" },
      ],
      // DataTable Posturas
      listPosturas: [],
      headerPosturas: [
        { text: "Persona", value: "name" },
        { text: "Monto", value: "offer.amount" },
        { text: "Acción", value: "actions" },
      ],
    };
  },
  components: {
    LayoutDefault,
    Breadcrumb,
    LinkWrapper,
    ButtonFilled,
    ViewProduct,
    CustomTable,
    TooltipIcon,
    ModalGeneral,
    BasicLabel,
    BasicInputText,
  },
  methods: {
    ...mapActions({
      fetchProduct: "product/fetchProduct",
    }),
    async initPage() {
      const product = await this.fetchProduct({ id: this.id });
      this.listImages.forEach((image) => {
        if (image.portada === "Portada") this.coverSelected = image.id;
      });
      this.fetchPosturas();
      document.title = product?.attributes?.name || "Producto";
      this.$store.commit("setLocation", [
        {
          title: product?.attributes?.name || "",
          location: `/direct-sale/product/${this.$route.params.id}`,
        },
      ]);
    },
    async fetchPosturas() {
      try {
        const { data } = await axios.get(`/api/products/${this.id}/offers`);
        const posturas = data.map((pos) => {
          return {
            ...pos,
            offer: {
              amount: parseInt(pos.offer.amount).toLocaleString(),
              offered: pos.offer.offered,
              status: pos.offer.status,
            },
          };
        });
        this.listPosturas = posturas;
        this.$store.commit("config/SET_OVERLAY", false);
        // eslint-disable-next-line
      } catch (e) {}
    },
    showModalLoad(img) {
      this.image = img;
      this.$store.commit("toggleModal", "general");
    },
    showModal() {
      this.modal_load_image = true;
      this.$store.commit("toggleModal", "general");
    },
    convertFile(e) {
      this.product_image = Object.values(e.target.files);
    },
    async changeCover(item) {
      try {
        this.coverSelected = item.id;
        if (this.coverSelected) {
          const { data } = await axios.patch(
            `/api/media/${this.coverSelected}`,
            {
              model: "Product",
              model_id: this.id,
            }
          );
          await this.initPage();
          this.$swal({
            icon: "success",
            title: "Excelente",
            text: data.message,
            showConfirmButton: false,
            timer: 2000,
          });
        } // eslint-disable-next-line
      } catch (e) {}
    },
    destroyItem(item) {
      const { id, entity } = item;
      this.$swal({
        title: "¿Está seguro?",
        text: "¡No podrá revertir esta opción!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Eliminar",
      }).then(async (result) => {
        if (result.value) {
          let loader = this.$loading.show();
          try {
            const { data } = await axios.delete(`/api/${entity}/${id}`);
            this.$swal({
              icon: "success",
              title: "Excelente",
              text: data.message,
              showConfirmButton: false,
              timer: 3000,
            }).then(async () => {
              if (entity === "products") {
                this.$router.push({ name: "admin-products" });
              } else {
                await this.initPage();
                loader.hide();
              }
            });
          } catch (e) {
            loader.hide();
          }
        }
      });
    },
    async accreditProduct(user) {
      this.$swal({
        title: "¿Seguro de que desea acreditar éste producto?",
        text: "¡No podrá revertir esta opción!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Acreditar",
      }).then(async (result) => {
        if (result.value) {
          let loader = this.$loading.show();
          try {
            const { data } = await axios.post(
              `/api/products/${this.id}/offers/accredit/${user}`
            );
            this.$swal({
              icon: "success",
              title: "Excelente",
              text: data.message,
              showConfirmButton: false,
              timer: 3000,
              onClose: async () => {
                loader.hide();
                await this.initPage();
              },
            });
          } catch (e) {
            loader.hide();
          }
        }
      });
    },
    async sendImages() {
      this.$store.commit("forceCloseModal");
      let loader = this.$loading.show();
      try {
        const imageToSend = new FormData();

        if (this.product_image.length !== undefined) {
          this.product_image.forEach((img, idx) => {
            imageToSend.append(`image-${idx}`, img);
          });

          if (this.product_image.length > 1) this.portada_image = null;
          else {
            this.portada_image =
              this.listImages.length == 0 ? true : this.portada_image;
          }
          imageToSend.append("images", this.product_image.length);
          imageToSend.append("portada", this.portada_image);
        } else {
          imageToSend.append("image-0", this.product_image);
          imageToSend.append("images", 1);
          imageToSend.append(
            "portada",
            this.listImages.length == 0 ? true : this.portada_image
          );
        }
        const { data } = await axios.post(
          `/api/products/${this.id}/image`,
          imageToSend
        );

        loader.hide();
        this.initPage();
        this.product_image = null;
        this.product_image_form = null;
        this.portada_image = false;
        this.modal_load_image = false;
        this.$swal({
          icon: "success",
          title: "Excelente",
          text: data.message,
          showConfirmButton: false,
          timer: 3000,
        });
      } catch (e) {
        this.showModal();
        loader.hide();
      }
    },
  },
  computed: {
    ...mapGetters({
      product: "product/product",
      check: "auth/check",
      role: "auth/role",
      modalIsGeneral: "modalIsGeneral",
    }),
    listImages() {
      if (this.product) {
        return this.product.relationships.images;
      }
      return [];
    },
    warrantyAmounts() {
      return this.warrantyAmountsBase.map((item) => {
        return {
          ...item,
          warranty_amount: this.formatCurrency(item.warranty_amount),
        };
      });
    },
    isAdmin() {
      return this.check && this.role === "admin";
    },
    isUser() {
      return this.check && this.role === "user";
    },
  },
  watch: {
    modalIsGeneral(val) {
      if (!val) {
        this.image = null;
        this.modal_load_image = false;
      }
    },
    "$route.params.id": async function (newValue) {
      if (newValue) {
        this.id = newValue;
        await this.initPage();
      }
    },
  },
  validations() {
    return {
      product_image_form: { required },
      portada_image: {},
    };
  },
};
</script>

<style>
.offer-product {
  width: 80%;
}
.gallery {
  width: 100%;
  height: auto;
}
</style>
