<template>
  <layout-default>
    <div class="container">
      <breadcrumb class="mt-5">
        <link-wrapper
          :location="link"
          class="breadcrumb-text text-decoration-none"
        >
          <span class="breadcrumb-home">Remates</span>
          <span class="breadcrumb-text mx-2"> / </span>
        </link-wrapper>
      </breadcrumb>
    </div>
    <section
      class="container my-5"
      v-if="auction && auction.attributes.type_number != 1"
    >
      <section-buttons-auction
        @changeUserIsPart="changeUserIsPart"
        :user-is-part="isPart"
        :status-part="statusPart"
      />
    </section>
    <transition-group name="showMenu">
      <modal-gallery
        :product="getCurrentLot"
        :photos="getImagesCurrentLot"
        v-if="modalIsGallery"
      />
      <modal-general v-if="modalIsGeneral">
        <form @submit.prevent="handlerStoreStreamingUrl">
          <div class="current-bid-title border-bottom">
            <h5 class="fw-bold">
              Ingresa el Iframe del video En Vivo para entrar al remate
            </h5>
            <div class="col-12 mt-5">
              <basic-label
                title="Iframe del Live Video"
                :required="true"
                class="fw-bold mb-1"
              />
              <basic-text-area
                :hasRoundedCorners="true"
                class="login-input mb-2 w-100 border"
                v-model="v$.form.streaming_url.$model"
                required
              />
              <span class="text-danger" v-if="v$.form.streaming_url.$error"
                >La URL es requerida.</span
              >
            </div>
            <section class="buttons d-flex justify-content-end mt-5">
              <button-filled
                type="submit"
                class="text-white"
                text="Entrar"
                :hasRoundedCorners="true"
              />
            </section>
          </div>
        </form>
      </modal-general>
    </transition-group>
    <!-- <section class="container my-5 col-9" v-if="auction && exhibition">
      <card-exhibition :exhibition="exhibition"></card-exhibition>
    </section> -->

    <section class="list-lots">
      <section-list-lots :lots="lots"></section-list-lots>
    </section>
  </layout-default>
</template>

<script>
import LayoutDefault from "@/components/organisms/LayoutDefault/LayoutDefault.vue";
import Breadcrumb from "@/components/atoms/Breadcrumb/Breadcrumb.vue";
import LinkWrapper from "@/components/atoms/LinkWrapper/LinkWrapper.vue";
import { mapActions, mapGetters } from "vuex";
import SectionButtonsAuction from "@/components/organisms/Section/Auction/SectionButtonsAuction.vue";
import SectionListLots from "@/components/organisms/Section/Lot/SectionListLots.vue";
import ModalGallery from "@/components/molecules/Modal/ModalGallery.vue";
import ModalGeneral from "@/components/molecules/Modal/ModalGeneral.vue";
import BasicLabel from "@/components/atoms/FormInput/BasicLabel.vue";
import BasicTextArea from "@/components/atoms/FormInput/BasicTextArea.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import ButtonFilled from "@/components/atoms/Button/ButtonFilled.vue";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations: {
    form: {
      streaming_url: { required },
    },
  },
  inject: ["$echo"],
  async created() {
    // Evento Realtime para actualizar la url del streaming si es actualizada
    this.$echo
      .channel("casa_lira_channel-test")
      .listen("AuctionUrlUpdated", ({ streaming_url }) => {
        //Seteamos la url cada vez que sea actualizada
        this.updateStreamingUrlAuction(streaming_url);
      });
    let loader = this.$loading.show();
    await this.fetchAuction(this.slug);
    loader.hide();
    document.title = this.auction?.attributes?.name || "Remate";
    this.$store.commit("setLocation", [
      {
        title: this.auction?.attributes?.name || "",
        location: `/auctions/show/${this.$route.params.slug}`,
      },
    ]);
    await this.isParticipant();
  },
  components: {
    LayoutDefault,
    Breadcrumb,
    LinkWrapper,
    SectionButtonsAuction,
    // CardExhibition,
    SectionListLots,
    ModalGallery,
    ModalGeneral,
    BasicLabel,
    BasicTextArea,
    ButtonFilled,
  },
  data() {
    return {
      slug: this.$route.params.slug,
      link: "/auctions",

      form: {
        streaming_url: "",
      },
      // Participant
      statusApproved: 1,
      statusOnHold: 0,
      isPart: true,
      statusPart: null,

      //Lot status
      statusAccredited: 3,
    };
  },
  methods: {
    ...mapActions({
      fetchAuction: "auction/fetchAuction",
      storeStreamingUrl: "auction/storeStreamingUrl",
      isParticipantAuction: "auction/isParticipantAuction",
      updateStreamingUrlAuction: "auction/updateStreamingUrlAuction",
    }),
    async isParticipant() {
      if (this.isUser) {
        const data = await this.isParticipantAuction({
          user_id: this.user.id,
          auction_id: this.auction.id,
        });
        this.statusPart = data.status;
        this.isPart = data.is;
      }
    },
    changeUserIsPart() {
      this.isPart = true;
    },
    //Metodo para almacenar la url del streaming y enviarlo al remate
    async handlerStoreStreamingUrl() {
      this.$store.commit("forceCloseModal");
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        return this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Debe ingresar una dirección URL para continuar",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        let loader = this.$loading.show();
        try {
          await this.storeStreamingUrl({
            streaming_url: this.form.streaming_url,
            auction_slug: this.slug,
          });
          loader.hide();
          this.$router.push({
            name: "auction-playground-admin",
            params: { slug: this.slug },
          });
        } catch (e) {
          loader.hide();
        }
      }
    },
  },
  computed: {
    isAdmin() {
      return this.check && this.role === "admin";
    },
    isUser() {
      return this.check && this.role === "user";
    },
    ...mapGetters({
      activeModal: "activeModal",
      modalIsGallery: "modalIsGallery",
      modalIsGeneral: "modalIsGeneral",
      check: "auth/check",
      role: "auth/role",
      user: "auth/user",
      auction: "auction/auction",
      exhibition: "auction/exhibition",
    }),
    lots() {
      if (this.auction.relationships?.lotes?.data?.length) {
        return this.auction.relationships.lotes.data;
      }
      return [];
    },
    getCurrentLot() {
      if (this.modalIsGallery && this.lots.length) {
        let lotActive = this.lots.find(
          (lot) => lot.id == this.activeModal.split("-")[1]
        );
        return lotActive;
      }
      return null;
    },
    getImagesCurrentLot() {
      if (this.modalIsGallery && this.lots.length) {
        let lotActive = this.lots.find(
          (lot) => lot.id == this.activeModal.split("-")[1]
        );
        return lotActive.relationships.images;
      }
      return [];
    },
  },
};
</script>

<style></style>
