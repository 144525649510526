<template>
  <tool-tip
    :interactive="true"
    :hover="true"
    class="m-0 horizontal"
    placement="top"
    :content="text"
  >
    <font-awesome-icon :class="`${classIcon} `" :icon="['fas', icon]" />
  </tool-tip>
</template>

<script>
import ToolTip from "./ToolTip.vue";
export default {
  components: { ToolTip },
  props: {
    icon: {
      type: String,
      default: "eye",
    },
    text: {
      type: String,
    },
    classIcon: {
      type: String,
      default: "text-primary",
    },
  },
};
</script>

<style></style>
