<template>
  <div class="container lots-container">
    <div class="row">
      <div
        class="
          filter-col
          col-md-3 col-sm-12
          pe-4
          d-none d-md-block
          filter-mobile
        "
      >
        <div class="filter-search-shop">
          <div v-if="auction">
            <filter-title
              class="title mb-3 mt-0"
              :title="auction.attributes.name"
            />
            <section class="mt-4">
              <h5 class="mb-3">Direccion:</h5>
              <p class="pt-2 pb-3" v-html="auction.attributes.address"></p>
              <h5>Fecha y Hora:</h5>
              <p class="pt-2 pb-3">
                {{ formatDate(auction.attributes.date) }}
              </p>
              <h5>Descripción:</h5>
              <p class="pt-2 pb-3" v-html="auction.attributes.description"></p>
              <h5 v-if="auction.relationships.comuna">Comuna:</h5>
              <p class="pt-2 pb-3" v-if="auction.relationships.comuna">
                {{ auction.relationships.comuna.attributes.name }}
              </p>
              <h5>Tipo:</h5>
              <p class="pt-2 pb-3" v-html="auction.attributes.type"></p>
              <h5>Estado:</h5>
              <p class="pt-2 pb-3" v-html="auction.attributes.status"></p>
              <h5>Comisión Martillo:</h5>
              <p class="pt-2 pb-3">
                {{ `${auction.attributes.commission_auctioneer}` }}
              </p>
              <section class="bases" v-if="auction.attributes.bases">
                <h5 class="mb-3">Bases:</h5>
                <button-filled
                  size="small"
                  class="w-75"
                  :hasRoundedCorners="true"
                  :is-link="true"
                  :external="true"
                  target="_blank"
                  :location="auction.attributes.bases"
                >
                  <font-awesome-icon :icon="['fas', 'file-pdf']" />
                  <span class="mx-2"> Descargar Bases </span>
                </button-filled>
              </section>
              <section class="catalog" v-if="auction.attributes.catalog">
                <h5 class="mb-3">Catálogo:</h5>
                <button-filled
                  size="small"
                  class="w-75"
                  :hasRoundedCorners="true"
                  :is-link="true"
                  :external="true"
                  target="_blank"
                  :location="auction.attributes.catalog"
                >
                  <font-awesome-icon :icon="['fas', 'file-pdf']" />
                  <span class="mx-2"> Descargar Catálogo </span>
                </button-filled>
              </section>
            </section>
          </div>
          <div class="mt-5" v-if="auction && exhibition">
            <filter-title class="title mb-3 mt-0" title="Exhibición" />
            <section class="mt-4">
              <h5 class="mb-3">Direccion:</h5>
              <p class="pt-2 pb-3" v-html="exhibition.attributes.address"></p>
              <h5>Fecha y Hora de Inicio:</h5>
              <p class="pt-2 pb-3">
                {{ formatDate(exhibition.attributes.start_date) }}
              </p>
              <h5>Fecha y Hora de Cierre:</h5>
              <p class="pt-2 pb-3">
                {{ formatDate(exhibition.attributes.end_date) }}
              </p>
              <h5>Descripción:</h5>
              <p
                class="pt-2 pb-3"
                v-html="exhibition.attributes.description"
              ></p>
            </section>
          </div>
        </div>
      </div>
      <div class="col-md-9 col-sm-12 shop-grid" id="topGrid">
        <div class="fluid-container filter-bar-shop">
          <filter-bar :totalResults="lots.length" :itemsShowing="lots.length">
            <template v-slot:left>
              <change-layout-buttons
                class="me-4"
                @onChange="layout = !layout"
              ></change-layout-buttons>
            </template>
          </filter-bar>
        </div>
        <keep-alive>
          <component
            :is="layout === true ? 'Grid' : 'List'"
            :items="lots"
            :itemsPerRow="3"
          />
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import FilterBar from "@/components/atoms/Filter/FilterBar.vue";
import Grid from "@/components/molecules/Layout/Grid.vue";
import List from "@/components/molecules/Layout/List.vue";
import ChangeLayoutButtons from "@/components/atoms/Button/ButtonDouble.vue";
import { mapGetters } from "vuex";
import FilterTitle from "@/components/atoms/Filter/FilterTitle.vue";
import moment from "moment";
import ButtonFilled from "@/components/atoms/Button/ButtonFilled.vue";
export default {
  props: {
    lots: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    FilterBar,
    Grid,
    List,
    ChangeLayoutButtons,
    FilterTitle,
    ButtonFilled,
  },
  data() {
    return {
      layout: true,
    };
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm [hrs]");
    },
  },
  computed: {
    ...mapGetters({
      check: "auth/check",
      role: "auth/role",
      user: "auth/user",
      auction: "auction/auction",
      exhibition: "auction/exhibition",
    }),
  },
};
</script>

<style>
.lots-container {
  padding-top: 15px;
  padding-bottom: 50px;
}
</style>
