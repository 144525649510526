<template>
  <layout-default>
    <!-- Info Group -->
    <div class="container">
      <div class="container my-account-page d-flex flex-column">
        <div class="my-account-page-location">
          <div class="my-account-breadcrumb border-bottom">
            <Breadcrumb />
          </div>
        </div>
      </div>
      <div class="mt-5">
        <section class="container col-10 products">
          <div class="d-flex justify-content-center">
            <!-- Card Info Group -->
            <card class="mx-auto">
              <template v-slot:title>
                <b>
                  {{ $route.params.id ? "Editar Grupo" : "Crear Grupo" }}
                </b>
              </template>
              <template v-slot:body>
                <group-form />
              </template>
            </card>
          </div>
        </section>
      </div>
    </div>
  </layout-default>
</template>

<script>
import Card from "@/components/molecules/Card/Card.vue";
import LayoutDefault from "@/components/organisms/LayoutDefault/LayoutDefault.vue";
import Breadcrumb from "@/components/atoms/Breadcrumb/Breadcrumb.vue";
import groupForm from "@/components/custom/product/groups/form.vue";

export default {
  name: "AdminGroupsEdit",
  middleware: ["auth", "admin"],
  components: {
    Card,
    groupForm,
    LayoutDefault,
    Breadcrumb,
  },
  mounted() {
    this.$store.commit("setLocation", [
      { title: "Grupos", location: "/admin/products" },
      {
        title: "Editar Grupo",
        location: `/admin/groups/edit/${this.$route.params.id}`,
      },
    ]);
  },
};
</script>
<style scoped>
.account-page {
  padding-bottom: 30px;
}
.my-account-breadcrumb {
  padding-top: 20px;
  padding-bottom: 20px;
}
.my-account-title {
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .my-account {
    display: block !important;
  }
  .my-account-breadcrumb {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .account-page {
    padding: 0px !important;
    display: flex !important;
    flex-direction: column !important;
  }
  .col-md-6 {
    padding: 35px 0px 0px 0px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .account-page {
    padding: 50px 10px !important;
  }
  .my-account {
    justify-content: space-between !important;
  }
  .my-account-page,
  .my-account {
    padding: 0px !important;
  }
}
</style>
