<template>
  <layout-default v-if="product.attributes">
    <div class="container mb-4">
      <breadcrumb class="mt-5">
        <link-wrapper location="/" class="breadcrumb-text text-decoration-none">
          <span class="breadcrumb-home">Inicio</span>
          <span class="breadcrumb-text mx-2"> / </span>
        </link-wrapper>
        <link-wrapper
          location="/direct-sale"
          class="breadcrumb-text text-decoration-none"
        >
          <span class="breadcrumb-home">Venta Directa</span>
          <span class="breadcrumb-text mx-2"> / </span>
        </link-wrapper>
        <link-wrapper
          v-if="product"
          :location="`/direct-sale/group/${product.attributes.group_id}`"
          class="breadcrumb-text text-decoration-none"
        >
          <span class="breadcrumb-home">{{ product.attributes.group }}</span>
          <span class="breadcrumb-text mx-2"> / </span>
        </link-wrapper>
      </breadcrumb>
    </div>
    <section class="container mt-3">
      <div class="row">
        <div class="d-block text-center col-lg-4 col-md-4 col-sm-12">
          <button-filled
            v-if="product.attributes.previous_product_id"
            type="button"
            class="fw-bold text-white my-2"
            :hasRoundedCorners="true"
            @click="
              $router.push({
                name: 'DirectSaleProduct',
                params: {
                  id: product.attributes.previous_product_id,
                },
              })
            "
          >
            <font-awesome-icon :icon="['fas', 'arrow-left']" />
            <span class="mx-2"> Anterior </span>
          </button-filled>
        </div>
        <div class="d-block text-center col-lg-4 col-md-4 col-sm-12">
          <button-filled
            type="button"
            @click="
              $router.push({
                name: 'DirectSaleGroup',
                params: {
                  id: product.attributes.group_id,
                  number: product.attributes.number,
                },
              })
            "
            class="fw-bold text-white my-2"
            :hasRoundedCorners="true"
          >
            <font-awesome-icon :icon="['fas', 'arrow-left']" />
            <span class="mx-2"> Regresar </span>
          </button-filled>
        </div>
        <div class="d-block text-center col-lg-4 col-md-4 col-sm-12">
          <button-filled
            type="button"
            v-if="product.attributes.next_product_id"
            @click="
              $router.push({
                name: 'DirectSaleProduct',
                params: {
                  id: product.attributes.next_product_id,
                },
              })
            "
            class="fw-bold text-white my-2"
            :hasRoundedCorners="true"
          >
            <span class="mx-2"> Siguiente </span>
            <font-awesome-icon :icon="['fas', 'arrow-right']" />
          </button-filled>
        </div>
      </div>
    </section>
    <section
      :class="`container mt-3 ${listImages.length ? 'col-12' : 'col-8'}`"
    >
      <view-product></view-product>
    </section>
    <div
      class="d-block text-center mt-5"
      v-if="
        !isOffered &&
        $route.name === 'DirectSaleProduct' &&
        product.attributes.status_type == 1 &&
        !product.attributes.only_buy
      "
    >
      <p v-html="`Por menor al precio de venta`" class="fw-bold mb-0"></p>
      <button-filled
        type="button"
        class="fw-bold text-white my-2 mb-4"
        :hasRoundedCorners="true"
        @click="setProductActionAndAmount('bid')"
      >
        <span class="mx-2"> Ofertar </span>
      </button-filled>
    </div>

    <section
      class="container mb-5 mt-3"
      v-if="isUser && !isOffered"
      v-show="productAction"
    >
      <div class="row col-10 mx-auto">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <card class="mx-auto">
            <template v-slot:title>
              <b>Nueva Oferta</b>
            </template>
            <template v-slot:body>
              <form ref="formOffer" @submit.prevent="sendOffer">
                <div class="p-3" v-if="product.relationships">
                  <basic-label
                    v-if="product.relationships"
                    :title="`Oferta (${product.relationships.currency.code})`"
                    class="mx-1 mb-1"
                    :required="true"
                  />
                  <basic-input-text
                    type="number"
                    class="login-input"
                    v-model="v$.offer.amount.$model"
                    required
                    :placeholder="`Oferta (${product.relationships.currency.code})`"
                  />
                  <span class="text-danger" v-if="v$.offer.amount.$error"
                    >El Monto es requerido.</span
                  >
                </div>
                <section
                  class="d-block text-right"
                  style="margin-bottom: -2rem"
                >
                  <button-filled
                    type="submit"
                    class="fw-bold text-white my-2"
                    :disabled="!valid"
                    :hasRoundedCorners="true"
                  >
                    <span class="mx-2"> Ofertar </span>
                  </button-filled>
                </section>
              </form>
            </template>
          </card>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <card class="mx-auto">
            <template v-slot:title>
              <b>Detalle de Compra</b>
            </template>
            <template v-slot:body>
              <div class="p-3">
                <div class="row">
                  <div class="col-md-6 col-sm-12">
                    <p><b>Producto:</b> {{ formatCurrency(offer.amount) }}</p>
                    <p><b>IVA:</b> {{ formatCurrency(ivaProduct) }}</p>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <p>
                      <b>Comisión:</b> {{ formatCurrency(commissionProduct) }}
                    </p>
                    <p>
                      <b>IVA:</b> {{ formatCurrency(ivaCommissionProduct) }}
                    </p>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:footer>
              <h5>
                {{ productAction == "bid" ? "Total" : "Total a Pagar" }}:
                {{ formatCurrency(totalProduct) }}
              </h5>
            </template>
          </card>
        </div>
      </div>
    </section>

    <alert
      v-if="isOffered"
      class="mb-5"
      title="Ya ha ofertado en éste producto."
    ></alert>

    <section
      class="container mb-5 terms"
      v-if="product.attributes.terms_and_conditions"
    >
      <card class="mx-auto">
        <template v-slot:title>
          <b>Términos y Condiciones</b>
        </template>
        <template v-slot:body>
          <p v-html="product.attributes.terms_and_conditions"></p>
        </template>
      </card>
    </section>
  </layout-default>
</template>

<script>
import LayoutDefault from "@/components/organisms/LayoutDefault/LayoutDefault.vue";
import Breadcrumb from "@/components/atoms/Breadcrumb/Breadcrumb.vue";
import LinkWrapper from "@/components/atoms/LinkWrapper/LinkWrapper.vue";
import { mapActions, mapGetters } from "vuex";
import ButtonFilled from "@/components/atoms/Button/ButtonFilled.vue";
import Alert from "@/components/atoms/Alert/Alert.vue";
import Card from "@/components/molecules/Card/Card.vue";
import BasicLabel from "@/components/atoms/FormInput/BasicLabel.vue";
import BasicInputText from "@/components/atoms/FormInput/BasicInputText.vue";
import useVuelidate from "@vuelidate/core";
import { required, numeric } from "@vuelidate/validators";
import ViewProduct from "@/components/organisms/Section/Product/ViewProduct.vue";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  async mounted() {
    await this.initPage();
  },
  unmounted() {
    this.$store.commit("product/SET_PRODUCT_ACTION", null);
    this.$store.commit("product/SET_CURRENT_AMOUNT_PRODUCT", null);
  },
  data() {
    return {
      id: this.$route.params.id,
      link: "/direct-sale",

      // Panel Terms
      panelTerms: 0,
      description: 0,

      // Data Offer Form
      warranty_substable_limit: null,
      valid: true,
      offer: {
        amount: null,
        warranty_amount: null,
        warranty: null,
        action: null,
      },
    };
  },
  validations() {
    return {
      offer: {
        amount: { required, numeric },
      },
    };
  },
  components: {
    LayoutDefault,
    Breadcrumb,
    LinkWrapper,
    ButtonFilled,
    Alert,
    Card,
    BasicLabel,
    BasicInputText,
    ViewProduct,
  },
  methods: {
    ...mapActions({
      fetchProduct: "product/fetchProduct",
      fetchConfigurations: "tool/fetchConfigurations",
      fetchWarrantyAmounts: "tool/fetchWarrantyAmounts",
      sendOfferProduct: "product/sendOfferProduct",
    }),
    async initPage() {
      const product = await this.fetchProduct({ id: this.id });
      this.fetchConfigurations();
      this.loadWarrantyAmounts();
      this.offer.amount = this.currentAmountProduct;
      this.offer.action = this.productAction;
      document.title = product?.attributes?.name || "Producto";
      this.$store.commit("setLocation", [
        {
          title: product?.attributes?.name || "",
          location: `/direct-sale/product/${this.$route.params.id}`,
        },
      ]);
    },
    async loadWarrantyAmounts() {
      if (this.isUser) {
        await this.fetchWarrantyAmounts();
      }
    },
    getConfiguration(configuration) {
      const configurationFiltered = this.configurations.filter(
        (item) => item.attributes.meta_key == configuration
      )[0];
      return configurationFiltered.attributes.meta_value;
    },
    formatCurrency(amount) {
      return this.product?.relationships?.currency?.code
        ? this.product.relationships.currency.code +
            " " +
            new Intl.NumberFormat("es-CL").format(amount)
        : "$ " + new Intl.NumberFormat("es-CL").format(amount);
    },
    setProductActionAndAmount(action) {
      if (!this.check) {
        this.$router.push({
          name: "MyAccount",
          params: { sale: true, product: this.id },
        });
      }
      this.$store.commit("product/SET_PRODUCT_ACTION", action);
      this.$store.commit(
        "product/SET_CURRENT_AMOUNT_PRODUCT",
        this.product.attributes.amount
      );
    },
    async sendOffer() {
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        this.$swal({
          title: "¿Está seguro?",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Enviar",
        }).then(async (res) => {
          if (res.value) {
            let loader = this.$loading.show();
            const dataToSend = new FormData();
            for (const key in this.offer) {
              dataToSend.append(key, this.offer[key]);
            }
            const data = await this.sendOfferProduct({
              payload: dataToSend,
              id: this.id,
            });
            await this.$store.dispatch("auth/fetchUser");
            loader.hide();
            this.$swal({
              icon: "success",
              title: "Excelente",
              text: data.message,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      product: "product/product",
      configurations: "tool/configurations",
      check: "auth/check",
      role: "auth/role",
      offers: "auth/offers",
      productAction: "product/productAction",
      currentAmountProduct: "product/currentAmountProduct",
      warrantyAmountsBase: "tool/warranty_amounts",
    }),
    listImages() {
      if (this.product) {
        return this.product.relationships.images;
      }
      return [];
    },
    warrantyAmounts() {
      return this.warrantyAmountsBase.map((item) => {
        return {
          ...item,
          warranty_amount: this.formatCurrency(item.warranty_amount),
        };
      });
    },
    isAdmin() {
      return this.check && this.role === "admin";
    },
    isUser() {
      return this.check && this.role === "user";
    },
    isOffered() {
      return this.offers.indexOf(parseInt(this.id)) !== -1;
    },
    ivaProduct() {
      if (this.offer.amount > 0) {
        const percentaje = Number(this.getConfiguration("iva"));
        return Math.trunc(Number((this.offer.amount * percentaje) / 100));
      } else {
        return 0;
      }
    },
    commissionProduct() {
      if (this.offer.amount > 0) {
        const percentaje = this.product.attributes.commission_auctioneer;
        return Math.trunc(Number((this.offer.amount * percentaje) / 100));
      } else {
        return 0;
      }
    },
    ivaCommissionProduct() {
      if (this.commissionProduct > 0) {
        const percentaje = Number(this.getConfiguration("iva"));
        return Math.trunc(Number((this.commissionProduct * percentaje) / 100));
      } else {
        return 0;
      }
    },
    amountTransfer() {
      if (this.offer.warranty_amount) {
        const amountFiltered = this.warrantyAmounts.filter(
          (item) => item.id == this.offer.warranty_amount
        )[0];
        return amountFiltered.warranty_amount;
      }
      return 0;
    },
    totalProduct() {
      return Math.trunc(
        Number(this.offer.amount) +
          this.ivaProduct +
          this.commissionProduct +
          this.ivaCommissionProduct
      );
    },
  },
  watch: {
    productAction() {
      this.offer.amount = this.currentAmountProduct;
      this.offer.action = "bid";
    },
    "offer.amount": function () {
      this.offer.warranty_amount = null;
      this.warranty_substable_limit = null;
    },
    "$route.params.id": async function (newValue) {
      if (newValue) {
        this.id = newValue;
        await this.initPage();
      }
    },
  },
};
</script>

<style>
.offer-product {
  width: 80%;
}
.gallery {
  width: 100%;
  height: auto;
}
</style>
