const HEADER_PRODUCTS = [
  { text: "Nombre", value: "attributes.name" },
  { text: "Grupo", value: "attributes.group" },
  { text: "Moneda", value: "relationships.currency.code" },
  { text: "Precio", value: "attributes.amount_format" },
  { text: "Ofertas", value: "attributes.offers" },
  { text: "Estado", value: "attributes.status" },
  { text: "Acciones", value: "accions" },
];

export { HEADER_PRODUCTS };
