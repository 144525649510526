<template>
  <layout-default>
    <div class="container">
      <div class="container my-account-page d-flex flex-column">
        <div class="my-account-page-location">
          <div class="my-account-breadcrumb border-bottom">
            <Breadcrumb />
          </div>
        </div>
        <div class="mt-5">
          <section class="container col-10 products">
            <h3 class="mb-4">Lotes Acreditados</h3>
            <custom-table :headers="headersAcredit" :items="acreditProducts">
              <template v-slot:accions="{ item }">
                <div :key="item.id">
                  <tooltip-icon
                    icon="eye"
                    class-icon="text-primary"
                  ></tooltip-icon>
                </div>
              </template>
            </custom-table>
          </section>
        </div>
        <div class="mt-5">
          <section class="container col-10 products">
            <h3 class="mb-4">Remates ofertados</h3>
            <custom-table :headers="headersAll" :items="allProducts">
              <template v-slot:accions="{ item }">
                <div :key="item.id">
                  <tooltip-icon
                    icon="eye"
                    class-icon="text-primary"
                  ></tooltip-icon>
                </div>
              </template>
            </custom-table>
          </section>
        </div>
      </div>
    </div>
  </layout-default>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LayoutDefault from "@/components/organisms/LayoutDefault/LayoutDefault.vue";
import Breadcrumb from "@/components/atoms/Breadcrumb/Breadcrumb.vue";
import CustomTable from "@/components/molecules/Table/CustomTable.vue";
import TooltipIcon from "@/components/atoms/TooltipIcon.vue";
export default {
  name: "UserDirectSale",
  middleware: ["auth", "user"],
  components: {
    LayoutDefault,
    CustomTable,
    TooltipIcon,
    Breadcrumb,
  },
  data() {
    return {
      // DataTable
      headersAcredit: [
        { text: "Nombre", value: "name" },
        { text: "Costo ($ CL)", value: "amount" },
        { text: "Monto ofertado ($ CL)", value: "offer.amount" },
        { text: "Fecha", value: "offer.offered" },
        { text: "Acciones", value: "accions" },
      ],
      headersAll: [
        { text: "Nombre", value: "name" },
        { text: "Costo ($ CL)", value: "amount" },
        { text: "Monto ofertado ($ CL)", value: "offer.amount" },
        { text: "Fecha", value: "offer.offered" },
        { text: "Estado", value: "status" },
        { text: "Acciones", value: "accions" },
      ],
    };
  },
  async created() {
    await this.fetchUserProducts(2);
    await this.fetchUserProducts(1);
  },
  mounted() {
    this.$store.commit("setLocation", [
      { title: "Productos", location: "/user/direct-sale" },
    ]);
  },
  methods: {
    ...mapActions({
      fetchUserProducts: "userApp/fetchUserProducts",
    }),
  },
  computed: {
    ...mapGetters({
      acreditProducts: "userApp/acreditProducts",
      allProducts: "userApp/allProducts",
    }),
  },
};
</script>

<style scoped>
.account-page {
  padding-bottom: 30px;
}
.my-account-breadcrumb {
  padding-top: 20px;
  padding-bottom: 20px;
}
.my-account-title {
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .my-account {
    display: block !important;
  }
  .my-account-breadcrumb {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .account-page {
    padding: 0px !important;
    display: flex !important;
    flex-direction: column !important;
  }
  .login-input {
    width: 270px !important;
  }
  .my-account-breadcrumb {
    width: 350px;
  }
  .col-md-6 {
    padding: 35px 0px 0px 0px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .account-page {
    padding: 50px 10px !important;
  }
  .my-account {
    justify-content: space-between !important;
  }
  .my-account-page,
  .my-account {
    padding: 0px !important;
  }
}
</style>
