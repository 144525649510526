<template>
  <div class="text-decoration-none h-100">
    <div class="product-card d-inline-block border-0 position-relative h-100">
      <div class="position-absolute mt-4 icon-card">
        <div class="d-flex flex-column">
          <tool-tip
            :interactive="true"
            :hover="true"
            class="m-0 horizontal"
            placement="right"
            content="Ver Grupo"
          >
            <div
              class="
                mt-2
                fa-2x
                rounded-circle
                wrapper
                d-flex
                justify-content-center
                align-items-center
              "
              @click="
                $router.push({
                  name: 'DirectSaleGroup',
                  params: {
                    id: group.id,
                  },
                })
              "
            >
              <font-awesome-icon color="#ea7f23" :icon="['fas', 'eye']" />
            </div>
          </tool-tip>
        </div>
      </div>
      <span
        class="text-decoration-none bb"
        @click="
          $router.push({
            name: 'DirectSaleGroup',
            params: {
              id: group.id,
            },
          })
        "
      >
        <div class="card position-relative border-0">
          <img
            :class="{ show: group.attributes.portada }"
            :src="`${group.attributes.portada || getLogoDefault}`"
            :alt="group.attributes.name"
          />
        </div>
      </span>
      <div class="product-card-title pt-2 pb-2 position-relative h-50">
        <div class="position-relative elbordaro">
          <h4 class="product-title m-0">
            <span
              class="text-decoration-none"
              @click="
                $router.push({
                  name: 'DirectSaleGroup',
                  params: {
                    id: group.id,
                  },
                })
              "
            >
              {{ group.attributes.name }}
            </span>
          </h4>
          <div class="caption text-center my-3">
            <b>{{ group.attributes.products_count }}</b>
            <small class="mx-2">
              {{
                group.attributes.products_count > 1 ||
                group.attributes.products_count == 0
                  ? "Productos"
                  : "Producto"
              }}
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ToolTip from "@/components/atoms/ToolTip.vue";
export default {
  components: { ToolTip },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getLogoDefault() {
      return require(this.$store.getters.nameApp !== "casalira"
        ? `@/assets/images/logos/logo.jpeg`
        : `@/assets/images/logos/logo.png`);
    },
  },
};
</script>
<style scoped="scoped">
.product-card {
  background: white;
  width: 100%;
  cursor: pointer;
}
.bb {
  border-bottom: 1px solid #dfe5e9 !important;
}
.card {
  width: 100%;
  overflow: hidden;
  margin: auto;
  display: flex;
  height: 300px !important;
  justify-content: center;
  align-items: center;
  border-radius: 0 !important;
}
.product-title {
  padding: 20px 20px 0px;
  text-align: center;
  background: #fff;
  line-height: 24px;
  color: #242424;
}
.prod-card-counter {
  transition: opacity 0.25s ease-in-out;
  top: -60px;
}
.product-title span:hover {
  color: var(--primary-color);
}
.product-title span {
  transition: all 250ms ease;
  -webkit-transition: all 250ms ease;
}

.show,
.hide {
  filter: brightness(80%);
  transition: all 0.25s ease-in-out;
}

.product-card:hover .show {
  visibility: visible;
  opacity: 1;
  transform: scale(1.1);
}

.product-card img {
  width: 100%;
  height: 100%;
}
.product-card span {
  align-self: center;
  color: #484848;
  display: block;
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  transition: color 0.25s ease-in-out;
}
.product-card span:hover {
  color: var(--primary-color);
}
.icon-card {
  left: 20px;
  z-index: 1;
}
.details {
  overflow: hidden;
  padding: 5px 5px 10px;
  background: #fff;
  text-align: center;
  color: #606060;
}

.product-card {
  overflow: hidden;
  box-shadow: 0 0 25px rgb(0 0 0 / 10%);
}

@media (max-width: 767.98px) {
  .prod-card-counter {
    top: -55px;
  }
  .Counter {
    margin-top: 25px;
    width: 100%;
    display: flex;
  }
  .product-title a {
    font-size: 15px !important;
  }
  .product-title {
    padding-top: 0 !important;
    padding: 20px 5px 0;
  }
  .details {
    font-size: 12px !important;
  }
  .elbordaro {
    margin-top: 10px;
  }
}
</style>
