<template>
  <layout-default>
    <div class="container">
      <div class="mt-5">
        <section class="container col-10 products">
          <section>
            <div class="d-flex justify-content-between mb-2">
              <h3 class="mb-4">Grupos</h3>
              <ButtonFilled
                class="blog-btn fw-bolder"
                :hasRoundedCorners="true"
                :text="'Nuevo'"
                size="small"
                location="/admin/groups/new"
              />
            </div>
            <custom-table :headers="headersGroups" :items="groupsAdmin">
              <template v-slot:accions="{ item }">
                <div class="d-flex justify-content-center" :key="item.id">
                  <tooltip-icon
                    text="Ver Grupo"
                    icon="eye"
                    class-icon="text-primary"
                    @click="$router.push(`/admin/groups/show/${item.id}`)"
                  ></tooltip-icon>
                  <tooltip-icon
                    text="Editar Grupo"
                    icon="edit"
                    class-icon="text-success"
                    @click="
                      $router.push({
                        name: 'admin-groups-edit',
                        params: { id: item.id },
                      })
                    "
                  ></tooltip-icon>
                  <tooltip-icon
                    text="Eliminar Grupo"
                    icon="trash"
                    class-icon="text-danger"
                    @click.prevent="
                      destroyProduct({
                        id: item.id,
                        entity: 'groups',
                        type: 1,
                      })
                    "
                  ></tooltip-icon>
                </div>
              </template>
            </custom-table>
          </section>

          <section class="mt-5">
            <div class="d-flex justify-content-between mb-2">
              <h3 class="mb-4">Productos</h3>
              <ButtonFilled
                class="blog-btn fw-bolder"
                :hasRoundedCorners="true"
                :text="'Nuevo'"
                size="small"
                location="/admin/product/new"
              />
            </div>
            <custom-table :headers="headersProducts" :items="productsAdmin">
              <template v-slot:accions="{ item }">
                <div class="d-flex justify-content-center" :key="item.id">
                  <tooltip-icon
                    text="Ver Producto"
                    icon="eye"
                    class-icon="text-primary"
                    @click="
                      $router.push({
                        name: 'admin-product-show',
                        params: { id: item.id },
                      })
                    "
                  ></tooltip-icon>
                  <tooltip-icon
                    text="Editar Producto"
                    icon="edit"
                    class-icon="text-success"
                    @click="
                      $router.push({
                        name: 'admin-product-edit',
                        params: { id: item.id },
                      })
                    "
                  ></tooltip-icon>
                  <tooltip-icon
                    text="Eliminar Producto"
                    icon="trash"
                    class-icon="text-danger"
                    @click.prevent="
                      destroyProduct({
                        id: item.id,
                        entity: 'products',
                        type: 2,
                      })
                    "
                  ></tooltip-icon>
                </div>
              </template>
            </custom-table>
          </section>

          <section class="my-5">
            <h3 class="mb-4">Productos Vendidos</h3>
            <custom-table
              :headers="headersProductsSold"
              :items="productsSoldAdmin"
            >
              <template v-slot:accions="{ item }">
                <div class="d-flex justify-content-center" :key="item.id">
                  <tooltip-icon
                    text="Ver Producto"
                    icon="eye"
                    class-icon="text-primary"
                    @click="
                      $router.push({
                        name: 'admin-product-show',
                        params: { id: item.id },
                      })
                    "
                  ></tooltip-icon>
                  <tooltip-icon
                    text="Editar Producto"
                    icon="edit"
                    class-icon="text-success"
                    @click="
                      $router.push({
                        name: 'admin-product-edit',
                        params: { id: item.id },
                      })
                    "
                  ></tooltip-icon>
                  <tooltip-icon
                    text="Eliminar Producto"
                    icon="trash"
                    class-icon="text-danger"
                    @click.prevent="
                      destroyProduct({
                        id: item.id,
                        entity: 'products',
                        type: 3,
                      })
                    "
                  ></tooltip-icon>
                </div>
              </template>
            </custom-table>
          </section>
        </section>
      </div>
    </div>
  </layout-default>
</template>

<script>
import axios from "axios";
import LayoutDefault from "@/components/organisms/LayoutDefault/LayoutDefault.vue";
import ButtonFilled from "@/components/atoms/Button/ButtonFilled.vue";
import { mapGetters, mapActions } from "vuex";
import CustomTable from "@/components/molecules/Table/CustomTable.vue";
import TooltipIcon from "@/components/atoms/TooltipIcon.vue";
import { HEADER_PRODUCTS } from "@/constants/headers";

export default {
  data() {
    return {
      // Headers
      headersGroups: [
        { text: "Nombre", value: "attributes.name" },
        { text: "Descripción", value: "break_desc" },
        { text: "Acciones", value: "accions" },
      ],
      headersProducts: [...HEADER_PRODUCTS],
      headersProductsSold: [
        ...HEADER_PRODUCTS.filter((item) => item.text !== "Estado"),
      ],
    };
  },
  middleware: ["admin"],
  beforeCreate() {
    document.title = "Productos";
  },
  mounted() {
    this.fetchGroupsAdmin();
    this.fetchProductsAdmin();
    this.fetchProductsSoldAdmin();
  },
  components: {
    LayoutDefault,
    CustomTable,
    TooltipIcon,
    ButtonFilled,
  },
  methods: {
    ...mapActions({
      fetchGroupsAdmin: "direct_sale/fetchGroupsAdmin",
      fetchProductsAdmin: "product/fetchProductsAdmin",
      fetchProductsSoldAdmin: "product/fetchProductsSoldAdmin",
    }),
    async loadData(type = 0) {
      if (type === 0 || type === 1) {
        await this.fetchGroupsAdmin();
      }
      if (type === 0 || type === 2) {
        await this.fetchProductsAdmin();
      }
      if (type === 0 || type === 3) {
        await this.fetchProductsSoldAdmin();
      }
    },
    destroyProduct(item) {
      const { id, entity, type } = item;
      this.$swal({
        title: "¿Está seguro?",
        text: "¡No podrá revertir esta opción!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Eliminar",
      }).then(async (result) => {
        if (result.value) {
          let loader = this.$loading.show();
          try {
            const { data } = await axios.delete(`/api/${entity}/${id}`);
            loader.hide();
            this.$swal({
              icon: "success",
              title: "Excelente",
              text: data.message,
              showConfirmButton: false,
              timer: 3000,
            }).then(async () => {
              await this.loadData(type);
            });
          } catch (e) {
            loader.hide();
          }
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      groupsAdmin: "direct_sale/groupsAdmin",
      productsAdmin: "product/productsAdmin",
      productsSoldAdmin: "product/productsSoldAdmin",
    }),
  },
};
</script>
<style scoped></style>
