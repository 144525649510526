<template>
  <layout-default>
    <div class="container">
      <div class="mt-5">
        <section class="container col-10 products">
          <section>
            <div class="d-flex justify-content-between mb-2">
              <h3 class="mb-4">Remates</h3>
              <ButtonFilled
                class="blog-btn fw-bolder"
                :hasRoundedCorners="true"
                :text="'Nuevo'"
                size="small"
                location="/admin/auctions/new"
              />
            </div>
            <custom-table
              :headers="headers"
              :items="auctions.data ? auctions.data : []"
            >
              <template v-slot:date="{ item }">
                {{ dateAuction(item) }} hrs
              </template>
              <template v-slot:accions="{ item }">
                <div class="d-flex justify-content-center" :key="item.id">
                  <tooltip-icon
                    text="Ver Remate"
                    icon="eye"
                    @click="
                      $router.push({
                        name: 'admin-auction-show',
                        params: { slug: item.attributes.slug },
                      })
                    "
                    class-icon="text-primary"
                  ></tooltip-icon>
                  <tooltip-icon
                    text="Editar Remate"
                    @click="
                      $router.push({
                        name: 'admin-auctions-edit',
                        params: { id: item.id },
                      })
                    "
                    icon="edit"
                    class-icon="text-success"
                  ></tooltip-icon>
                  <tooltip-icon
                    text="Eliminar Remate"
                    icon="trash"
                    @click.prevent="deleteAuction(item.id)"
                    class-icon="text-danger"
                  ></tooltip-icon>
                </div>
              </template>
            </custom-table>
          </section>

          <section class="mt-5">
            <div class="d-flex justify-content-between mb-2">
              <h3 class="mb-4">Remates finalizados</h3>
            </div>
            <custom-table
              :headers="headersAuctionsFinished"
              :items="auctionsFinished.data ? auctionsFinished.data : []"
            >
              <template v-slot:date="{ item }">
                {{ dateAuction(item) }} hrs
              </template>
              <template v-slot:accions="{ item }">
                <div class="d-flex justify-content-center" :key="item.id">
                  <tooltip-icon
                    text="Ver Remate"
                    icon="eye"
                    @click="
                      $router.push({
                        name: 'admin-auction-show',
                        params: { slug: item.attributes.slug },
                      })
                    "
                    class-icon="text-primary"
                  ></tooltip-icon>
                </div>
              </template>
            </custom-table>
          </section>
        </section>
      </div>
    </div>
  </layout-default>
</template>

<script>
import axios from "axios";
import moment from "moment";
import LayoutDefault from "@/components/organisms/LayoutDefault/LayoutDefault.vue";
import ButtonFilled from "@/components/atoms/Button/ButtonFilled.vue";
import { mapGetters } from "vuex";
import CustomTable from "@/components/molecules/Table/CustomTable.vue";
import TooltipIcon from "@/components/atoms/TooltipIcon.vue";

export default {
  name: "AdminAuctions",
  middleware: ["auth", "admin"],
  beforeCreate() {
    document.title = "Administrar Remates";
  },
  components: {
    LayoutDefault,
    ButtonFilled,
    CustomTable,
    TooltipIcon,
  },
  data: () => ({
    headers: [
      { text: "Nombre", value: "attributes.name" },
      { text: "Fecha", value: "date" },
      {
        text: "Comuna",
        value: "relationships.comuna.attributes.name",
      },
      { text: "Tipo", value: "attributes.type" },
      { text: "Status", value: "attributes.status" },
      { text: "Acciones", value: "accions" },
    ],
    search: "",

    // Auction finished
    auctionsFinished: {},
    headersAuctionsFinished: [
      { text: "Nombre", value: "attributes.name" },
      { text: "Fecha", value: "date" },
      {
        text: "Comuna",
        value: "relationships.comuna.attributes.name",
      },
      { text: "Tipo", value: "attributes.type" },
      { text: "Acción", value: "accions" },
    ],
  }),
  computed: {
    ...mapGetters({
      auctions: "auction/auctions",
    }),
  },
  async created() {
    let loader = this.$loading.show();
    await this.$store.dispatch("auction/fetchAuctions", { status: [1, 2, 4] });
    loader.hide();
    await this.fetchAuctionsFinished();
  },
  methods: {
    async fetchAuctionsFinished() {
      try {
        const { data } = await axios.get("/api/auctions?status", {
          params: {
            status: [3],
          },
        });
        this.auctionsFinished = data;
        // eslint-disable-next-line
      } catch (e) {}
    },
    dateAuction(item) {
      return moment(item.attributes.date).format("DD/MM/YYYY hh:mm");
    },
    deleteAuction(id) {
      this.$swal({
        title: "¿Estás seguro de eliminar este remate?",
        text: "¡No podrás revertir esta acción!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar",
        cancelButtonText: "No, cancelar",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          let loader = this.$loading.show();
          try {
            const { data } = await axios.delete(`/api/auctions/${id}`);
            loader.hide();
            this.$swal({
              icon: "success",
              title: "Excelente",
              text: data.message,
              showConfirmButton: false,
              timer: 3000,
            }).then(async () => {
              await this.$store.dispatch("auction/fetchAuctions", {
                status: [1, 2, 4],
              });
            });
          } catch (e) {
            loader.hide();
          }
        }
      });
    },
  },
};
</script>
<style scoped></style>
