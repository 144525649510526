<template>
  <form>
    <div class="contact-form">
      <form @submit.prevent="sendFormContact">
        <div class="form-title d-flex flex-column">
          <span class="fw-bold fs-1 mb-4">Formulario de Contacto</span>
          <!-- <span class="subtitle fw-light fs-6 text-secondary"> -->
          <div class="user-details justify-content-between d-flex me-5">
            <div class="col-md-6 col-input pe-3">
              <BasicInputText
                v-model="v$.form.name.$model"
                :placeholder="'Nombre'"
                required
                :hasFocus="false"
              />
              <span class="text-danger" v-if="v$.form.name.$error"
                >El Nombre es requerido.</span
              >
            </div>
            <div class="col-md-6 col-input ps-3">
              <BasicInputText
                v-model="v$.form.email.$model"
                :placeholder="'Correo Electrónico'"
                required
                :hasFocus="false"
              />
              <span class="text-danger" v-if="v$.form.email.$error"
                >El Correo electrónico tiene un formato invalido.</span
              >
            </div>
          </div>

          <div class="user-message">
            <BasicTextArea
              required
              class="fw-light user-textarea"
              :placeholder="'Mensaje'"
              v-model="v$.form.message.$model"
            />
            <span class="text-danger" v-if="v$.form.message.$error"
              >El Mensaje es requerido y debe contener al menos 15
              caracteres.</span
            >
          </div>
          <div
            class="
              mt-4
              mb-2
              d-flex
              flex-column flex-md-row
              justify-content-between
              align-items-center
            "
          >
            <vue-recaptcha
              @verify="onVerifyCaptcha"
              @expired="onExpiredCaptcha"
              ref="recaptcha"
              :sitekey="siteKey"
            ></vue-recaptcha>
            <button-filled
              class="send-btn text-white mt-3 mt-md-0"
              :size="'small'"
              :text="'Enviar Mensaje'"
              :hasRoundedCorners="true"
              type="submit"
            >
              <template v-slot:default>
                <span class="me-3">Enviar Mensaje</span
                ><font-awesome-icon
                  class="fa"
                  :icon="['fas', 'long-arrow-alt-right']"
                />
              </template>
            </button-filled>
          </div>
        </div>
      </form>
    </div>
  </form>
</template>
<script>
import BasicInputText from "../../atoms/FormInput/BasicInputText.vue";
import BasicTextArea from "../../atoms/FormInput/BasicTextArea.vue";
import ButtonFilled from "../../atoms/Button/ButtonFilled.vue";
import { VueRecaptcha } from "vue-recaptcha";
import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";

import { mapActions } from "vuex";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        message: "",
      },
      recaptchaValidate: false,
      siteKey: process.env.VUE_APP_RECAPTCHA_KEY,
    };
  },
  validations: {
    form: {
      name: { required },
      email: { required, email },
      message: { required, minLengthValue: minLength(15) },
    },
  },
  components: {
    BasicInputText,
    BasicTextArea,
    ButtonFilled,
    VueRecaptcha,
  },
  methods: {
    ...mapActions({
      sendMessageContact: "tool/sendMessageContact",
    }),
    onVerifyCaptcha() {
      this.recaptchaValidate = true;
    },
    onExpiredCaptcha() {
      this.recaptchaValidate = false;
    },
    async sendFormContact() {
      if (!this.recaptchaValidate) {
        return this.$swal({
          icon: "error",
          title: "Upss",
          text: "Debe verificar que no es un robot.",
          showConfirmButton: true,
        });
      }
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        const { name, email, message } = this.form;
        let loader = this.$loading.show();
        const resp = await this.sendMessageContact({
          name,
          email,
          message,
        });
        if (resp.message) {
          loader.hide();
          this.$swal({
            icon: "success",
            title: "Excelente",
            text: resp.message,
            showConfirmButton: true,
          });
          this.form.email = "";
          this.form.name = "";
          this.form.message = "";
          this.$nextTick(() => {
            this.v$.$reset();
          });
        } else {
          this.$swal({
            icon: "error",
            title: "Upss",
            text: "Ha ocurrido un error, intentalo nuevamente mas tarde.",
            showConfirmButton: true,
          });
        }
      }
    },
  },
};
</script>

<style scoped="scoped">
.subtitle {
  margin-bottom: 30px;
}
.contact-form {
  padding-top: 60px !important;
  padding-right: 30px;
  padding-bottom: 85px;
  padding-left: 160px;
  box-shadow: 0px 0px 25px rgb(0 0 0 / 10%);
}
.form-control {
  background-color: #efefef !important;
  box-shadow: none;
  border-radius: 10px 10px 10px 10px;
}
.send-btn {
  height: 40px;
  padding: 0 20px !important;
}
.user-details,
.user-textarea {
  width: 100%;
}
.user-details {
  margin-bottom: 40px;
}
.user-details {
  border: none !important;
}

@media (max-width: 767.98px) {
  .contact-form,
  .user-details {
    display: flex !important;
    flex-direction: column;
  }
  .col-input {
    padding: 0 !important;
    margin-top: 20px;
  }
  .contact-form {
    width: 100%;
    display: flex;
    padding: 30px;
  }
  .user-details > input {
    width: 290px !important;
    height: 50px;
    border-radius: 10px !important;
    border: none;
  }
  .user-textarea {
    width: 290px;
    border: none;
  }
  .user-details {
    margin-bottom: 20px;
  }
}
</style>
