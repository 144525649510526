<template>
  <layout-default>
    <div class="container">
      <div class="container d-flex flex-column">
        <heading class="mt-5" :title="'Remates'" />
      </div>
      <div class="">
        <div class="container latest-auctions my-3">
          <SectionAuctions></SectionAuctions>
        </div>
      </div>
    </div>
  </layout-default>
</template>

<script>
import LayoutDefault from "@/components/organisms/LayoutDefault/LayoutDefault.vue";
import SectionAuctions from "@/components/organisms/Section/Auction/SectionAuctions.vue";
import Heading from "@/components/atoms/SectionHeading/Heading.vue";

export default {
  beforeCreate() {
    document.title = "Últimos Remates";
  },
  mounted() {
    this.$store.commit("setLocation", [
      { title: "Remates", location: "/auctions" },
    ]);
  },
  components: {
    LayoutDefault,
    SectionAuctions,
    Heading,
  },
};
</script>
<style scoped></style>
