<template>
  <div>
    <layout-default>
      <div class="container mb-4">
        <breadcrumb class="mt-5">
          <link-wrapper
            location="/admin/auctions"
            class="breadcrumb-text text-decoration-none"
          >
            <span class="breadcrumb-home">Remates</span>
            <span class="breadcrumb-text mx-2"> / </span>
          </link-wrapper>
          <link-wrapper
            :location="`/admin/auctions/show/${slug}`"
            class="breadcrumb-text text-decoration-none"
          >
            <span class="breadcrumb-home">Remate</span>
            <span class="breadcrumb-text mx-2"> / </span>
          </link-wrapper>
          <link-wrapper
            class="breadcrumb-text text-decoration-none"
            :location="`/admin/auctions/${slug}/lots/edit/${$route.params.id}`"
          >
            <span class="breadcrumb-home">Editar Lote</span>
          </link-wrapper>
        </breadcrumb>
      </div>
      <div class="mt-5">
        <section class="container w-75 products mb-5">
          <div class="d-flex justify-content-center">
            <!-- Card Info Group -->
            <card class="mx-auto">
              <template v-slot:title>
                <b>
                  {{ $route.params.id ? "Editar Lote" : "Nuevo Lote" }}
                </b>
              </template>
              <template v-slot:body>
                <lot-form :auction-slug="slug" :lot-id="lot_id" type="editar" />
              </template>
            </card>
          </div>
        </section>
      </div>
    </layout-default>
  </div>
</template>

<script>
import Card from "@/components/molecules/Card/Card.vue";
import Breadcrumb from "@/components/atoms/Breadcrumb/Breadcrumb.vue";
import LayoutDefault from "@/components/organisms/LayoutDefault/LayoutDefault.vue";
import LotForm from "@/components/custom/lots/form.vue";
import LinkWrapper from "@/components/atoms/LinkWrapper/LinkWrapper.vue";

export default {
  name: "AdminLotsEdit",
  middleware: ["auth", "admin"],
  components: {
    LotForm,
    LayoutDefault,
    Card,
    Breadcrumb,
    LinkWrapper,
  },
  data() {
    return {
      slug: this.$route.params.slug,
      lot_id: this.$route.params.id,
      lot: {},
    };
  },
  beforeCreate() {
    document.title = "Editar Lote";
    this.$store.commit("setLocation", []);
  },
};
</script>
