<template>
  <div class="text-decoration-none h-100">
    <div class="product-card d-inline-block border-0 position-relative h-100">
      <span class="text-decoration-none" @click="actionCard">
        <div class="card position-relative border-0">
          <img
            class="show"
            :src="`${
              product.attributes.portada ||
              require('@/assets/images/products/default_car.jpg')
            }`"
            :alt="product.attributes.name"
          />
        </div>
      </span>
      <div class="product-card-title pb-3 position-relative h-50">
        <div class="position-relative elbordaro">
          <div class="p-4 pb-0 d-block text-center">
            <h3 class="m-0">
              <span class="text-decoration-none" @click="actionCard">
                {{ product.attributes.name }}
              </span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //Lot status
      statusAccredited: 3,
      typeLots: "lots",
      typeProducts: "products",
    };
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  methods: {
    actionCard() {
      this.$router.push(this.product.url_route);
    },
    setColor(st) {
      let cl = "";
      if (st == 1) {
        cl = "bg-success";
      } else cl = "bg-danger";
      return cl;
    },
  },
};
</script>
<style scoped="scoped">
.product-card {
  background: white;
  width: 100%;
  cursor: pointer;
}
.bb {
  border-bottom: 1px solid #dfe5e9 !important;
}
.card {
  width: 100%;
  overflow: hidden;
  margin: auto;
  display: flex;
  height: 300px !important;
  justify-content: center;
  align-items: center;
  border-radius: 0 !important;
}
.product-title {
  padding: 20px 20px 0px;
  text-align: center;
  background: #fff;
  line-height: 24px;
  color: #242424;
}
.prod-card-counter {
  transition: opacity 0.25s ease-in-out;
  top: -60px;
}
.product-title span:hover {
  color: var(--primary-color);
}
.product-title span {
  transition: all 250ms ease;
  -webkit-transition: all 250ms ease;
}

.show,
.hide {
  filter: brightness(70%);
  transition: all 0.25s ease-in-out;
}

.product-card:hover .show {
  visibility: visible;
  opacity: 1;
  transform: scale(1.1);
}

.product-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.product-card span {
  align-self: center;
  color: #484848;
  display: block;
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  transition: color 0.25s ease-in-out;
}
.product-card span:hover {
  color: var(--primary-color);
}
.icon-card {
  left: 0px;
  z-index: 1;
}
.details {
  overflow: hidden;
  padding: 5px 5px 10px;
  background: #fff;
  text-align: center;
  color: #606060;
}

.product-card {
  overflow: hidden;
  box-shadow: 0 0 25px rgb(0 0 0 / 10%);
}

@media (max-width: 767.98px) {
  .prod-card-counter {
    top: -55px;
  }
  .Counter {
    margin-top: 25px;
    width: 100%;
    display: flex;
  }
  .product-title a {
    font-size: 15px !important;
  }
  .product-title {
    padding-top: 0 !important;
    padding: 20px 5px 0;
  }
  .details {
    font-size: 12px !important;
  }
  .elbordaro {
    margin-top: 10px;
  }
}
</style>
