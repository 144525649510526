<template>
  <card class="mx-auto">
    <template v-slot:title>
      <div class="d-flex justify-content-between">
        <div>
          <b>{{ auction.attributes.name }}</b>
        </div>
        <div v-if="auction.attributes.status_number != 3">
          <template v-if="check && role == 'admin' && useType == 'admin'">
            <tooltip-icon
              text="Editar Remate"
              icon="pencil-alt"
              class-icon="text-white"
              @click="
                $router.push({
                  name: 'admin-auctions-edit',
                  params: { id: auction.id },
                })
              "
            ></tooltip-icon>
            <tooltip-icon
              text="Eliminar Remate"
              icon="trash-alt"
              class-icon="text-white"
              @click="destroyFunction({ id: auction.id, entity: 'auctions' })"
            ></tooltip-icon>
          </template>
        </div>
      </div>
    </template>

    <template v-slot:body>
      <div class="row justify-center">
        <div class="col-lg-4 pt-2 col-md-12 col-sm-12 d-block text-center">
          <img
            :src="`${
              auction.attributes.portada ||
              require('@/assets/images/logos/logo.png')
            }`"
            alt=""
            class="gallery mt-2"
          />
        </div>
        <div class="col-lg-8 col-md-12 col-sm-12">
          <div class="row px-3 pt-2">
            <div class="col-md-6 col-sm-12">
              <card-item
                :text="auction.attributes.address"
                icon="map"
                label="Dirección"
              ></card-item>
            </div>
            <div class="col-md-6 col-sm-12">
              <card-item
                :text="formatDate(auction.attributes.date)"
                icon="calendar"
                label="Fecha y Hora"
              ></card-item>
            </div>
            <div class="col-md-6 col-sm-12" v-if="auction.relationships.comuna">
              <card-item
                :text="auction.relationships.comuna.attributes.name"
                icon="map"
                label="Comuna"
              ></card-item>
            </div>
            <div class="col-md-6 col-sm-12">
              <card-item
                :text="auction.attributes.type"
                icon="user"
                label="Tipo"
              ></card-item>
            </div>
            <div class="col-md-6 col-sm-12">
              <card-item
                :text="auction.attributes.status"
                icon="exclamation"
                label="Estado"
              ></card-item>
            </div>
            <div class="col-md-6 col-sm-12">
              <card-item
                :text="`${auction.attributes.commission_auctioneer} ${
                  auction.attributes.commission ? '%' : ''
                }`"
                icon="exclamation"
                label="Comisión del Martillero"
              ></card-item>
            </div>
            <div class="col-md-6 col-sm-12">
              <card-item
                :text="auction.attributes.description"
                icon="align-justify"
                label="Descripción"
              ></card-item>
            </div>
            <div class="col-md-3 col-sm-6">
              <button-filled
                type="button"
                class="fw-bold text-white mt-4"
                :hasRoundedCorners="true"
                v-if="auction.attributes.bases"
                :location="auction.attributes.bases"
                :is-link="true"
                :external="true"
                target="_blank"
              >
                <small class="mx-2"> Ver Bases </small>
              </button-filled>
            </div>
            <div class="col-md-3 col-sm-6">
              <button-filled
                type="button"
                class="fw-bold text-white mt-4"
                :hasRoundedCorners="true"
                v-if="auction.attributes.catalog"
                :location="auction.attributes.catalog"
                :is-link="true"
                :external="true"
              >
                <small class=""> Ver Catálogo </small>
              </button-filled>
            </div>
          </div>
        </div>
      </div>
    </template>
  </card>
</template>

<script>
import Card from "@/components/molecules/Card/Card.vue";
import ButtonFilled from "@/components/atoms/Button/ButtonFilled.vue";
import moment from "moment";
import CardItem from "@/components/molecules/Card/CardItem.vue";
import { mapGetters } from "vuex";
import TooltipIcon from "@/components/atoms/TooltipIcon.vue";

export default {
  components: {
    ButtonFilled,
    Card,
    CardItem,
    TooltipIcon,
  },
  props: {
    auction: {
      type: Object,
      default: () => {
        return {
          attributes: {},
          relationships: {},
        };
      },
    },
    destroyFunction: { type: Function },
    useType: {
      type: String,
      default: "admin",
    },
  },
  computed: {
    ...mapGetters({
      check: "auth/check",
      role: "auth/role",
    }),
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
  },
};
</script>

<style></style>
