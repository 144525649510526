<template>
  <div>
    <div class="row mb-md-2">
      <div class="col-12 col-md-12">
        <BasicLabel title="Descripción" :required="true" class="fw-bold" />
        <BasicInputText
          :hasRoundedCorners="true"
          class="login-input mb-2"
          :placeholder="'Descripción'"
          v-model="v$.exhibition.description.$model"
          required
        />
        <span class="text-danger" v-if="v$.exhibition.description.$error"
          >La Descripción es requerida.</span
        >
      </div>
    </div>
    <div class="row mb-md-2">
      <div class="col-lg-3 col-sm-6 my-2 my-md-0">
        <BasicLabel title="Fecha de inicio" :required="true" class="fw-bold" />
        <BasicInputText
          :hasRoundedCorners="true"
          class="login-input mb-2"
          v-model="v$.exhibition.start_date.date.$model"
          required
          type="date"
          :min="minDate"
        />
        <span class="text-danger" v-if="v$.exhibition.start_date.date.$error"
          >La Fecha es requerida.</span
        >
      </div>
      <div class="col-lg-3 col-sm-6 my-2 my-md-0">
        <BasicLabel title="Hora de Inicio" :required="true" class="fw-bold" />
        <BasicInputText
          :hasRoundedCorners="true"
          class="login-input mb-2"
          v-model="v$.exhibition.start_date.time.$model"
          type="time"
        />
        <span class="text-danger" v-if="v$.exhibition.start_date.time.$error"
          >La Hora es requerida.</span
        >
      </div>
      <div class="col-lg-3 col-sm-6 my-2 my-md-0">
        <BasicLabel title="Fecha de Cierre" :required="true" class="fw-bold" />
        <BasicInputText
          :hasRoundedCorners="true"
          class="login-input mb-2"
          v-model="v$.exhibition.end_date.date.$model"
          required
          type="date"
          :min="minDate"
        />
        <span class="text-danger" v-if="v$.exhibition.end_date.date.$error"
          >La Fecha es requerida.</span
        >
      </div>
      <div class="col-lg-3 col-sm-6 my-2 my-md-0">
        <BasicLabel title="Hora de Cierre" :required="true" class="fw-bold" />
        <BasicInputText
          :hasRoundedCorners="true"
          class="login-input mb-2"
          v-model="v$.exhibition.end_date.time.$model"
          type="time"
        />
        <span class="text-danger" v-if="v$.exhibition.end_date.time.$error"
          >La Hora es requerida.</span
        >
      </div>
    </div>
    <div class="row mb-md-2">
      <div class="col-12 col-md-12">
        <BasicLabel title="Dirección" :required="true" class="fw-bold" />
        <BasicInputText
          :hasRoundedCorners="true"
          class="login-input mb-2"
          :placeholder="'Dirección'"
          v-model="v$.exhibition.address.$model"
          required
        />
        <span class="text-danger" v-if="v$.exhibition.address.$error"
          >La Dirección es requerida.</span
        >
      </div>
    </div>
    <div class="row mb-md-2">
      <div class="col-md-6 col-sm-12">
        <BasicLabel title="Comuna" :required="true" class="fw-bold" />
        <BasicSelectBox
          name="comune"
          class="form-control mt-md-3"
          defaultValueText="Seleccione una comuna"
          :options="comunas"
          v-model="v$.exhibition.comuna_id.$model"
        />
        <span class="text-danger" v-if="v$.exhibition.comuna_id.$error"
          >La Comuna es requerida.</span
        >
      </div>
      <div class="col-md-6 col-sm-12">
        <BasicLabel title="Imagen de portada" class="fw-bold" />
        <BasicInputText
          @input="convertFile($event)"
          :hasRoundedCorners="true"
          class="login-input form-control-sm mb-2"
          :placeholder="'Imagen de portada'"
          :type="'file'"
          v-model="exhibition.portada"
          accept="image/png, image/jpeg"
        />
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import BasicLabel from "@/components/atoms/FormInput/BasicLabel.vue";
import BasicSelectBox from "@/components/atoms/FormInput/BasicSelectBox.vue";
import BasicInputText from "@/components/atoms/FormInput/BasicInputText.vue";
export default {
  name: "ExhibitionNew",
  // eslint-disable-next-line vue/require-prop-types
  setup() {
    return { v$: useVuelidate() };
  },
  props: ["comunas", "modelValue"],
  emits: ["update:modelValue"],
  components: {
    BasicLabel,
    BasicInputText,
    BasicSelectBox,
  },
  data() {
    return {
      portada: null,
      modals: {
        date_start_picker: false,
        time_start_picker: false,
        date_end_picker: false,
        time_end_picker: false,
      },
      exhibition: {
        description: null,
        start_date: {
          date: null,
          time: null,
        },
        end_date: {
          date: null,
          time: null,
        },
        address: null,
        comuna_id: null,
        portada: null,
      },
    };
  },
  watch: {
    modelValue(val) {
      this.exhibition = val;
    },
  },
  mounted() {
    if (this.modelValue ? this.modelValue.description : false)
      this.exhibition = this.modelValue;
    this.$emit("update:modelValue", this.exhibition);
  },
  methods: {
    convertFile(e) {
      this.portada = e.target.files[0];
    },
    validateDateTimes(type, attr) {
      const d1 = this.exhibition[type][attr];
      if (type === "start_date") {
        const d2 = this.exhibition.end_date[attr];
        if (d2 && attr === "date") {
          if (d1 > d2) {
            this.exhibition.end_date.date = null;
          }
        }
        if (d2 && attr === "time") {
          if (
            this.exhibition.start_date.date === this.exhibition.end_date.date &&
            d1 >= d2
          ) {
            this.exhibition.end_date.time = null;
          }
        }
      }
      if (type === "end_date") {
        const d2 = this.exhibition.start_date[attr];
        if (d2 && attr === "date") {
          if (d1 < d2) {
            this.exhibition.end_date.date = null;
          }
        }
        if (d2 && attr === "time") {
          if (
            this.exhibition.start_date.date === this.exhibition.end_date.date &&
            d1 <= d2
          ) {
            this.exhibition.end_date.time = null;
          }
        }
      }
      //this.$refs[ref].save(this.exhibition[type][attr])
    },
  },
  computed: {
    minDate() {
      return new Date().toISOString().slice(0, 10);
    },
  },
  validations() {
    return {
      exhibition: {
        description: { required },
        start_date: {
          date: { required },
          time: { required },
        },
        end_date: {
          date: { required },
          time: { required },
        },
        address: { required },
        comuna_id: { required },
      },
    };
  },
};
</script>

<style scope>
.login-input {
  height: 40px !important;
  padding: 10px 20px !important;
  box-shadow: none !important;
  margin-top: 10px;
}
</style>
