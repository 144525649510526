<template>
  <div
    class="
      d-flex
      justify-content-center
      align-items-center
      flex-column flex-md-row
    "
  >
    <!-- Componente de countdown -->
    <div class="mx-4 my-2">
      <badge-sale>
        <countdown-auction
          v-if="auction.attributes.date"
          :date="auction.attributes.date"
        />
      </badge-sale>
    </div>

    <!-- Entrar a participación del remate como administrador  -->
    <div class="my-2" v-if="isAdmin">
      <button-filled
        type="button"
        @click="toPlaygroundAdminAuction"
        class="fw-bold mx-4 text-white"
        :text="'Entrar al Remate'"
      />
    </div>

    <!-- Entrar a participación del remate como espectador  -->
    <div class="my-2" v-if="!check && auction.attributes.streaming_url">
      <button-filled
        type="button"
        @click="
          $router.push({
            name: 'auction-playground',
            params: {
              slug: auction.attributes.slug,
            },
          })
        "
        class="fw-bold mx-4 text-white"
        :text="'Entrar al Remate como espectador'"
      />
    </div>

    <!-- Entrar a participación del remate como participante  -->
    <div
      class="my-2"
      v-if="(!check || userIsPart) && auction.attributes.streaming_url"
    >
      <button-filled
        type="button"
        @click="redirectView"
        class="fw-bold mx-4 text-white"
        :text="'Entrar al Remate como participante'"
      />
    </div>

    <!-- Solicitar Participación en Remate  -->
    <div class="my-2" v-if="isUser && !userIsPart && statusPart == null">
      <button-filled
        type="button"
        @click="sendRequestForParticipation"
        class="fw-bold mx-4 text-white"
        :text="'Solicitar Participación en Remate'"
      />
    </div>
  </div>
  <div
    class="
      d-flex
      justify-content-center
      align-items-center
      flex-column flex-md-row
    "
  >
    <div class="m-4" v-if="userIsPart && statusPart == statusOnHold">
      <badge-sale>
        <h6 class="pt-2">
          <font-awesome-icon
            class="text-white text-center mx-2"
            :icon="['fas', 'exclamation']"
          />
          Solicitud para participar en el Remate a la espera de aprobación
        </h6>
      </badge-sale>
    </div>
    <div class="m-4" v-if="userIsPart && statusPart == statusApproved">
      <badge-sale color="bg-success">
        <h6 class="pt-2">
          <font-awesome-icon
            class="text-white text-center mx-2"
            :icon="['fas', 'check']"
          />
          ¡Solicitud para participar en el Remate fue aprobada!
        </h6>
      </badge-sale>
    </div>
  </div>
</template>

<script>
import CountdownAuction from "@/components/custom/CountdownAuction.vue";
import BadgeSale from "@/components/atoms/Badge/BadgeSale.vue";
import ButtonFilled from "@/components/atoms/Button/ButtonFilled.vue";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  props: ["userIsPart", "statusPart"],
  components: {
    CountdownAuction,
    BadgeSale,
    ButtonFilled,
  },
  data() {
    return {
      statusApproved: 1,
      statusOnHold: 0,
    };
  },
  computed: {
    isAdmin() {
      return this.check && this.role === "admin";
    },
    isUser() {
      return this.check && this.role === "user";
    },
    ...mapGetters({
      check: "auth/check",
      role: "auth/role",
      user: "auth/user",
      auction: "auction/auction",
    }),
  },
  methods: {
    ...mapActions({
      sendRequestForParticipationAuction:
        "auction/sendRequestForParticipationAuction",
    }),
    //Primero se verifica si el Remate aun no tiene la url del streaming, de ser asi se pedira para poder seguir
    toPlaygroundAdminAuction() {
      if (!this.auction.attributes.streaming_url) {
        return this.$store.commit("toggleModal", "general");
      }
      //si ya tiene url, lo enviamos directo al remate
      this.$router.push({
        name: "auction-playground-admin",
        params: { slug: this.auction.attributes.slug },
      });
    },
    redirectView() {
      if (this.check) {
        if (this.userIsPart) {
          this.$router.push({
            name: "auction-playground",
            params: { slug: this.auction.attributes.slug },
          });
        }
      } else {
        this.$router.push({
          name: "MyAccount",
          params: { id: this.auction.attributes.slug },
        });
      }
    },
    // Enviar solicitud de participacion al remate
    sendRequestForParticipation() {
      this.$swal({
        title: "¿Está seguro?",
        text: "¿Desea enviar una solicitud de participación a éste remate?",
        icon: "info",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Enviar",
      }).then(async (result) => {
        if (result.value) {
          let loader = this.$loading.show();
          const data = await this.sendRequestForParticipationAuction({
            auction_id: this.auction.id,
            user_id: this.user.id,
          });
          this.$swal({
            icon: "success",
            title: "Excelente",
            text: data.message,
            showConfirmButton: false,
            timer: 3000,
          });
          loader.hide();
          this.$emit("changeUserIsPart", true);
        }
      });
    },
  },
};
</script>

<style></style>
