<template>
  <div>
    <layout-default>
      <div class="container mb-4">
        <breadcrumb class="mt-5">
          <link-wrapper
            location="/"
            class="breadcrumb-text text-decoration-none"
          >
            <span class="breadcrumb-home">Inicio</span>
            <span class="breadcrumb-text mx-2"> / </span>
          </link-wrapper>
          <link-wrapper
            location="/admin/products"
            class="breadcrumb-text text-decoration-none"
          >
            <span class="breadcrumb-home">Productos</span>
            <span class="breadcrumb-text mx-2"> / </span>
          </link-wrapper>
          <link-wrapper
            class="breadcrumb-text text-decoration-none"
            :location="`/admin/product/new`"
          >
            <span class="breadcrumb-text text-decoration-none"
              >Nuevo producto</span
            >
          </link-wrapper>
        </breadcrumb>
      </div>
      <div class="mt-5">
        <section class="container col-10 products">
          <div class="d-flex justify-content-center">
            <!-- Card Info Group -->
            <card class="mx-auto">
              <template v-slot:title>
                <b>
                  {{ $route.params.id ? "Editar Producto" : "Nuevo Producto" }}
                </b>
              </template>
              <template v-slot:body>
                <product-form />
              </template>
            </card>
          </div>
        </section>
      </div>
    </layout-default>
  </div>
</template>

<script>
import Card from "@/components/molecules/Card/Card.vue";
import Breadcrumb from "@/components/atoms/Breadcrumb/Breadcrumb.vue";
import LayoutDefault from "@/components/organisms/LayoutDefault/LayoutDefault.vue";
import productForm from "@/components/custom/product/form.vue";
import LinkWrapper from "@/components/atoms/LinkWrapper/LinkWrapper.vue";

export default {
  name: "AdminProductsNew",
  middleware: ["auth", "admin"],
  components: {
    productForm,
    LayoutDefault,
    Card,
    Breadcrumb,
    LinkWrapper,
  },
  created() {
    this.$store.commit("setLocation", []);
  },
};
</script>
