<template>
  <div class="row single-product d-flex" v-if="product">
    <div class="col-lg-5 col-md-7 col-sm-12" v-if="listImages.length">
      <product-slider
        class="product-image"
        :photos="listImages"
      ></product-slider>
    </div>
    <div
      class="
        product-details-col
        col-lg-7 col-md-5 col-sm-12
        d-flex
        flex-column
        mt-4 mt-md-0
      "
      :class="{ 'col-lg-12 col-md-12': !listImages.length }"
    >
      <div
        class="current-bid-title border-bottom d-flex justify-content-between"
      >
        <span class="current-bid fw-bold">
          {{ product.attributes.name }}
        </span>
        <div v-if="$route.name === 'admin-product-show'">
          <tooltip-icon
            text="Ver Producto"
            icon="eye"
            class-icon="text-primary"
            @click="
              $router.push({
                name: 'DirectSaleProduct',
                params: { id: product.id },
              })
            "
          ></tooltip-icon>
          <tooltip-icon
            text="Editar Producto"
            icon="pencil-alt"
            class-icon="text-success"
            @click="
              $router.push({
                name: 'admin-product-edit',
                params: { id: product.id },
              })
            "
          ></tooltip-icon>
          <tooltip-icon
            text="Eliminar Producto"
            icon="trash-alt"
            class-icon="text-danger"
            @click.prevent="
              destroyProduct({
                id: product.id,
                entity: 'products',
              })
            "
          ></tooltip-icon>
        </div>
      </div>
      <section class="product-details p-2">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <card-item
              :text="`${
                product.relationships.currency.code !== 'UF'
                  ? product.relationships.currency.code
                  : ''
              } ${product.attributes.amount_format}`"
              icon="dollar-sign"
              label="Precio de Venta"
            ></card-item>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12">
            <card-item
              :text="product.attributes.status"
              icon="exclamation"
              label="Estado:"
            ></card-item>
          </div>

          <div class="col-lg-6 col-md-12 col-sm-12">
            <card-item
              :text="product.attributes.commission_text"
              icon="gavel"
              label="Comisión"
            ></card-item>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12">
            <card-item
              :text="product.attributes.iva_text"
              icon="dollar-sign"
              label="IVA"
            ></card-item>
          </div>
          <div class="col-md-12 col-sm-12">
            <div class="d-flex align-items-center">
              <div class="rounded-circle bg-theme icon-label">
                <font-awesome-icon
                  class="text-white text-center"
                  :icon="['fas', 'align-justify']"
                />
              </div>
              <h6 class="product-condition">Descripción:</h6>
            </div>
            <b
              style="font-size: 15px !important"
              v-html="product.attributes.description"
            ></b>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ProductSlider from "@/components/atoms/Carousel/ProductSlider.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import CardItem from "@/components/molecules/Card/CardItem.vue";
import TooltipIcon from "@/components/atoms/TooltipIcon.vue";

export default {
  components: {
    ProductSlider,
    CardItem,
    TooltipIcon,
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    destroyProduct(item) {
      const { id, entity } = item;
      this.$swal({
        title: "¿Está seguro?",
        text: "¡No podrá revertir esta opción!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Eliminar",
      }).then(async (result) => {
        if (result.value) {
          let loader = this.$loading.show();
          try {
            const { data } = await axios.delete(`/api/${entity}/${id}`);
            loader.hide();
            this.$swal({
              icon: "success",
              title: "Excelente",
              text: data.message,
              showConfirmButton: false,
              timer: 3000,
            }).then(async () => {
              this.$router.push({
                name: "AdminProducts",
              });
            });
          } catch (e) {
            loader.hide();
          }
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      product: "product/product",
    }),
    listImages() {
      if (this.product) {
        return this.product.relationships.images;
      }
      return [];
    },
    coverImage() {
      let image = this.listImages.find((image) => {
        return image.portada === "Portada";
      });
      return image;
    },
  },
};
</script>

<style>
.icon-label {
  margin-right: 1rem;
  min-width: 2.5rem !important;
  padding: 0.4rem 0.5rem;
  text-align: center;
}
</style>
