<template>
  <form
    ref="formGroup"
    @submit.prevent="sendData"
    class="login-form d-flex flex-column p-md-4"
  >
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <BasicLabel title="Nombre" :required="true" class="fw-bold" />
        <BasicInputText
          :hasRoundedCorners="true"
          class="login-input mb-2"
          :placeholder="'Nombre'"
          v-model="v$.group.name.$model"
          required
        />
        <span class="text-danger" v-if="v$.group.name.$error"
          >El Nombre es requerido.</span
        >
      </div>
      <div class="col-md-6 col-sm-12">
        <BasicLabel
          title="Imagen de portada"
          :required="!this.$route.params.id ? true : false"
          class="fw-bold"
        />
        <BasicInputText
          @input="convertFile($event, 'portada')"
          :hasRoundedCorners="true"
          class="login-input form-control-sm mb-2"
          :placeholder="'Imagen de portada'"
          :type="'file'"
          v-model="v$.group.portada.$model"
          :required="!this.$route.params.id ? true : false"
          accept="image/png, image/jpeg"
        />
        <span class="text-danger" v-if="v$.group.portada.$error"
          >La Imagen de portada es requerido.</span
        >
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <BasicLabel title="Fecha de inicio" :required="true" class="fw-bold" />
        <BasicInputText
          :hasRoundedCorners="true"
          class="login-input mb-2"
          v-model="v$.group.start_date.$model"
          required
          type="date"
          :min="minStart"
          @input="validateDateTimes('start_date', 'startDate')"
        />
        <span class="text-danger" v-if="v$.group.start_date.$error"
          >La Fecha de inicio es requerida.</span
        >
      </div>
      <div class="col-md-6 col-sm-12">
        <BasicLabel title="Fecha final" class="fw-bold" />
        <BasicInputText
          :hasRoundedCorners="true"
          class="login-input mb-2"
          v-model="v$.group.end_date.$model"
          type="date"
          :min="minEnd"
          @input="validateDateTimes('end_date', 'endDate')"
        />
      </div>
      <!-- eslint-enable -->
    </div>
    <div class="row mt-2">
      <div class="col-12 col-md-12 col-sm-12">
        <BasicLabel title="Descripción" :required="true" class="fw-bold" />
        <basic-text-area
          :hasRoundedCorners="true"
          class="text-area-basic mb-3 mt-1"
          :placeholder="'Descripción'"
          v-model="v$.group.description.$model"
          required
        />
        <span class="text-danger" v-if="v$.group.description.$error"
          >La Descripción es requerido.</span
        >
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <BasicLabel title="Base" class="fw-bold" />
        <BasicInputText
          :hasRoundedCorners="true"
          class="login-input form-control-sm mb-3"
          :placeholder="'Base'"
          :type="'file'"
          v-model="v$.group.bases.$model"
          @input="convertFile($event, 'base')"
          accept=".pdf"
        />
      </div>
      <div class="col-md-6 col-sm-12">
        <BasicLabel
          title="Comisión de Martillero (%)"
          :required="true"
          class="fw-bold"
        />
        <BasicInputText
          :hasRoundedCorners="true"
          class="login-input mb-3"
          :placeholder="'Comisión de Martillero (%)'"
          v-model="v$.group.commission_auctioneer.$model"
          required
          :type="'number'"
        />
        <span class="text-danger" v-if="v$.group.commission_auctioneer.$error"
          >La Comisión del Martillero es requerida.</span
        >
      </div>
    </div>
    <div class="row mx-0">
      <div class="col-md-6 col-sm-12 mt-2">
        <div class="form-check form-switch">
          <input
            class="form-check-input mb-4"
            type="checkbox"
            id="flexSwitchCheckDefault"
            name="flexSwitchCheckDefault"
            role="switch"
            v-model="v$.group.visibility_public.$model"
          />
          <BasicLabel
            title="Visibilidad al público"
            class="form-check-label fw-bold"
            for="flexSwitchCheckDefault"
          />
        </div>
      </div>
      <div class="col-md-6 col-sm-12 mt-2">
        <div class="form-check form-switch">
          <input
            class="form-check-input mb-4"
            type="checkbox"
            id="flexSwitchCheckDefault2"
            name="flexSwitchCheckDefault2"
            role="switch"
            v-model="v$.group.only_buy.$model"
          />
          <BasicLabel
            title="Solo compra"
            class="form-check-label fw-bold"
            for="flexSwitchCheckDefault2"
          />
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 col-md-12 col-sm-12">
        <BasicLabel title="Términos y condiciones" class="fw-bold" />
        <basic-text-area
          class="fw-light text-area-basic mt-1 mb-2"
          :placeholder="'Términos y condiciones'"
          v-model="v$.group.terms_and_conditions.$model"
        />
      </div>
    </div>
    <div class="d-flex justify-content-end mt-4">
      <ButtonFilled
        type="submit"
        :disabled="!valid"
        class="Minicart_button text-white"
        :text="id ? 'Editar' : 'Crear'"
        :hasRoundedCorners="true"
      />
    </div>
  </form>
</template>

<script>
import axios from "axios";
import moment from "moment";
import useVuelidate from "@vuelidate/core";
import BasicInputText from "@/components/atoms/FormInput/BasicInputText.vue";
import ButtonFilled from "@/components/atoms/Button/ButtonFilled.vue";
import BasicLabel from "@/components/atoms/FormInput/BasicLabel.vue";
import { required } from "@vuelidate/validators";
import BasicTextArea from "@/components/atoms/FormInput/BasicTextArea.vue";
export default {
  name: "GroupFormComponent",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    BasicInputText,
    BasicLabel,
    ButtonFilled,
    BasicTextArea,
  },
  data() {
    return {
      // Group Data
      id: this.$route.params.id,

      // Modals
      modals: {
        date_start_picker: false,
        date_end_picker: false,
      },

      portada: null,
      bases: null,

      group: {
        name: null,
        portada: null,
        description: null,
        terms_and_conditions: null,
        bases: null,
        start_date: null,
        end_date: null,
        visibility_public: null,
        only_buy: null,
        commission_auctioneer: null,
      },

      // Validate Data
      valid: true,
    };
  },
  created() {
    if (this.$route.params.id) {
      this.fetchGroup();
    }
  },
  methods: {
    backRouter(e) {
      e.prevent.default();
      this.$router.back();
    },
    validateDateTimes(type) {
      const d1 = this.group[type];
      if (type === "start_date") {
        const d2 = this.group.end_date;
        if (d1 >= d2) {
          this.group.end_date = null;
        }
      }
      if (type === "end_date") {
        const d2 = this.group.start_date;
        if (d1 <= d2) {
          this.group.end_date = null;
        }
      }
      // this.$refs[ref].save(this.group[type]);
    },
    async fetchGroup() {
      let loader = this.$loading.show();
      try {
        const { data } = (await axios.get(`/api/groups/${this.id}`)).data;
        const { attributes } = data;
        this.group = {
          name: attributes.name,
          description: attributes.description,
          terms_and_conditions: attributes.terms_and_conditions,
          commission_auctioneer: attributes.commission_auctioneer,
          start_date: attributes.start_date,
          end_date: attributes.end_date,
          visibility_public: Boolean(attributes.visibility_public),
          only_buy: Boolean(attributes.only_buy),
          portada: null,
        };
        // eslint-disable-next-line
      } catch (e) {}
      loader.hide();
    },
    convertFile(e, type) {
      if (type === "portada") {
        this.portada = e.target.files[0];
      } else {
        this.bases = e.target.files[0];
      }
    },
    async sendData() {
      const isFormCorrect = await this.v$.$validate();
      let loader = this.$loading.show();
      if (isFormCorrect) {
        try {
          let groupToSend = new FormData();
          for (const key in this.group) {
            if (this.group[key]) {
              groupToSend.append(
                key,
                key === "portada"
                  ? this.portada
                  : key === "bases"
                  ? this.bases
                  : this.group[key]
              );
            }
          }
          let config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };
          const url = this.id ? `/api/groups/${this.id}` : "/api/groups";
          const { data } = await axios.post(url, groupToSend, config);

          let id = this.id ? this.id : data.id;

          this.$swal({
            icon: "success",
            title: "Excelente",
            text: data.message,
            showConfirmButton: false,
            timer: 3000,
          }).then(async () => {
            this.$router.push({
              name: "admin-groups-show",
              params: { id: id },
            });
          });
          // eslint-disable-next-line
        } catch (e) {}
        loader.hide();
      }
    },
  },
  validations() {
    if (this.$route.params.id) {
      return {
        group: {
          name: { required },
          portada: {},
          start_date: { required },
          end_date: {},
          description: { required },
          bases: {},
          commission_auctioneer: { required },
          visibility_public: {},
          only_buy: {},
          terms_and_conditions: {},
        },
      };
    } else {
      return {
        group: {
          name: { required },
          portada: { required },
          start_date: { required },
          end_date: {},
          description: { required },
          bases: {},
          commission_auctioneer: { required },
          visibility_public: {},
          only_buy: {},
          terms_and_conditions: {},
        },
      };
    }
  },
  computed: {
    minStart() {
      return moment(new Date()).format("YYYY-MM-DD");
    },
    minEnd() {
      return moment(this.group.start_date).add("2", "day").format("YYYY-MM-DD");
    },
  },
};
</script>
<style>
.customer-checkbox {
  margin-top: 10px;
}
.text-area-basic {
  border-radius: 0.5rem !important;
  border: 1px solid #dbdbdb !important;
}
.login-input {
  height: 40px !important;
  padding: 10px 20px !important;
  box-shadow: none !important;
  margin-top: 10px;
}
.form-check {
  padding-left: 27px !important;
}
.login-field,
.login-field-last {
  margin-top: 30px !important;
}

.Minicart_button {
  width: 150px !important;
  height: 40px !important;
}
@media screen and (max-width: 768px) {
  .login-input {
    width: 100% !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .login-input {
    width: 250px !important;
  }
  .form-check-input input {
    width: 15px !important;
  }
}
</style>
