<template>
  <div class="row single-product d-flex" v-if="lot">
    <div class="col-lg-5 col-md-7 col-sm-12" v-if="listImages.length">
      <product-slider
        class="product-image"
        :photos="listImages"
      ></product-slider>
    </div>
    <div
      class="
        product-details-col
        col-lg-7 col-md-5 col-sm-12
        d-flex
        flex-column
        mt-4 mt-md-0
      "
      :class="{ 'col-lg-12 col-md-12': !listImages.length }"
    >
      <div
        class="current-bid-title border-bottom d-flex justify-content-between"
      >
        <span class="current-bid fw-bold">
          {{ lot.attributes.name }}
        </span>
      </div>
      <section class="product-details p-2">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <card-item
              :text="lot.attributes.number"
              icon="dollar-sign"
              label="Numero de Lote"
            ></card-item>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12">
            <card-item
              :text="lot.attributes.iva"
              icon="exclamation"
              label="IVA"
            ></card-item>
          </div>

          <div class="col-lg-6 col-md-12 col-sm-12">
            <card-item
              :text="lot.attributes.minimum"
              icon="gavel"
              label="Minimo"
            ></card-item>
          </div>
          <div class="col-md-12 col-sm-12">
            <div class="d-flex align-items-center">
              <div class="rounded-circle bg-theme icon-label">
                <font-awesome-icon
                  class="text-white text-center"
                  :icon="['fas', 'align-justify']"
                />
              </div>
              <h6 class="product-condition">Descripcion:</h6>
            </div>
            <b
              style="font-size: 15px !important"
              v-html="lot.attributes.description"
            ></b>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import ProductSlider from "@/components/atoms/Carousel/ProductSlider.vue";
import { mapGetters } from "vuex";
import CardItem from "@/components/molecules/Card/CardItem.vue";

export default {
  components: {
    ProductSlider,
    CardItem,
  },
  computed: {
    ...mapGetters({
      lot: "lot/lot",
    }),
    listImages() {
      if (this.lot) {
        return this.lot.relationships.images;
      }
      return [];
    },
    coverImage() {
      let image = this.listImages.find((image) => {
        return image.portada === "Portada";
      });
      return image;
    },
  },
};
</script>

<style>
.icon-label {
  margin-right: 1rem;
  min-width: 2.5rem !important;
  padding: 0.4rem 0.5rem;
  text-align: center;
}
</style>
