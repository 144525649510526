<template>
  <table class="table table-hover table-responsive card-table">
    <thead :class="bgHeader ? bgHeader : 'bg-theme text-white'">
      <tr class="">
        <th
          scope="col"
          class="px-3 text-center"
          v-for="header in headers"
          :key="header.value"
        >
          {{ header.text }}
        </th>
      </tr>
    </thead>
    <tbody v-if="items.length">
      <template v-for="item in items" :key="item.id">
        <tr>
          <td class="text-center" v-for="header in headers" :key="header.value">
            <slot :name="header.value" v-bind:item="item">
              <p class="" v-html="getValueByPath(item, header.value)"></p>
            </slot>
          </td>
        </tr>
        <slot name="collapse" v-bind:item="item"></slot>
      </template>
    </tbody>
    <tbody v-else>
      <tr class="w-100">
        <td :colspan="headers.length">
          <p class="text-center my-1 mx-auto">Sin Datos</p>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { getObjectValueByPath } from "@/utils";
export default {
  name: "CustomTable",
  props: {
    headers: {
      type: Array,
    },
    items: {
      type: Array,
    },
    bgHeader: {
      type: String,
    },
  },
  methods: {
    getValueByPath(item, header) {
      return getObjectValueByPath(item, header);
    },
  },
};
</script>

<style>
.card-table {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* this adds the "card" effect */
  padding: 16px;
}

table > :not(caption) > * > * {
  padding: 1rem 1rem !important;
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
</style>
