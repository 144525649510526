<template>
  <layout-default>
    <div class="container mt-5">
      <div class="row">
        <div
          class="
            filter-col
            col-md-3 col-sm-12
            pe-4
            d-none d-md-block
            filter-mobile
          "
        >
          <div class="filter-search-shop">
            <div>
              <filter-title
                class="title mb-3 mt-0"
                title="Resultados de la busqueda"
              />
            </div>
          </div>
        </div>
        <div class="col-md-9 col-sm-12 shop-grid" id="topGrid">
          <div class="fluid-container filter-bar-shop">
            <filter-bar
              :totalResults="itemsResults.length"
              :itemsShowing="productsPerPage().length"
            >
              <template v-slot:left>
                <change-layout-buttons
                  class="me-4"
                  @onChange="layout = !layout"
                ></change-layout-buttons>
              </template>
            </filter-bar>
          </div>
          <keep-alive>
            <component
              :search="true"
              :is="layout === true ? 'Grid' : 'List'"
              :items="productsPerPage()"
              :itemsPerRow="3"
            />
          </keep-alive>

          <h5 class="text-center mt-4" v-if="!itemsResults.length">
            Sin resultados..
          </h5>
          <pagination-buttons
            class="pagination-shop mx-auto"
            v-if="itemsResults.length / itemsPerPage > 1"
            resetID="topGrid"
            :pageNumbers="Math.ceil(itemsResults.length / itemsPerPage)"
            @onChangePageNoRequest="updatePage"
            :currentPage="
              $route.params.page === undefined
                ? currentPage
                : parseInt($route.params.page)
            "
          ></pagination-buttons>
        </div>
      </div>
    </div>
  </layout-default>
</template>

<script>
import LayoutDefault from "@/components/organisms/LayoutDefault/LayoutDefault.vue";
import FilterTitle from "@/components/atoms/Filter/FilterTitle.vue";
import FilterBar from "@/components/atoms/Filter/FilterBar.vue";
import ChangeLayoutButtons from "@/components/atoms/Button/ButtonDouble.vue";
import Grid from "@/components/molecules/Layout/Grid.vue";
import List from "@/components/molecules/Layout/List.vue";
import { mapGetters } from "vuex";
import PaginationButtons from "@/components/atoms/PaginationButtons/PaginationButtons.vue";

export default {
  components: {
    LayoutDefault,
    FilterTitle,
    FilterBar,
    ChangeLayoutButtons,
    Grid,
    List,
    PaginationButtons,
  },
  data() {
    return {
      itemsPerPage: 9,
      currentPage: 1,
      layout: true,
      showFromto: 0, // Desde
      showUpto: 9, // hasta
    };
  },
  computed: {
    ...mapGetters({ itemsResults: "search/itemsResults" }),
  },
  methods: {
    productsPerPage() {
      if (this.itemsResults.length) {
        return this.itemsResults.slice(this.showFromto, this.showUpto);
      }
      return [];
    },
    updatePage(pageNo) {
      this.showFromto = (pageNo - 1) * this.itemsPerPage;
      this.showUpto = pageNo * this.itemsPerPage;
    },
  },
};
</script>

<style></style>
