<template>
  <layout-default>
    <!-- Info Group -->
    <div class="container">
      <div class="container my-account-page d-flex flex-column">
        <div class="my-account-page-location">
          <div class="my-account-breadcrumb border-bottom">
            <Breadcrumb />
          </div>
        </div>
      </div>
      <div class="mt-5" v-if="group">
        <section class="container col-10 products">
          <div class="d-flex justify-content-center">
            <!-- Card Info Group -->
            <card-group :group="group" :destroy-function="destroy" />
          </div>
        </section>
      </div>
    </div>
  </layout-default>
</template>

<script>
import axios from "axios";
import LayoutDefault from "@/components/organisms/LayoutDefault/LayoutDefault.vue";
import Breadcrumb from "@/components/atoms/Breadcrumb/Breadcrumb.vue";
import CardGroup from "@/components/custom/product/groups/CardGroup";

export default {
  name: "AdminGroupsShow",
  components: {
    CardGroup,
    LayoutDefault,
    Breadcrumb,
  },
  data() {
    return {
      // Data Group
      id: this.$route.params.id,
      group: {
        id: 0,
        attributes: {},
      },

      // DataTable Products
      products: [],
      headerProducts: [],
    };
  },
  created() {
    this.fetchGroup();
  },
  mounted() {
    this.$store.commit("setLocation", [
      { title: "Grupos", location: "/admin/products" },
      {
        title: "Grupo",
        location: `/admin/groups/show/${this.$route.params.id}`,
      },
    ]);
  },
  methods: {
    async fetchGroup() {
      try {
        const { data } = (await axios.get(`/api/groups/${this.id}`)).data;
        this.group = data;
      } catch (e) {
        console.log(e);
      }
    },
    destroy(item) {
      const { id, entity } = item;
      this.$swal({
        title: "¿Está seguro?",
        text: "¡No podrá revertir esta opción!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Eliminar",
      }).then(async (result) => {
        if (result.value) {
          this.$store.commit("config/SET_OVERLAY", true);
          try {
            const { data } = await axios.delete(`/api/${entity}/${id}`);
            this.$swal({
              icon: "success",
              title: "Excelente",
              text: data.message,
              showConfirmButton: false,
              timer: 3000,
              onClose: async () => {
                this.$router.push({ name: "admin-products" });
              },
            });
          } catch (e) {
            this.$store.commit("config/SET_OVERLAY", false);
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.account-page {
  padding-bottom: 30px;
}
.my-account-breadcrumb {
  padding-top: 20px;
  padding-bottom: 20px;
}
.my-account-title {
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .my-account {
    display: block !important;
  }
  .my-account-breadcrumb {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .account-page {
    padding: 0px !important;
    display: flex !important;
    flex-direction: column !important;
  }
  .login-input {
    width: 270px !important;
  }
  .my-account-breadcrumb {
    width: 350px;
  }
  .col-md-6 {
    padding: 35px 0px 0px 0px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .account-page {
    padding: 50px 10px !important;
  }
  .my-account {
    justify-content: space-between !important;
  }
  .my-account-page,
  .my-account {
    padding: 0px !important;
  }
}
</style>
