<template>
  <form @submit.prevent="sendData" class="px-md-5 py-1">
    <div class="row mb-2 align-items-center">
      <div class="col-12 col-md-5 col-sm-12">
        <basic-label title="Nombre" :required="true" class="fw-bold" />
        <basic-input-text
          :hasRoundedCorners="true"
          class="login-input mb-2 mt-1"
          :placeholder="'Nombre'"
          v-model="v$.lot.name.$model"
          required
        />
        <span class="text-danger" v-if="v$.lot.name.$error"
          >El Nombre es requerido.</span
        >
      </div>
      <div
        class="col-md-2 col-sm-12"
        :class="[lot.chkminimum ? 'mx-0' : 'mx-3']"
      >
        <div
          class="
            login-input
            form-check form-switch
            mt-4
            d-flex
            justify-content-center
          "
        >
          <input
            class="form-check-input mb-4"
            type="checkbox"
            name="flexSwitchCheckDefault"
            role="switch"
            v-model="v$.lot.iva.$model"
          />
          <basic-label
            title="IVA"
            class="form-check-label fw-bold mx-3"
            for="flexSwitchCheckDefault"
          />
        </div>
      </div>
      <div class="col-md-2 col-sm-12">
        <div
          class="
            login-input
            form-check form-switch
            mt-4
            d-flex
            justify-content-center
          "
        >
          <input
            class="form-check-input mb-4"
            type="checkbox"
            name="flexSwitchCheckDefault"
            role="switch"
            v-model="v$.lot.chkminimum.$model"
            @change="
              lot.minimum = null;
              setDefaultCurrency();
            "
          />
          <basic-label
            title="Minimo"
            class="form-check-label fw-bold mx-3"
            for="flexSwitchCheckDefault"
          />
        </div>
      </div>
      <div class="col-md-3 col-sm-12" v-if="lot.chkminimum">
        <basic-label title="Minimo" :required="true" class="fw-bold" />
        <basic-input-text
          :hasRoundedCorners="true"
          class="login-input mb-2"
          :placeholder="'Minimo'"
          v-model="v$.lot.minimum.$model"
          type="number"
          required
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-5 mt-3">
        <BasicLabel title="Estado" class="fw-bold" />
        <basic-select-box
          name="status"
          :disabled="!lotId"
          class="form-control login-input mt-1"
          defaultValueText="Seleccione un Estado"
          :options="status"
          v-model="v$.lot.status.$model"
        />
      </div>
      <div class="col-sm-12 col-md-3 mx-auto mt-0 mt-sm-4">
        <BasicLabel title="Boton de Oferta Izquierda" class="fw-bold" />
        <basic-select-box
          name="bid_one"
          class="form-control login-input mt-1"
          defaultValueText="Botón de Oferta izquierda"
          :options="bids"
          v-model="v$.lot.bid_one.$model"
        />
      </div>
      <div class="col-sm-12 col-md-3 mt-0 my-sm-4">
        <BasicLabel title="Boton de Oferta Derecha" class="fw-bold" />
        <basic-select-box
          name="bid_two"
          class="form-control login-input mt-1"
          defaultValueText="Botón de Oferta Derecha"
          :options="bids"
          v-model="v$.lot.bid_two.$model"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6" v-if="lot.chkminimum">
        <BasicLabel title="Moneda" class="mb-1 fw-bold" />
        <basic-select-box
          name="bid_two"
          class="form-control login-input mt-1"
          defaultValueText="Seleccione una Moneda"
          :options="currencys"
          v-model="v$.lot.currency_id.$model"
        />
      </div>
      <div class="col-sm-12 col-md-12 mt-3">
        <BasicLabel title="Descripción" class="mb-1 fw-bold" />
        <ckeditor
          class="login-input"
          v-model="v$.lot.description.$model"
          :editor="editor"
          :config="editorConfig"
        ></ckeditor>
        <span class="text-danger" v-if="v$.lot.description.$error"
          >La Descripción es requerida.</span
        >
      </div>
    </div>
    <div class="d-flex justify-content-end mt-4">
      <button-filled
        type="submit"
        class="text-white"
        :text="type"
        :hasRoundedCorners="true"
      />
    </div>
  </form>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { currencys } from "@/constants/currency";
import BasicLabel from "@/components/atoms/FormInput/BasicLabel.vue";
import BasicInputText from "@/components/atoms/FormInput/BasicInputText.vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import BasicSelectBox from "@/components/atoms/FormInput/BasicSelectBox.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/es";
import ButtonFilled from "@/components/atoms/Button/ButtonFilled.vue";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: { BasicLabel, BasicInputText, BasicSelectBox, ButtonFilled },
  name: "LotFormComponent",
  props: {
    modelValue: {
      default: {
        description: "",
      },
    },
    value: { default: null },
    auctionSlug: { default: null },
    lotId: { default: null },
    type: { type: String, default: "crear" },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      PESO_CURRENCY: currencys.PESO_CURRENCY,
      UF_CURRENCY: currencys.UF_CURRENCY,
      USD_CURRENCY: currencys.USD_CURRENCY,
      // Data Lot
      lot: {
        number: null,
        name: null,
        description: null,
        iva: true,
        chkminimum: false,
        minimum: null,
        status: 1,
        auction: this.auctionSlug,
        bid_one: 50000,
        bid_two: null,
        currency_id: 1,
      },

      valid: true,

      // Status Select Data
      status: [
        { name: "En espera", id: 1 },
        { name: "En remate", id: 2 },
        { name: "Adjudicado", id: 3 },
      ],

      // Automatic Bids
      bids: [],
      bidsPesos: [
        { id: 50000, name: "$50.000" },
        { id: 100000, name: "$100.000" },
        { id: 200000, name: "$200.000" },
        { id: 500000, name: "$500.000" },
        { id: 1000000, name: "$1.000.000" },
        { id: 2000000, name: "$2.000.000" },
      ],
      bidsUf: [
        { id: 5, name: "UF5" },
        { id: 10, name: "UF10" },
        { id: 20, name: "UF20" },
        { id: 50, name: "UF50" },
        { id: 100, name: "UF100" },
        { id: 200, name: "UF200" },
        { id: 250, name: "UF250" },
        { id: 500, name: "UF500" },
        { id: 1000, name: "UF1000" },
        { id: 2000, name: "UF2000" },
        { id: 2500, name: "UF2500" },
        { id: 5000, name: "UF5000" },
        { id: 10000, name: "UF10000" },
        { id: 20000, name: "UF20000" },
      ],
      //CKEditor
      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
        language: "es",
      },
    };
  },
  async mounted() {
    if (this.lotId && this.type === "editar") {
      await this.fetchLot();
      this.bids =
        this.lot.currency_id == this.PESO_CURRENCY ||
        this.lot.currency_id == this.USD_CURRENCY
          ? this.bidsPesos
          : this.bidsUf;
      this.status.forEach((st) => {
        if (st.id === this.lot.status) st.name = this.lot.status_text;
      });
    } else {
      this.bids = this.bidsPesos;
    }
    await this.$store.dispatch("currency/fetchCurrencys");
  },
  computed: {
    ...mapGetters({
      currencys: "currency/currencys",
    }),
  },
  methods: {
    async fetchLot() {
      let loader = this.$loading.show();
      try {
        const { data } = (await axios.get(`/api/lots/${this.lotId}`)).data;
        const { attributes } = data;
        const {
          number,
          name,
          description,
          iva,
          minimum,
          min,
          status,
          status_text,
          bid_one,
          bid_two,
          currency_id,
        } = attributes;
        this.lot = {
          number,
          name,
          description,
          iva:
            iva.toLowerCase() === "aplica" || iva.toLowerCase() === "si"
              ? true
              : false,
          minimum: min,
          status,
          status_text,
          chkminimum: !Number(minimum) ?? minimum.toLowerCase() !== "no aplica",
          bid_one,
          bid_two,
          currency_id,
        };
      } catch (e) {
        return e;
      } finally {
        loader.hide();
      }
    },
    setBidsValues() {
      if (this.lot.currency_id == this.UF_CURRENCY) {
        this.bids = this.bidsUf;
      } else {
        this.bids = this.bidsPesos;
      }
    },
    setDefaultCurrency() {
      if (!this.lot.chkminimum) {
        //Moneda Chilena
        this.lot.currency_id = 1;
      }
    },
    async sendData() {
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        let loader = this.$loading.show();
        try {
          let met = "";
          let url = "";
          if (this.lotId) {
            met = "patch";
            url = `/api/lots/${this.lotId}`;
          } else {
            met = "post";
            url = "/api/lots";
          }
          const { data } = await axios[met](url, this.lot);
          this.$swal({
            icon: "success",
            title: "Excelente",
            text: data.message,
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            this.$router.push({
              name: "admin-auctions-lots-show",
              params: { slug: this.auctionSlug, id: data.id },
            });
          });
        } catch (e) {
          const { status } = e.response || {};
          if (status === 409) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: e.response.data.errors.detail,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        } finally {
          loader.hide();
        }
      }
    },
  },
  validations() {
    return {
      lot: {
        name: { required },
        iva: {},
        chkminimum: {},
        minimum: {},
        status: {},
        bid_one: {},
        bid_two: {},
        currency_id: {},
        description: { required },
      },
    };
  },
};
</script>
<style>
.title-description {
  font-size: 13px;
  color: gray;
}

.ck-editor__editable_inline {
  min-height: 200px;
}

@media screen and (max-width: 768px) {
  .ck-editor__editable_inline {
    width: 270px;
  }

  .ck.ck-editor__top {
    width: 270px;
  }
}
</style>
