<template>
  <div class="row align-items-stretch mt-4" v-for="item in items" :key="item">
    <keep-alive>
      <component
        :is="search ? 'SearchCardWide' : 'ProductCardWide'"
        :product="item"
      />
    </keep-alive>
  </div>
</template>

<script>
import ProductCardWide from "../Product/ProductCardWide.vue";
import SearchCardWide from "@/components/custom/SearchCardWide";

export default {
  components: { ProductCardWide, SearchCardWide },
  props: {
    items: {
      type: Array,
      required: true,
    },
    search: {
      Type: Boolean,
      default: false,
    },
  },
};
</script>
