<template>
  <div class="container w-50 mb-4 banner-shop text-center">
    <h1 class="banner-title">{{ title }}</h1>
    <p v-if="subtitle" class="banner-paragraph">{{ subtitle }}</p>
  </div>
</template>
<script>
export default {
  props: ["title", "subtitle"],
};
</script>
<style scoped="scoped">
.banner-shop {
  background: #1e9e1a;
  background-size: cover;
  border-radius: 0.5rem;
  padding: 20px 20px;
}
.banner-title {
  color: #ffff;
  font-weight: 600;
  font-size: 20px;
}
.banner-paragraph {
  color: #ffff;
  font-size: 15px;
}
</style>
