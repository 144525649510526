<template>
  <layout-default>
    <div class="container contact-container">
      <h2>Preguntas Frecuentes (FAQs)</h2>
      <div class="accordion accordion-flush mt-2" id="accordionFlushExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingOne">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
              <b>COMO PARTICIPAR UN REMATE</b>
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <ol style="color: #5e5553 !important">
                <li>
                  Iniciar sesión, en nuestra pagina www.remateslasamericas.com ,
                  si no tiene una debe realizar el registro con sus datos e
                  ingresar.
                </li>
                <li>
                  Seleccione el bien en el cuál se encuentra interesado e
                  inscribase.
                </li>
                <li>Ingrese su garantia previa</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingTwo">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseTwo"
              aria-expanded="false"
              aria-controls="flush-collapseTwo"
            >
              <b>ABRE UNA CUENTA CON NOSOTROS</b>
            </button>
          </h2>
          <div
            id="flush-collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingTwo"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              Sigue el paso a paso y llena el formulario con nosotros. Abrir una
              cuenta con REMATES LAS AMERICAS no tiene costo alguno.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree"
              aria-expanded="false"
              aria-controls="flush-collapseThree"
            >
              <b>COMO INGRESO AL REMATE</b>
            </button>
          </h2>
          <div
            id="flush-collapseThree"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingThree"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              Haga click en el remate que es de su interes.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingThree2">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree2"
              aria-expanded="false"
              aria-controls="flush-collapseThree2"
            >
              <b>QUE COMISIONES DEBO PAGAR SI ME ADJUDICO UN BIEN</b>
            </button>
          </h2>
          <div
            id="flush-collapseThree2"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingThree2"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              <b>BIENES INMUEBLES:</b> (APARTAMENTOS, CASAS, TERRENOS, OFICINAS)
              3% del valor total de adjudicación + impuestos vigentes en cada
              país donde se encuente el bien. <br />
              <b>EQUIPO DE TRANSPORTE:</b> 10% del valor total de adjudicación +
              impuestos vigentes en cada país donde se encuente el bien. <br />
              <b>BIENES MUEBLES:</b> (RELOJES, CUADROS, MUEBLES, JOYAS,
              MERCANCIA EN GENERAL) 10% del valor total de adjudicación +
              impuestos vigentes en cada país donde se encuente el bien.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingThree3">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree3"
              aria-expanded="false"
              aria-controls="flush-collapseThree3"
            >
              <b>QUE ES UNA GARANTIA</b>
            </button>
          </h2>
          <div
            id="flush-collapseThree3"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingThree3"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              Es un depósito efectuado a la cuenta bancaria de REMATES LAS
              AMERICAS, por la suma unica de una base de 500 USD, para
              garantizar la seriedad de ofertas del comprador. <br />
              ESCALONADO: <br />
              500USD hasta 30.000USD. <br />
              31.000 USD HASTA 100.000 USD. <br />
              3.000 USD SOBRE 100.000 EL 10%.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingThree4">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree4"
              aria-expanded="false"
              aria-controls="flush-collapseThree4"
            >
              <b
                >QUE PASA CON MI GARANTIA SI NO ME ADJUDICO EL BIEN EN LA
                SUBASTA
              </b>
            </button>
          </h2>
          <div
            id="flush-collapseThree4"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingThree4"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              Si no se adjudica el bien, su garantia será restituida en un
              tiempo maximo de 48 horas habiles contadas desde el cierre de la
              subasta. ¡No tema a subastar no hay mejor forma de comprar!.
              <b
                >Si usted esta dentro de los primeros 3 compradores será
                restituido en 72 horas.
              </b>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingThree5">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree5"
              aria-expanded="false"
              aria-controls="flush-collapseThree5"
            >
              <b>SI EL REMATE SE ENCUENTRA EN ESTADO DE ¨ESPERANDO¨</b>
            </button>
          </h2>
          <div
            id="flush-collapseThree5"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingThree5"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              Usted puede solicitar toda la información que requiera sobre los
              bienes a subastar contactactando a nuestros ejecutivos al correo
              contacto@remateslasamericas.com
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingThree6">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree6"
              aria-expanded="false"
              aria-controls="flush-collapseThree6"
            >
              <b
                >CUÁLES SON LOS DATOS DE LACUENTA PARA LOS PAGOS DE LA
                GARANTIA</b
              >
            </button>
          </h2>
          <div
            id="flush-collapseThree6"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingThree6"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              Banco Santander <br />
              Cuenta corriente número 05917360 <br />
              Aaron Riquelme Roman <br />
              Rut: 12.857.335-6 <br />
              Correo: aaron@remateslasamericas.com <br />
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingThree7">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree7"
              aria-expanded="false"
              aria-controls="flush-collapseThree7"
            >
              <b>SI COMPRO DESDE EL EXTRANJERO COMO GARANTIZO MI COMPRA </b>
            </button>
          </h2>
          <div
            id="flush-collapseThree7"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingThree7"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              En caso de que usted gane un remate y el bien este en otro pais,
              le informamos en que ciudad esta su bien y coordinamos con su
              agente decarga el envío.
              <b>Los costos de transporte corren por parte del comprador.</b>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingThree8">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree8"
              aria-expanded="false"
              aria-controls="flush-collapseThree8"
            >
              <b>QUE PASA CUANDO ME ADJUDIQUE UN BIEN</b>
            </button>
          </h2>
          <div
            id="flush-collapseThree8"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingThree8"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              Una vez usted gana llene el formulario de instrucciones
              indicandonos que desea hacer con lo que se ha ganado. Si tinene
              algun inconveniente contactenos.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingThree9">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree9"
              aria-expanded="false"
              aria-controls="flush-collapseThree9"
            >
              <b>COMO ES EL PROCESO DE ESCRITURACIÓN DE MI PROPIEDAD</b>
            </button>
          </h2>
          <div
            id="flush-collapseThree9"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingThree9"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body">
              Pendiente. Nuestro equipo de abogados lo asesosaran en el proceso
              de traspaso de dominio del bien, el cuál esta determinado
              legalmente por el país donde este se encuentre ubicado.
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout-default>
</template>

<script>
import LayoutDefault from "../../components/organisms/LayoutDefault/LayoutDefault.vue";

export default {
  beforeCreate() {
    document.title = "FAQs";
  },
  components: {
    LayoutDefault,
  },
};
</script>
<style scoped>
.section-features {
  width: 85%;
}
.testimonial-message {
  max-width: 700px;
}
.contact-container {
  margin-bottom: 80px;
  margin-top: 80px;
}
:deep(.features img) {
  width: 50px !important;
  height: auto !important;
}
:deep(.features h5) {
  font-size: 30px;
}
:deep(.features p) {
  font-size: 15px !important;
  text-transform: uppercase !important;
}
.section-team {
  margin-bottom: 100px !important;
}
.about-testimonial {
  padding-left: 118px;
  padding-right: 118px;
  padding: 70px 83px 70px;
  background-color: #f9f9f9;
  width: 100%;
}

@media (max-width: 767.98px) {
  .section-features {
    width: 100%;
  }
  .section-features {
    padding: 0px !important;
    margin: 50px 0;
  }
  .section-features {
    display: flex !important;
    justify-content: center !important;
  }

  :deep(.feature) {
    display: flex;
    justify-content: unset !important;
  }

  .section-team {
    display: none !important;
  }
  .testimonial-message,
  .section-features {
    padding: 0 10px !important;
  }
  :deep(.testimonial-message) {
    margin: 50px 0px !important;
  }
  :deep(.testimonial) {
    padding: 0px 10px;
  }
  .about-features {
    padding: 20px !important;
  }
  .about-team {
    display: none !important;
  }
  .about-testimonial {
    padding: 50px 15px 0 0;
  }
  .feature-list {
    display: block !important;
    flex-direction: column !important;
  }
  .section-feature {
    padding: 35px 15px 65px;
  }
  .features h5 {
    font-size: 20px;
  }
  .features {
    flex-direction: column !important;
  }
  .section-features {
    padding-top: 20px;
  }
  .contact-features {
    padding: 0 10px !important;
  }
  .first-column,
  .second-column {
    padding: 0px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .about-team {
    display: none !important;
  }
  .first-column {
    padding: 5px;
  }
  .second-column {
    padding: 5px;
  }
  .about-testimonial {
    padding: 70px 10px !important;
  }
  :deep(.feature) {
    margin: 0px !important;
  }
}
.accordion-button:not(.collapsed) {
  color: #00002e !important;
}
</style>
