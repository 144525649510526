<template>
  <layout-default>
    <div class="container account-page">
      <div class="container my-account-page d-flex flex-column">
        <div class="my-account-page-location">
          <div class="my-account-breadcrumb border-bottom">
            <Breadcrumb />
          </div>
        </div>
        <div class="my-account d-flex justify-content-center flex-wrap">
          <div class="col-md-8 my-account-login">
            <section class="container mb-5 terms">
              <card class="mx-auto">
                <template v-slot:title>
                  <b>Perfil de usuario</b>
                </template>
                <template v-slot:body>
                  <user-show :user="user" />
                </template>
                <template v-slot:footer>
                  <ButtonFilled
                    class="blog-btn fw-bolder"
                    :hasRoundedCorners="true"
                    :text="'Editar'"
                    size="small"
                    location="/edit-profile"
                  />
                </template>
              </card>
            </section>
          </div>
        </div>
      </div>
    </div>
  </layout-default>
</template>

<script>
import Card from "@/components/molecules/Card/Card.vue";
import UserShow from "@/components/custom/user/show.vue";
import ButtonFilled from "@/components/atoms/Button/ButtonFilled.vue";
import Breadcrumb from "@/components/atoms/Breadcrumb/Breadcrumb.vue";
import LayoutDefault from "@/components/organisms/LayoutDefault/LayoutDefault.vue";
export default {
  name: "Profile",
  middleware: ["auth"],
  components: { LayoutDefault, Breadcrumb, Card, ButtonFilled, UserShow },
  beforeCreate() {
    document.title = "Perfil";
  },
  created() {
    const { id, attributes, relationships } = this.$store.getters["auth/user"];
    this.user = { id, attributes, relationships };
    console.log(this.user);
  },
  mounted() {
    this.$store.commit("setLocation", [
      { title: "Perfil", location: "/profile" },
    ]);
  },
  data() {
    return {
      // Data User
      user: { id: 0, attributes: {}, relationships: {} },
    };
  },
};
</script>

<style scoped="scoped">
.account-page {
  padding-bottom: 30px;
}
.my-account-login,
.my-account-register {
  padding: 50px 0;
}
.my-account-login {
  padding-right: 50px;
}
.my-account-register {
  padding-left: 50px;
}
.my-account-breadcrumb {
  padding-top: 20px;
  padding-bottom: 20px;
}
.my-account-title {
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .my-account {
    display: block !important;
  }
  .my-account-breadcrumb {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .account-page {
    padding: 0px !important;
    display: flex !important;
    flex-direction: column !important;
  }
  .login-input {
    width: 270px !important;
  }
  .my-account-breadcrumb {
    width: 350px;
  }
  .col-md-6 {
    padding: 35px 0px 0px 0px;
  }
  .my-account-register {
    padding-bottom: 70px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .account-page {
    padding: 50px 10px !important;
  }
  :deep(.my-account > .my-account-register .my-account-login) {
    padding: 0 !important;
  }
  .my-account {
    justify-content: space-between !important;
  }

  .my-account-page,
  .my-account {
    padding: 0px !important;
  }
}
</style>
