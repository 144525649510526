<template>
  <div>
    <swiper
      :spaceBetween="10"
      :navigation="true"
      :thumbs="{ swiper: thumbsSwiper }"
      class="mySwiper2"
    >
      <swiper-slide v-for="photo in photos" :key="photo.id">
        <v-zoomer>
          <img
            :src="$getFileSource(photo.url)"
            style="object-fit: contain; width: 100%; height: 100%"
          />
        </v-zoomer>
      </swiper-slide>
    </swiper>
    <swiper
      @swiper="setThumbsSwiper"
      :spaceBetween="10"
      :slidesPerView="7"
      :freeMode="true"
      :watchSlidesProgress="true"
    >
      <swiper-slide v-for="photo in photos" :key="photo.id" class="mySwiper">
        <div
          class="d-flex align-items-center justify-content-center mt-2"
          v-if="photos.length > 1"
        >
          <img
            class="mx-1"
            :src="$getFileSource(photo.url)"
            height="81"
            width="81"
          />
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/swiper-bundle.css";

// import Swiper core and required modules
import SwiperCore, { Navigation, Thumbs } from "swiper";

// install Swiper modules
SwiperCore.use([Navigation, Thumbs]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    photos: {
      type: Array,
    },
  },
  data() {
    return {
      thumbsSwiper: null,
    };
  },
  methods: {
    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper;
    },
  },
};
</script>

<style>
.mySwiper {
  width: 81px !important;
  cursor: pointer;
}
</style>
