<template>
  <playground>
    <template v-slot:admin-actions="props">
      <br />
      <p class="text-center fw-bold">Acciones</p>
      <div class="row justify-content-center my-2">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="text-center">
            <button-filled
              :disabled="!lotInAuction || props.winner == ''"
              type="button"
              class="fw-bold text-white"
              @click="awardLot(true)"
              :hasRoundedCorners="true"
            >
              <font-awesome-icon
                class="text-white text-center"
                :icon="['fas', 'trophy']"
              />
              <small class="mx-2"> Adjudicar Lote </small>
            </button-filled>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="text-center">
            <button-filled
              :disabled="
                !lotInAuction ||
                statusAuction === 'Esperando' ||
                props.winner != ''
              "
              type="button"
              class="fw-bold text-white mx-auto"
              @click="awardLot(false)"
              :hasRoundedCorners="true"
            >
              <font-awesome-icon
                class="text-white text-center"
                :icon="['fas', 'trophy']"
              />

              <small class="mx-2"> Finalizar sin postor </small>
            </button-filled>
          </div>
        </div>
      </div>
      <div class="row justify-content-center my-2">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="text-center">
            <button-filled
              :disabled="
                (lotInAuction && !lot_awarded) ||
                statusAuction === 'Esperando' ||
                activeLots < 1 ||
                pauseAuction
              "
              type="button"
              class="fw-bold text-white"
              @click="nextLot"
              :hasRoundedCorners="true"
            >
              <font-awesome-icon
                class="text-white text-center"
                :icon="['fas', 'arrow-right']"
              />

              <small class="mx-2"> Siguiente Lote </small>
            </button-filled>
          </div>
        </div>
        <div
          class="col-lg-6 col-md-6 col-sm-12"
          v-if="!pauseAuction && activeAuction && !props.lotInAuction"
        >
          <div class="text-center">
            <button-filled
              type="button"
              class="fw-bold text-white"
              @click="handlerUpdateStatusAuction(4)"
              :hasRoundedCorners="true"
            >
              <font-awesome-icon
                class="text-white text-center"
                :icon="['fas', 'pause']"
              />

              <small class="mx-2"> Pausar Remate </small>
            </button-filled>
          </div>
        </div>
        <div
          class="col-lg-6 col-md-6 col-sm-12"
          v-else-if="pauseAuction && !activeAuction && !props.lotInAuction"
        >
          <div class="text-center">
            <button-filled
              type="button"
              class="fw-bold text-white"
              @click="handlerUpdateStatusAuction(1, true)"
              :hasRoundedCorners="true"
            >
              <font-awesome-icon
                class="text-white text-center"
                :icon="['fas', 'redo']"
              />

              <small class="mx-2"> Reanudar Remate </small>
            </button-filled>
          </div>
        </div>
      </div>
      <div class="row justify-content-center my-2">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="text-center">
            <button-filled
              :disabled="activeAuction || pauseAuction"
              type="button"
              class="fw-bold text-white"
              @click="handlerUpdateStatusAuction(1)"
              :hasRoundedCorners="true"
            >
              <font-awesome-icon
                class="text-white text-center"
                :icon="['fas', 'play']"
              />

              <small class="mx-2"> Iniciar Remate </small>
            </button-filled>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="text-center">
            <button-filled
              :disabled="!activeAuction"
              type="button"
              class="fw-bold text-white"
              @click="handlerUpdateStatusAuction(3)"
              :hasRoundedCorners="true"
            >
              <font-awesome-icon
                class="text-white text-center"
                :icon="['fas', 'times-circle']"
              />

              <small class="mx-2"> Finalizar Remate </small>
            </button-filled>
          </div>
        </div>
        <!-- <p class="text-center mt-4">
          Total de usuarios conectados: <b> {{ props.usersConected }}</b>
        </p> -->
      </div>
    </template>
  </playground>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Playground from "@/components/custom/auction/Playground.vue";
import ButtonFilled from "@/components/atoms/Button/ButtonFilled.vue";
export default {
  name: "PlaygroundAdmin",
  middleware: ["auth", "admin"],
  data() {
    return {
      remate_close_dialog: false,
      lot_awarded: false,
      slug: this.$route.params.slug,
    };
  },
  components: {
    Playground,
    ButtonFilled,
  },
  created() {},
  methods: {
    ...mapActions({
      updateStatusAuction: "auction/updateStatusAuction",
      pushAwardLot: "lot/awardLot",
      getNextLot: "lot/nextLot",
    }),
    handlerUpdateStatusAuction(status, resumeAuction = false) {
      let win = {};

      if (resumeAuction) {
        win = {
          text: "¿Desea reanudar el remate?",
          confirmText: "Reanudar Remate",
        };
      } else if (status === 1) {
        win = {
          text: "¿Seguro que desea iniciar el remate?",
          confirmText: "Iniciar",
        };
      } else if (status === 4) {
        win = {
          text: "¿Desea pausar el remate?",
          confirmText: "Pausar Remate",
        };
      } else {
        win = {
          text: "¿Desea finalizar el remate?",
          confirmText: "Cerrar Remate",
        };
      }
      this.sweet(win).then(async (result) => {
        if (result.value) {
          let loader = this.$loading.show();
          await this.updateStatusAuction({
            status,
            auction_slug: this.slug,
            resume_auction: resumeAuction,
          });
          loader.hide();
        }
      });
    },
    awardLot(withUser) {
      const text = withUser ? "adjudicar" : "finalizar sin postor";
      const win = {
        text: `¿Seguro que desea ${text}: ${this.lotInAuction.attributes.name}?`,
        confirmText: withUser ? "Adjudicar" : "Finalizar sin postor",
      };
      this.sweet(win).then(async (result) => {
        if (result.value) {
          let loader = this.$loading.show();
          this.lot_awarded = true;
          const message = await this.pushAwardLot();
          loader.hide();
          this.$swal({
            icon: "success",
            title: "Excelente",
            text: message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
    },
    async nextLot() {
      const win = {
        text: "¿Seguro que desea pasar al siguiente lote?",
        confirmText: "Siguiente lote",
      };
      this.sweet(win).then(async (result) => {
        if (result.value) {
          this.lot_awarded = false;
          await this.getNextLot();
        }
      });
    },
    sweet(win) {
      return this.$swal({
        title: "¡Atención!",
        text: win.text,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: win.confirmText,
        cancelButtonColor: "#78909C",
      });
    },
  },
  computed: {
    ...mapGetters({
      auction: "auction/auction",
      lotInAuction: "lot/lotInAuction",
      statusAuction: "auction/status",
    }),
    activeAuction() {
      if (this.auction) {
        return this.auction.attributes.status == "Activo";
      } else {
        return false;
      }
    },
    pauseAuction() {
      if (this.auction) {
        return this.auction.attributes.status == "Pausado";
      } else {
        return false;
      }
    },
    activeLots() {
      if (this.auction) {
        let lots_actives = this.auction.relationships.lotes.data.filter(
          (lote) => {
            return lote.attributes.status === 1;
          }
        );
        return lots_actives.length;
      } else {
        return false;
      }
    },
  },
};
</script>

<style></style>
