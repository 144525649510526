<template>
  <div class="row" v-if="auctions && auctions.data.length">
    <div
      v-for="auction in auctions.data"
      :key="auction.id"
      class="col-6 col-lg-4 col-md-6 col-sm-12 mb-4"
    >
      <feed-auction-card :auction="auction" />
    </div>
  </div>
  <h3 v-else class="text-center">No hay remates disponibles</h3>
</template>

<script>
import FeedAuctionCard from "@/components/molecules/Auction/FeedAuctionCard.vue";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  created() {
    this.loadAuctions();
  },
  components: { FeedAuctionCard },
  data() {
    return {
      meta: {
        default: false,
        default_count: 0,
      },
    };
  },
  methods: {
    ...mapActions({
      fetchAuctions: "auction/fetchAuctions",
    }),
    async loadAuctions() {
      await this.fetchAuctions({ status: [1, 2, 4] });
      // const data = await this.fetchauctions();
      // this.meta = data.meta;
    },
  },
  computed: {
    ...mapGetters({
      auctions: "auction/auctions",
    }),
  },
};
</script>
<style scoped="scoped">
:deep(.prod-card-counter) {
  margin-top: -15px;
  border-radius: 0px !important;
}

@media (max-width: 767.98px) {
  :deep(.prod-card-counter) {
    margin-top: 0px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  :deep(.prod-card-counter) {
    margin-top: 0px !important;
  }
}
</style>
