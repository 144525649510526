<template>
  <div>
    <layout-default>
      <div class="container mb-4">
        <breadcrumb class="mt-5">
          <link-wrapper
            location="/admin/auctions"
            class="breadcrumb-text text-decoration-none"
          >
            <span class="breadcrumb-home">Remates</span>
            <span class="breadcrumb-text mx-2"> / </span>
          </link-wrapper>
          <link-wrapper
            :location="`/admin/auctions/show/${slug}`"
            class="breadcrumb-text text-decoration-none"
          >
            <span class="breadcrumb-home">Remate</span>
            <span class="breadcrumb-text mx-2"> / </span>
          </link-wrapper>
          <link-wrapper
            class="breadcrumb-text text-decoration-none"
            :location="`/admin/auctions/${slug}/lots/order-lots`"
          >
            <span class="breadcrumb-home">Ordenar Lotes</span>
          </link-wrapper>
        </breadcrumb>
      </div>
      <div class="mt-5">
        <section class="container products">
          <div class="w-75 mx-auto">
            <section>
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <h3 class="mb-4">Ordenar Lotes</h3>
                <div class="d-block text-center">
                  <h3 class="text-center d-inline-block">Importante:</h3>
                  <br />
                  <p class="text-center d-inline-block ml-1">
                    Arrastre el lote para ordenarlo.
                  </p>
                </div>
                <button-filled
                  class="blog-btn fw-bolder mb-2"
                  :hasRoundedCorners="true"
                  :text="'Enviar Nuevo Orden'"
                  size="small"
                  @click="sendLots"
                />
              </div>
              <table class="table table-hover table-responsive card-table">
                <thead class="bg-theme text-white'">
                  <tr class="">
                    <th scope="col" class="px-3 text-white text-center">
                      Numero
                    </th>
                    <th scope="col" class="px-3 text-white text-center">
                      Nombre
                    </th>
                  </tr>
                </thead>
                <draggable v-model="lots" item-key="id" tag="tbody">
                  <template #item="{ element }">
                    <tr :key="element.id">
                      <td scope="row" class="text-center">
                        {{ element.attributes.number_text }}
                      </td>
                      <td class="text-center">{{ element.attributes.name }}</td>
                    </tr>
                  </template>
                </draggable>
              </table>
            </section>
          </div>
        </section>
      </div>
    </layout-default>
  </div>
</template>

<script>
import axios from "axios";
import draggable from "vuedraggable";
import Breadcrumb from "@/components/atoms/Breadcrumb/Breadcrumb.vue";
import LayoutDefault from "@/components/organisms/LayoutDefault/LayoutDefault.vue";
import LinkWrapper from "@/components/atoms/LinkWrapper/LinkWrapper.vue";
import ButtonFilled from "@/components/atoms/Button/ButtonFilled.vue";

export default {
  name: "AdminProductsNew",
  middleware: ["auth", "admin"],
  data() {
    return {
      slug: this.$route.params.slug,
      lots: [],
    };
  },
  components: {
    LayoutDefault,
    Breadcrumb,
    LinkWrapper,
    ButtonFilled,
    draggable,
  },
  mounted() {
    this.$store.commit("setLocation", []);
    this.loadLots();
  },
  methods: {
    async loadLots() {
      try {
        const { data } = (await axios.get(`/api/auctions/${this.slug}/lots`))
          .data;
        this.lots = data;
      } catch (e) {
        return e;
      }
    },
    async sendLots() {
      let loader = this.$loading.show();
      try {
        let order = this.lots.map((lot) => lot.id);
        const { data } = await axios.post("/api/auctions/reorder/lots", {
          order: order,
        });
        this.$swal({
          icon: "success",
          title: "Excelente",
          text: data.message,
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          this.$router.push({
            name: "admin-auction-show",
            params: { slug: this.slug },
          });
        });
      } catch (e) {
        return e;
      } finally {
        loader.hide();
      }
    },
  },
};
</script>
