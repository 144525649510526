<template>
  <div
    :class="`
      btn btn-sm
      text-light
      rounded-pill
      ps-3
      pe-3
      fw-bold
      text-uppercase
      ${color}
    `"
  >
    <slot>
      {{ text }}
    </slot>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "primary-chip",
    },
  },
};
</script>

<style>
.primary-chip {
  background-color: #031d4a;
}

.secondary-chip {
  background-color: #765d04;
}
</style>
