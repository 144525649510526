<template>
  <div class="d-flex align-items-center justify-content-start">
    <div class="rounded-circle bg-theme icon-label">
      <font-awesome-icon class="text-white text-center" :icon="['fas', icon]" />
    </div>
    <label for="amount" class="mt-2">
      <span class="">
        {{ label }}
      </span>
      <p class="fw-bold" v-html="text"></p>
    </label>
  </div>
</template>

<script>
export default {
  props: ["label", "icon", "text"],
};
</script>

<style>
.text-gray {
  color: gray;
}
.icon-label {
  margin-right: 1rem;
  min-width: 2.5rem !important;
  padding: 0.4rem 0.5rem;
  text-align: center;
}
</style>
