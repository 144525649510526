<template>
  <layout-default>
    <div class="container mb-4">
      <breadcrumb class="mt-5 pb-3 border-bottom">
        <link-wrapper location="/" class="breadcrumb-text text-decoration-none">
          <span class="breadcrumb-home">Inicio</span>
          <span class="breadcrumb-text mx-2"> / </span>
        </link-wrapper>
        <link-wrapper
          location="/admin/auctions"
          class="breadcrumb-text text-decoration-none"
        >
          <span class="breadcrumb-home">Remates</span>
          <span class="breadcrumb-text mx-2"> / </span>
        </link-wrapper>
      </breadcrumb>
    </div>
    <div class="container">
      <div class="mt-5 w-75 mx-auto">
        <card-auction
          :destroy-function="destroyItem"
          :auction="auction"
        ></card-auction>

        <card-exhibition
          class="mt-5"
          v-if="auction && exhibition"
          :exhibition="exhibition"
        ></card-exhibition>

        <section class="container mt-5 products">
          <section>
            <div class="d-flex justify-content-between mb-2">
              <h3 class="mb-4">Lotes</h3>
              <div class="d-flex justify-content-center align-items-center">
                <button-outline
                  class="blog-btn fw-bold mx-3 mb-2"
                  :text="'Ordenar Lotes'"
                  @onClick="
                    $router.push({
                      name: 'admin-auctions-lots-order',
                      params: { slug: slug },
                    })
                  "
                  :hasRoundedCorners="true"
                />
                <ButtonFilled
                  @click="
                    $router.push({
                      name: 'admin-auctions-lots-new',
                      params: { slug: slug },
                    })
                  "
                  class="fw-bolder mb-2"
                  :hasRoundedCorners="true"
                  :text="'Agregar Lote'"
                />
              </div>
            </div>
            <custom-table :headers="headersLots" :items="lots">
              <template v-slot:accions="{ item }">
                <div class="d-flex justify-content-center" :key="item.id">
                  <tooltip-icon
                    text="Ver Lote"
                    @click="
                      $router.push({
                        name: 'admin-auctions-lots-show',
                        params: { slug: slug, id: item.id },
                      })
                    "
                    icon="eye"
                    class-icon="text-primary"
                  ></tooltip-icon>
                  <tooltip-icon
                    text="Editar Lote"
                    @click="
                      $router.push({
                        name: 'admin-auctions-lots-edit',
                        params: { slug: slug, id: item.id },
                      })
                    "
                    icon="edit"
                    class-icon="text-success"
                  ></tooltip-icon>
                  <tooltip-icon
                    text="Eliminar Lote"
                    icon="trash"
                    @click="destroyItem({ id: item.id, entity: 'lots' })"
                    class-icon="text-danger"
                  ></tooltip-icon>
                </div>
              </template>
            </custom-table>
          </section>

          <section class="mt-5">
            <div class="d-flex justify-content-between mb-2">
              <h3 class="mb-4">Participantes Postulados</h3>
              <ButtonFilled
                @click="showModalAddUsers"
                class="blog-btn fw-bolder mb-2"
                :hasRoundedCorners="true"
                :text="'Agregar Participantes'"
                size="small"
              />
            </div>
            <custom-table :headers="headersParticipants" :items="postulates">
              <template v-slot:accions="{ item }">
                <div class="d-flex justify-content-center" :key="item.id">
                  <tooltip-icon
                    @click="changeStatus(item.id, 1)"
                    text="Aprobar participante"
                    icon="check"
                    class-icon="text-success"
                  ></tooltip-icon>
                </div>
              </template>
            </custom-table>
          </section>

          <section class="mt-5">
            <div class="d-flex justify-content-between mb-2">
              <h3 class="mb-4">Participantes Aprobados</h3>
            </div>
            <custom-table :headers="headersParticipants" :items="approved">
              <template v-slot:accions="{ item }">
                <div class="d-flex justify-content-center" :key="item.id">
                  <tooltip-icon
                    @click="changeStatus(item.id, 0)"
                    text="Desaprobar participante"
                    icon="window-close"
                    class-icon="text-danger"
                  ></tooltip-icon>
                </div>
              </template>
            </custom-table>
          </section>

          <modal-general v-if="modalIsGeneral">
            <form-add-users-auction
              :fetch-function="fetchAuction"
            ></form-add-users-auction>
          </modal-general>
        </section>
      </div>
    </div>
  </layout-default>
</template>

<script>
import axios from "axios";
import LayoutDefault from "@/components/organisms/LayoutDefault/LayoutDefault.vue";
import ButtonFilled from "@/components/atoms/Button/ButtonFilled.vue";
import CustomTable from "@/components/molecules/Table/CustomTable.vue";
import LinkWrapper from "@/components/atoms/LinkWrapper/LinkWrapper.vue";
import Breadcrumb from "@/components/atoms/Breadcrumb/Breadcrumb.vue";
import CardAuction from "@/components/custom/auction/CardAuction.vue";
import TooltipIcon from "@/components/atoms/TooltipIcon.vue";
import { mapGetters } from "vuex";
import ModalGeneral from "@/components/molecules/Modal/ModalGeneral.vue";
import FormAddUsersAuction from "@/components/custom/auction/FormAddUsersAuction.vue";
import CardExhibition from "@/components/custom/auction/CardExhibition.vue";
import ButtonOutline from "@/components/atoms/Button/ButtonOutline.vue";

export default {
  name: "AdminAuctions",
  middleware: ["auth", "admin"],
  beforeCreate() {
    document.title = "Ver Remates";
    this.$store.commit("setLocation", [
      {
        title: "Ver Remate",
        location: `/admin/auctions/show/${this.$route.params.slug}`,
      },
    ]);
  },
  components: {
    LayoutDefault,
    LinkWrapper,
    Breadcrumb,
    CardAuction,
    CustomTable,
    TooltipIcon,
    ButtonFilled,
    ModalGeneral,
    FormAddUsersAuction,
    CardExhibition,
    ButtonOutline,
  },
  data() {
    return {
      headersLots: [
        { text: "Nombre", value: "attributes.name" },
        { text: "IVA", value: "attributes.iva" },
        { text: "Minimo", value: "attributes.minimum" },
      ],
      headersParticipants: [
        { text: "Rut", value: "attributes.rut" },
        { text: "Nombre", value: "attributes.name" },
        {
          text: "Correo Electrónico",
          value: "attributes.email",
        },
        {
          text: "Acciones",
          value: "accions",
        },
      ],
      // Data Remate
      auction: {
        id: null,
        attributes: { date: null },
        relationships: {
          comuna: { attributes: {} },
          lotes: [],
          exhibition: { id: 0 },
        },
      },
      // Awarded Columns
      pushedColumns: 0,

      // Data Exhibicion
      exhibition: {
        attributes: { start_date: new Date(), end_date: new Date() },
      },

      // Data Params
      slug: this.$route.params.slug,

      // Participants
      postulates: [],
      approved: [],
    };
  },
  async created() {
    await this.fetchAuction();
    await this.pushedColumnsIntoTable();
  },
  computed: {
    ...mapGetters({
      modalIsGeneral: "modalIsGeneral",
    }),
    lots() {
      return this.auction?.relationships?.lotes?.data || [];
    },
  },
  methods: {
    showModalAddUsers() {
      this.$store.commit("toggleModal", "general");
    },
    async fetchAuction() {
      let loader = this.$loading.show();
      try {
        const { data } = (await axios.get(`/api/auctions/${this.slug}`)).data;
        this.auction = data;
        this.exhibition = data.relationships.exhibition;
        await this.fetchParticipants();
      } catch (e) {
        return e;
      } finally {
        loader.hide();
      }
    },
    async fetchParticipants() {
      try {
        const { data } = await axios.get(
          `/api/auctions/${this.slug}/participants`
        );
        this.postulates = data.participants.filter((post) => {
          return post.status == 0;
        });

        this.approved = data.participants.filter((post) => {
          return post.status == 1;
        });
      } catch ({ response }) {
        return response;
      }
    },
    async changeStatus(user, st) {
      let loader = this.$loading.show();
      try {
        const { data } = await axios.patch(
          `/api/auctions/${this.slug}/participants/${user}`,
          {
            status: st,
          }
        );
        this.$swal({
          icon: "success",
          title: "Excelente",
          text: "Postulación modificada exitosamente",
          showConfirmButton: true,
          timer: 3000,
        });
        this.fetchParticipants();
        return data;
      } catch (e) {
        return e;
      } finally {
        loader.hide();
      }
    },
    destroyItem(item) {
      const { id, entity } = item;
      this.$swal({
        title: "¿Está seguro?",
        text: "¡No podrá revertir esta opción!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Eliminar",
      }).then(async (result) => {
        if (result.value) {
          let loader = this.$loading.show();
          try {
            const { data } = await axios.delete(`/api/${entity}/${id}`);
            this.$swal({
              icon: "success",
              title: "Excelente",
              text: data.message,
              showConfirmButton: false,
              timer: 3000,
            }).then(() => {
              if (entity === "auctions") {
                this.$router.push({ name: "admin-auctions" });
              } else this.fetchAuction();
            });
          } catch (e) {
            return e;
          } finally {
            loader.hide();
          }
        }
      });
    },
    pushedColumnsIntoTable() {
      if (!this.pushedColumns) {
        if (this.auction.attributes.status_number == 3) {
          this.headersLots.push({
            text: "Adjudicado a",
            value: "relationships.awarded",
          });
          this.headersLots.push({
            text: "Monto de Adjudicación",
            value: "relationships.awarded_amount",
          });
          this.pushedColumns = 1;
        }
        if (this.auction.attributes.status_number < 3 || this.pushedColumns) {
          this.headersLots.push({
            text: "Acciones",
            value: "accions",
          });
          this.pushedColumns = 1;
        }
      }
    },
  },
  watch: {
    auction: {
      handler() {
        this.pushedColumnsIntoTable();
      },
      deep: true,
    },
  },
};
</script>
<style scoped></style>
