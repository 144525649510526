<template>
  <playground></playground>
</template>

<script>
import Playground from "@/components/custom/auction/Playground.vue";
export default {
  data() {
    return {};
  },
  components: {
    Playground,
  },
  created() {},
  methods: {},
  computed: {},
};
</script>
