<template>
  <Heading
    :title="'Grupos de Venta Directa'"
    class="mb-5"
    v-if="groups.length"
  />
  <div class="row" v-if="groups.length">
    <div v-for="group in groups" :key="group" class="col-6 col-md-4 mb-4">
      <group-card :group="group" />
    </div>
  </div>
  <h3 v-else class="text-center">No hay grupos disponibles</h3>
</template>

<script>
import Heading from "@/components/atoms/SectionHeading/Heading.vue";
import GroupCard from "@/components/molecules/Product/Group/GroupCard.vue";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  mounted() {
    this.loadGroups();
  },
  components: { Heading, GroupCard },
  data() {
    return {
      meta: {
        default: false,
        default_count: 0,
      },
    };
  },
  methods: {
    ...mapActions({
      fetchGroups: "direct_sale/fetchGroups",
    }),
    async loadGroups() {
      const data = await this.fetchGroups();
      this.meta = data.meta;
    },
  },
  computed: {
    ...mapGetters({
      groups: "direct_sale/groups",
    }),
  },
};
</script>
<style scoped="scoped">
:deep(.prod-card-counter) {
  margin-top: -15px;
  border-radius: 0px !important;
}

@media (max-width: 767.98px) {
  :deep(.prod-card-counter) {
    margin-top: 0px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  :deep(.prod-card-counter) {
    margin-top: 0px !important;
  }
}
</style>
