<template>
  <form @submit.prevent="sendData" class="login-form d-flex flex-column p-md-4">
    <div class="row mb-2">
      <div class="col-md-6 col-sm-12">
        <BasicLabel title="Nombre" :required="true" class="fw-bold" />
        <BasicInputText
          :hasRoundedCorners="true"
          class="login-input mb-2"
          :placeholder="'Nombre'"
          v-model="v$.product.name.$model"
          required
        />
        <span class="text-danger" v-if="v$.product.name.$error"
          >El Nombre es requerido.</span
        >
      </div>
      <div class="col-md-3 col-sm-6">
        <BasicLabel title="Fecha de inicio" :required="true" class="fw-bold" />
        <BasicInputText
          :hasRoundedCorners="true"
          class="login-input mb-2"
          v-model="v$.product.start_date.$model"
          required
          type="date"
          :min="minStart"
          @input="validateDateTimes('start_date', 'startDate')"
        />
        <span class="text-danger" v-if="v$.product.start_date.$error"
          >La Fecha de inicio es requerida.</span
        >
      </div>
      <div class="col-md-3 col-sm-6">
        <BasicLabel title="Fecha final" class="fw-bold" />
        <BasicInputText
          :hasRoundedCorners="true"
          class="login-input mb-2"
          v-model="v$.product.end_date.$model"
          type="date"
          :min="minEnd"
          @input="validateDateTimes('end_date', 'endDate')"
        />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-md-6 col-sm-12" v-if="product.currency_id">
        <BasicLabel title="Moneda" :required="true" class="fw-bold" />
        <BasicSelectBox
          name="currency"
          class="form-control mt-3"
          defaultValueText="Seleccione una moneda"
          :options="optionsCurrencys"
          v-model="v$.product.currency_id.$model"
        />
      </div>
      <div class="col-md-6 col-sm-12">
        <BasicLabel title="Precio" :required="true" class="fw-bold" />
        <BasicInputText
          :hasRoundedCorners="true"
          class="login-input mb-3"
          :placeholder="'Precio'"
          v-model="v$.product.amount.$model"
          required
          :type="'number'"
        />
        <span class="text-danger" v-if="v$.product.amount.$error"
          >El Precio es requerido.</span
        >
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4">
        <BasicLabel
          title="Comisión de Martillero (%)"
          :required="true"
          class="fw-bold"
        />
        <BasicInputText
          :hasRoundedCorners="true"
          class="login-input mb-3"
          :placeholder="'Comisión de Martillero (%)'"
          v-model="v$.product.commission_auctioneer.$model"
          required
          :type="'number'"
        />
        <span class="text-danger" v-if="v$.product.commission_auctioneer.$error"
          >La Comisión del Martillero es requerida.</span
        >
      </div>
      <div class="col-12 col-md-2">
        <div class="form-check form-switch mt-5 d-flex justify-content-center">
          <input
            class="form-check-input mb-4"
            type="checkbox"
            id="flexSwitchCheckDefault"
            name="flexSwitchCheckDefault"
            role="switch"
            v-model="v$.product.iva.$model"
          />
          <BasicLabel
            title="IVA"
            class="form-check-label fw-bold ml-3"
            for="flexSwitchCheckDefault"
          />
        </div>
      </div>
      <div class="col-12 col-md-6">
        <BasicLabel title="Grupo" class="fw-bold" />
        <BasicSelectBox
          name="group"
          class="form-control mt-3"
          defaultValueText="Seleccione un Grupo"
          :options="groups"
          v-model="v$.product.group_id.$model"
        />
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-12 col-md-8">
        <BasicLabel title="Descripción" class="fw-bold" :required="true" />
        <ckeditor
          v-model="v$.product.description.$model"
          :editor="editor"
          :config="editorConfig"
        ></ckeditor>
        <span class="text-danger" v-if="v$.product.description.$error"
          >La Descripción es requerida.</span
        >
      </div>
      <div class="col-12 col-md-4">
        <BasicLabel title="Estado" class="fw-bold" />
        <BasicSelectBox
          name="status"
          class="form-control mt-3"
          defaultValueText="Seleccione un Estado"
          :options="status"
          v-model="v$.product.status.$model"
        />
      </div>
      <div class="d-flex justify-content-end mt-4">
        <ButtonFilled
          type="submit"
          class="Minicart_button text-white"
          :text="id ? 'Editar' : 'Crear'"
          :hasRoundedCorners="true"
        />
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapGetters } from "vuex";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import useVuelidate from "@vuelidate/core";
import { currencys } from "@/constants/currency";
import { required } from "@vuelidate/validators";
import BasicLabel from "@/components/atoms/FormInput/BasicLabel.vue";
import BasicSelectBox from "../../atoms/FormInput/BasicSelectBox.vue";
import ButtonFilled from "@/components/atoms/Button/ButtonFilled.vue";
import BasicInputText from "@/components/atoms/FormInput/BasicInputText.vue";
import "@ckeditor/ckeditor5-build-classic/build/translations/es";
export default {
  name: "ProductFormComponent",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    BasicLabel,
    BasicInputText,
    BasicSelectBox,
    ButtonFilled,
  },
  data() {
    return {
      id: this.$route.params.id,
      // Data Info
      product: {
        name: null,
        amount: null,
        start_date: null,
        currency_id: currencys.PESO_CURRENCY,
        end_date: null,
        description: null,
        group_id: null,
        commission_auctioneer: null,
        status: null,
        iva: null,
      },

      // Groups
      groups: [],

      //CKEditor
      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
        language: "es",
      },
      // Status
      status: [
        { name: "Inactivo", id: 0 },
        { name: "Disponible", id: 1 },
        { name: "Vendido", id: 2 },
      ],

      // Modals
      modals: {
        date_start_picker: false,
        date_end_picker: false,
      },
    };
  },
  async created() {
    if (this.id) await this.fetchProduct();
    else this.product.status = 1;
    this.$store.dispatch("currency/fetchCurrencys");
    this.fetchGroups();
  },
  computed: {
    ...mapGetters({
      currencys: "currency/currencys",
    }),
    optionsCurrencys() {
      return this.currencys.map(({ description: name, id }) => ({
        name,
        id,
      }));
    },
    minStart() {
      return moment(new Date()).format("YYYY-MM-DD");
    },
    minEnd() {
      return moment(this.product.start_date)
        .add("2", "day")
        .format("YYYY-MM-DD");
    },
  },
  methods: {
    async fetchProduct() {
      try {
        const { data } = (await axios.get(`/api/products/${this.id}`)).data;
        const { attributes } = data;
        const { status_type } = attributes;
        this.product = attributes;
        this.product.status = status_type;
        // eslint-disable-next-line
      } catch (e) {
        console.log(e);
      }
    },
    async fetchGroups() {
      try {
        const { data } = (await axios.get("/api/groups-admin")).data;
        this.groups = data.map(({ attributes: group, id }) => ({
          name: group.name,
          attributes: group,
          id,
        }));
        // eslint-disable-next-line
      } catch (e) {}
    },
    async sendData() {
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        let loader = this.$loading.show();
        try {
          let url = "/api/products";
          let met = "post";
          if (this.id) {
            url = url + `/${this.id}`;
            met = "patch";
          }

          if (this.product.group_id === undefined) this.product.group_id = null;

          if (!this.product.status) this.product.status = 1;

          this.product.iva = !this.product.iva ? 0 : 1;

          const { data } = await axios[met](url, this.product);

          let id = this.id ? this.id : data.id;

          this.$swal({
            icon: "success",
            title: "Excelente",
            text: data.message,
            showConfirmButton: false,
            timer: 3000,
          }).then(async () => {
            this.$router.push({
              name: "admin-product-show",
              params: { id: id },
            });
          });
          loader.hide();
        } catch (e) {
          const { status } = e.response || {};
          console.log(e.response);
          if (status === 422) {
            const { errors } = e.response.data;
            const err = errors[Object.keys(errors)[0]][0];
            this.$swal({
              icon: "error",
              title: "Disculpe",
              text: err,
              showConfirmButton: false,
              timer: 4000,
            });
          }
          loader.hide();
        }
      }
    },
    validateDateTimes(type) {
      const d1 = this.product[type];
      if (type === "start_date") {
        const d2 = this.product.end_date;
        if (d1 >= d2) {
          this.product.end_date = null;
        }
      }
      if (type === "end_date") {
        const d2 = this.product.start_date;
        if (d1 <= d2) {
          this.product.end_date = null;
        }
      }
      // this.$refs[ref].save(this.group[type]);
    },
  },
  watch: {
    "product.group_id": function (newValue) {
      if (this.groups && this.groups.length) {
        const groupSelected = this.groups.find((item) => item.id == newValue);
        this.product.commission_auctioneer = groupSelected.attributes
          .commission_auctioneer
          ? groupSelected.attributes.commission_auctioneer
          : 0;
        this.product.start_date = groupSelected.attributes.start_date;
        this.product.end_date = groupSelected.attributes.end_date;
      }
    },
  },
  validations() {
    return {
      product: {
        name: { required },
        amount: { required },
        start_date: { required },
        currency_id: {},
        end_date: {},
        description: { required },
        group_id: {},
        commission_auctioneer: { required },
        status: {},
        iva: {},
      },
    };
  },
};
</script>
<style scoped="scoped">
.customer-checkbox {
  margin-top: 10px;
}
.login-input {
  height: 40px !important;
  padding: 10px 20px !important;
  box-shadow: none !important;
  margin-top: 10px;
}
.form-check {
  padding-left: 27px !important;
}
.login-field,
.login-field-last {
  margin-top: 30px !important;
}

.Minicart_button {
  width: 150px !important;
  height: 40px !important;
}
@media screen and (max-width: 768px) {
  .login-input {
    width: 100% !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .login-input {
    width: 250px !important;
  }
  .form-check-input input {
    width: 15px !important;
  }
}
.form-switch .form-check-input {
  height: 1.8rem;
  width: 3rem;
}
.form-check-label {
  margin-left: 1rem;
  margin-top: 0.2rem;
}
</style>
