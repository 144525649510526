<template>
  <vue-countdown
    v-if="timeRemaining"
    :time="timeRemaining"
    :interval="100"
    v-slot="{ days, hours, minutes, seconds, milliseconds }"
  >
    <span class="text-interval">
      Faltan：{{ days }} días, {{ hours }} horas, {{ minutes }} minutos,
      {{ seconds }}.{{ Math.floor(milliseconds / 100) }}
      segundos.
    </span>
  </vue-countdown>
  <span class="text-interval" v-else>Actualmente en remate</span>
</template>

<script>
export default {
  name: "CountdownAuction",
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  computed: {
    timeRemaining() {
      if (this.date) {
        const now = new Date();
        const dateAuction = new Date(this.date);
        const time_remaining = dateAuction - now;
        if (time_remaining > 0) {
          return time_remaining;
        }
        return false;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.text-interval {
  font-size: 14px !important;
  font-weight: 600 !important;
}
</style>
