<template>
  <layout-default>
    <div class="container">
      <div class="mt-5">
        <div class="container latest-auctions my-5">
          <SectionGroupsDirectSale></SectionGroupsDirectSale>
        </div>
      </div>
    </div>
  </layout-default>
</template>

<script>
import LayoutDefault from "@/components/organisms/LayoutDefault/LayoutDefault.vue";
import SectionGroupsDirectSale from "@/components/organisms/Section/DirectSale/SectionGroupsDirectSale.vue";

export default {
  beforeCreate() {
    document.title = "Venta Directa";
  },
  mounted() {
    this.$store.commit("setLocation", [
      { title: "Venta Directa", location: "/direct-sale" },
    ]);
  },
  components: {
    LayoutDefault,
    SectionGroupsDirectSale,
  },
};
</script>
<style scoped></style>
