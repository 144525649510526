<template>
  <div class="row p-3 pb-0">
    <!-- Name -->
    <div class="col-12 col-md-4">
      <div class="d-flex justify-content-between">
        <div>
          <font-awesome-icon
            :icon="['fas', 'user']"
            size="2x"
            class="mt-2 p-2 icon-circle-custom"
          />
        </div>
        <div class="ms-2 me-auto">
          <div class="fw-bolder" v-html="user.attributes.name"></div>
          <p class="fw-light" v-html="labels.name" />
        </div>
      </div>
    </div>
    <!-- Lastname -->
    <div class="col-12 col-md-4">
      <div class="d-flex justify-content-between">
        <div>
          <font-awesome-icon
            :icon="['fas', 'user']"
            size="2x"
            class="mt-2 p-2 icon-circle-custom"
          />
        </div>
        <div class="ms-2 me-auto">
          <div class="fw-bolder" v-html="user.attributes.lastname"></div>
          <p class="fw-light" v-html="labels.lastname" />
        </div>
      </div>
    </div>
    <!-- Rut -->
    <div class="col-12 col-md-4" v-if="user.relationships.info">
      <div class="d-flex justify-content-between">
        <div>
          <font-awesome-icon
            :icon="['fas', 'exclamation-circle']"
            size="2x"
            class="mt-2 p-2 icon-circle-custom"
          />
        </div>
        <div class="ms-2 me-auto">
          <div class="fw-bolder" v-html="user.relationships.info.rut"></div>
          <p class="fw-light" v-html="labels.rut" />
        </div>
      </div>
    </div>
    <!-- Email -->
    <div class="col-12 col-md-4" v-if="user.relationships.info">
      <div class="d-flex justify-content-between">
        <div>
          <font-awesome-icon
            :icon="['fas', 'envelope']"
            size="2x"
            class="mt-2 p-2 icon-circle-custom"
          />
        </div>
        <div class="ms-2 me-auto">
          <div class="fw-bolder" v-html="user.attributes.email"></div>
          <p class="fw-light" v-html="labels.email" />
        </div>
      </div>
    </div>
    <template v-if="user.relationships.info">
      <!-- Número Telefónico -->
      <div class="col-12 col-md-4">
        <div class="d-flex justify-content-between">
          <div>
            <font-awesome-icon
              :icon="['fas', 'phone']"
              size="2x"
              class="mt-2 p-2 icon-circle-custom"
            />
          </div>
          <div class="ms-2 me-auto">
            <div class="fw-bolder" v-html="user.relationships.info.phone"></div>
            <p class="fw-light" v-html="labels.phone" />
          </div>
        </div>
      </div>
      <!-- Comuna -->
      <div class="col-12 col-md-4">
        <div class="d-flex justify-content-between">
          <div>
            <font-awesome-icon
              :icon="['fas', 'map']"
              size="2x"
              class="mt-2 p-2 icon-circle-custom"
            />
          </div>
          <div class="ms-2 me-auto">
            <div class="fw-bolder" v-html="user.relationships.comuna"></div>
            <p class="fw-light" v-html="labels.comuna" />
          </div>
        </div>
      </div>
      <!-- Dirección -->
      <div class="col-12 col-md-4">
        <div class="d-flex justify-content-between">
          <div>
            <font-awesome-icon
              :icon="['fas', 'map-marker-alt']"
              size="2x"
              class="mt-2 p-2 icon-circle-custom"
            />
          </div>
          <div class="ms-2 me-auto">
            <div
              class="fw-bolder"
              v-html="user.relationships.info.address"
            ></div>
            <p class="fw-light" v-html="labels.address" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "UserShowComponent",
  props: ["user"],
  data() {
    return {
      /* Card Info Profile */
      labels: {
        name: "Nombre",
        lastname: "Apellido",
        email: "Correo electrónico",
        phone: "Número de Teléfono",
        comuna: "Comuna",
        rut: "RUT",
        address: "Dirección",
      },
    };
  },
};
</script>
<style scoped>
.icon-circle-custom {
  color: #fff;
  background: var(--primary-color) !important;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}
</style>
