<template>
  <div
    class="
      position-fixed
      modall
      d-flex
      align-items-center
      justify-content-center
    "
  >
    <div class="bg-white row position-relative p-5">
      <div
        class="close position-absolute"
        @click="$store.commit('forceCloseModal')"
      >
        <font-awesome-icon
          style="font-size: 15px !important"
          class="text-danger"
          :icon="['fas', 'window-close']"
        />
      </div>
      <div class="mx-auto">
        <div class="current-bid-title">
          <span class="current-bid fw-bold">
            {{ product ? product.attributes.name : "Imágenes" }}
          </span>
        </div>
        <h5 v-if="product && product.attributes.min">
          Precio de Venta Mínimo:
        </h5>
        <p
          v-if="product && product.attributes.min"
          class="pt-2 pb-3"
          v-html="currencyFormatMin(product)"
        ></p>
        <h5 v-if="product">Descripción:</h5>
        <p
          v-if="product"
          class="pt-2 pb-3"
          v-html="product.attributes.description"
        ></p>
        <product-slider
          v-if="photos.length"
          class="product-image"
          :photos="photos"
        ></product-slider>
      </div>
    </div>
  </div>
</template>

<script>
import ProductSlider from "@/components/atoms/Carousel/ProductSlider.vue";
export default {
  components: { ProductSlider },
  props: {
    photos: {
      type: Array,
    },
    product: {
      default: null,
    },
  },
  methods: {
    // Formato de moneda de minimo
    currencyFormatMin(product) {
      return (
        product.relationships?.currency?.symbol +
        new Intl.NumberFormat("es-CL").format(product.attributes.min)
      );
    },
  },
};
</script>

<style scoped="scoped">
.bolded {
  font-size: 24px !important;
  font-weight: bold;
}
.modall > .row {
  max-height: calc(90vh);
  overflow-y: auto;
  max-width: 700px;
  min-width: 45%;
  border-radius: 1rem;
}

.close {
  cursor: pointer;
  right: 10px;
  top: 1.5rem;
  width: fit-content;
}
.spacing {
  margin: 3px 0;
}
.negative-spacing {
  margin-top: -5px;
}
:deep(.item) {
  font-weight: bold;
  color: #484848 !important;
}
</style>
