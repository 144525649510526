<template>
  <div
    class="
      position-fixed
      modall
      d-flex
      align-items-center
      justify-content-center
    "
  >
    <div class="bg-white row position-relative p-5">
      <div
        class="close position-absolute"
        @click="$store.commit('forceCloseModal')"
      >
        <font-awesome-icon
          style="font-size: 15px !important"
          class="text-danger"
          :icon="['fas', 'window-close']"
        />
      </div>
      <div class="mx-auto">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped="scoped">
.bolded {
  font-size: 24px !important;
  font-weight: bold;
}
.modall > .row {
  max-width: 700px;
  min-width: 45%;
  border-radius: 1rem;
}
.close {
  cursor: pointer;
  right: 10px;
  top: 1.5rem;
  width: fit-content;
}
.spacing {
  margin: 3px 0;
}
.negative-spacing {
  margin-top: -5px;
}
:deep(.item) {
  font-weight: bold;
  color: #484848 !important;
}
</style>
